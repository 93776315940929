import { ListItemRoot } from "./ListItem.styles";
import { ListItemProps } from "./ListItem.types";
import clsx from "clsx";

function ListItem(props: ListItemProps): JSX.Element {
    const { className, style, children, selectable = true } = props;

    const [root, rootProps] =
        "onClick" in props
            ? ["span" as any, { role: "button", onClick: selectable ? props.onClick : undefined }]
            : ["a" as any, { href: props.href }];

    return (
        <ListItemRoot as={root} className={clsx(selectable && "Selectable", className)} style={style} {...rootProps}>
            {children}
        </ListItemRoot>
    );
}

export default ListItem;
