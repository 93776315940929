import { Outlet, RouteObject } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import { useBridgeRoutes } from "ui/bridge/BridgeRouter";
import { useActivityRoutes } from "ui/activity/router/ActivityRouter";
import BridgePage from "ui/bridge/pages/BridgePage/BridgePage";
import { HomeRoutes } from "./HomeRouter.types";

export const useHomeRoutes = (): RouteObject[] => {
    const bridgeRoutes = useBridgeRoutes();
    const activityRoutes = useActivityRoutes();

    return [
        {
            path: HomeRoutes.HOME,
            element: (
                <HomePage>
                    <Outlet />
                </HomePage>
            ),
            children: [
                {
                    path: HomeRoutes.HOME,
                    element: <BridgePage />,
                },
                ...bridgeRoutes,
                ...activityRoutes,
            ],
        },
    ];
};
