import { ChevronRightIcon } from "ui/common/icons";
import { SequenceProps } from "../Sequence.types";
import { Fragment, useMemo } from "react";

export interface UseSequenceItemsProps {
    children: SequenceProps["children"];
    Icon: SequenceProps["Icon"];
}

export default function useSequenceItems({ children, Icon = ChevronRightIcon }: UseSequenceItemsProps): JSX.Element[] {
    return useMemo(() => {
        return children.reduce((acc, child, index) => {
            acc.push(<Fragment key={`child-${index}`}>{child}</Fragment>);
            if (index < children.length - 1) {
                acc.push(<Icon className="SequenceIcon" key={`icon-${index}`} />);
            }
            return acc;
        }, [] as JSX.Element[]);
    }, [children, Icon]);
}
