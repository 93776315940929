import styled, { css } from "styled-components";
import TokenSelectorList from "../TokenSelectorList/TokenSelectorList";
import { Col } from "@peersyst/react-components";

export const BridgeTokenSelectorRoot = styled(Col)`
    height: 100%;
    overflow: hidden;
`;

export const BridgeTokenSelectorList = styled(TokenSelectorList)(
    ({ theme }) => css`
        flex: 1;
        padding: ${theme.spacing[6]} 0;
        overflow: auto;
    `,
);
