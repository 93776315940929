import { ExchangeIcon } from "ui/common/icons";
import { SwapButtonProps } from "./SwapButton.types";
import { SwapButtonRoot } from "./SwapButton.styles";
import clsx from "clsx";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import ControllerFactory from "ui/adapter/ControllerFactory";

function SwapButton({ style, className }: SwapButtonProps): JSX.Element {
    const { originChain, destinationChain } = useBridgeChainsState();

    const handleSwap = () => {
        ControllerFactory.bridgeTransferController.swap();
    };

    return (
        <SwapButtonRoot
            variant="secondary"
            size="md"
            disabled={!originChain && !destinationChain}
            onClick={handleSwap}
            style={style}
            className={clsx("SwapButton", className)}
        >
            <ExchangeIcon css={{ fontSize: "1.25rem" }} />
        </SwapButtonRoot>
    );
}

export default SwapButton;
