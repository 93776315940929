import FormGroup from "ui/common/components/layout/FormGroup/FormGroup";
import BridgeOriginAmountField from "../BridgeOriginAmountField/BridgeOriginAmountField";
import { BridgeTransferInputProps } from "./BridgeTransferInput.types";
import { useState } from "react";
import BridgeDestinationAmountField from "../BridgeDestinationAmountField/BridgeDestinationAmountField";
import { useTranslate } from "ui/locale";
import { BridgeTransferInputRoot } from "./BridgeTransferInput.styles";

function BridgeTransferInput({ name, required }: BridgeTransferInputProps): JSX.Element {
    const translate = useTranslate();

    const [amount, setAmount] = useState("");

    return (
        <BridgeTransferInputRoot>
            <FormGroup label={translate("youSend")}>
                <BridgeOriginAmountField label={translate("amount")} name={name} value={amount} onChange={setAmount} required={required} />
            </FormGroup>
            <FormGroup label={translate("youReceive")}>
                <BridgeDestinationAmountField label={translate("amount")} value={amount} />
            </FormGroup>
        </BridgeTransferInputRoot>
    );
}

export default BridgeTransferInput;
