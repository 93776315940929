import { Token } from "common/models/wallet/Token";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeSource, XChainBridge } from "xchain-sdk";

/**
 * Returns the bridge token for the given BridgeSource and XChainBridge.
 */
export function useGetSourceBridgeToken<TData = Token>(
    source: BridgeSource | undefined,
    xChainBridge: XChainBridge | undefined,
    { enabled = true, ...restOptions }: QueryOptions<Token, unknown, TData, any[]> = {},
): QueryResult<TData> {
    const bridge = useBridgeState();
    return useQuery<Token, unknown, TData, any[]>(
        [Queries.BRIDGE_SOURCE_TOKEN, source, JSON.stringify(xChainBridge), bridge?.direction],
        () => ControllerFactory.bridgeTokenController.getBridgeSourceXChainBridgeToken(source!, xChainBridge!),
        {
            enabled: !!source && !!bridge && !!xChainBridge && enabled,
            ...restOptions,
        },
    );
}
