import { Bridge } from "xchain-sdk";
import { createStore } from "zustand";

export type IBridgeState = {
    bridge: Bridge | undefined;
};

const bridgeState = createStore<IBridgeState>(() => ({ bridge: undefined }));

export default bridgeState;
