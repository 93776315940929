import Factory from "common/utils/Factory";
import RepositoryFactory from "domain/adapter/RepositoryFactory";
import { ISettingsController } from "./controllers/ISettingsController";
import SettingsController from "domain/settings/SettignsController";
import { IBridgeController } from "./controllers/IBridgeController";
import BridgeController from "domain/bridge/controllers/BridgeController";
import bridgeState from "domain/bridge/states/bridgeState";
import { BridgeApi, HealthApi, TransferApi, TokensApi } from "data_access/api/service";
import { IBridgeWalletsController } from "./controllers/IBridgeWalletsController";
import bridgeWalletsState from "domain/bridge/states/bridgeWalletsState";
import { BridgeWalletsController } from "domain/bridge/controllers/BridgeWalletsController";
import bridgeChainsState from "domain/bridge/states/bridgeChainsState";
import { IBridgeTransferController } from "./controllers/IBridgeTransferController";
import BridgeTransferController from "domain/bridge/controllers/BridgeTransferController";
import { IActivityController } from "./controllers/IActivityController";
import { ActivityController } from "domain/activity/controllers/ActivityController";
import { IBridgeProvidersController } from "./controllers/IBridgeProvidersControllers";
import { IBridgeDoorsController } from "./controllers/IBridgeDoorsController";
import { IBridgeManagerController } from "./controllers/IBridgeManagerController";
import { IBridgeTokenController } from "./controllers/IBridgeTokenController";
import { BridgeChainsController } from "domain/bridge/controllers/BridgeChainsController";
import { IBridgeChainsController } from "./controllers/IBridgeChainsController";
import { BridgeProvidersController } from "domain/bridge/controllers/BridgeProvidersController";
import { BridgeDoorsController } from "domain/bridge/controllers/BridgeDoorsController";
import { BridgeManagerController } from "domain/bridge/controllers/BridgeManagerController";
import { BridgeTokenController } from "domain/bridge/controllers/BridgeTokenController/BridgeTokenController";
import { IHealthController } from "./controllers/IHealthController";
import HealthController from "domain/health/controllers/HealthController";

export interface IControllerFactory {
    activityController: IActivityController;
    settingsController: ISettingsController;
    bridgeChainsController: IBridgeChainsController;
    bridgeProvidersController: IBridgeProvidersController;
    bridgeDoorsController: IBridgeDoorsController;
    bridgeManagerController: IBridgeManagerController;
    bridgeTokenController: IBridgeTokenController;
    bridgeController: IBridgeController;
    bridgeWalletsController: IBridgeWalletsController;
    bridgeTransferController: IBridgeTransferController;
    healthController: IHealthController;
}

export default Factory<IControllerFactory>({
    activityController: (resolve) => new ActivityController(TransferApi, resolve.bridgeChainsController, resolve.bridgeWalletsController),
    settingsController: () => new SettingsController(RepositoryFactory.settingsRepository),
    bridgeChainsController: () => new BridgeChainsController(BridgeApi, bridgeChainsState, RepositoryFactory.bridgeChainsRepository),
    bridgeProvidersController: () => new BridgeProvidersController(),
    bridgeDoorsController: () => new BridgeDoorsController(BridgeApi),
    bridgeManagerController: () => new BridgeManagerController(),
    bridgeTokenController: (resolve) =>
        new BridgeTokenController(
            TokensApi,
            resolve.bridgeChainsController,
            resolve.bridgeManagerController,
            resolve.bridgeProvidersController,
            RepositoryFactory.customTokensRepository,
        ),
    bridgeController: (resolve) => new BridgeController(bridgeState, resolve.bridgeChainsController),
    bridgeWalletsController: (resolve) =>
        new BridgeWalletsController(
            resolve.bridgeChainsController,
            resolve.bridgeController,
            bridgeWalletsState,
            RepositoryFactory.bridgeWalletsRepository,
        ),
    bridgeTransferController: (resolve) =>
        new BridgeTransferController(
            resolve.bridgeChainsController,
            resolve.bridgeWalletsController,
            resolve.bridgeManagerController,
            resolve.bridgeController,
            resolve.bridgeTokenController,
        ),
    healthController: () => new HealthController(HealthApi),
});
