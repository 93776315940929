import { useMemo } from "react";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import useSwitchToChain from "ui/wallet/query/useSwitchToChain";
import Modal from "ui/common/components/feedback/Modal/Modal";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { INVALID_NETWORKS } from "../index";
import { InvalidNetworkProps } from "../InvalidNetwork.types";
import { BridgeSource } from "xchain-sdk";

//TODO: Delete on double metamask refactor
function InvalidNetworkOverlay(): JSX.Element {
    const { originWallet, destinationWallet } = useBridgeWalletsState();
    const { originChain, destinationChain } = useBridgeChainsState();

    const { mutate: switchOriginNetwork, isLoading: switchingOriginNetwork } = useSwitchToChain(BridgeSource.ORIGIN);
    const { mutate: switchDestinationNetwork, isLoading: switchingDestinationNetwork } = useSwitchToChain(BridgeSource.DESTINATION);

    const isOriginNetworkValid = originWallet.connection === "connected" ? originWallet.isChainValid : true;
    const isDestinationNetworkValid = destinationWallet.connection === "connected" ? destinationWallet.isChainValid : true;

    const invalidNetworkProps = (
        !isOriginNetworkValid
            ? {
                  switchToNetwork: switchOriginNetwork,
                  switchingNetwork: switchingOriginNetwork,
                  chain: originChain,
                  wallet: originWallet,
              }
            : !isDestinationNetworkValid
              ? {
                    switchToNetwork: switchDestinationNetwork,
                    switchingNetwork: switchingDestinationNetwork,
                    chain: destinationChain,
                    wallet: destinationWallet,
                }
              : undefined
    ) as InvalidNetworkProps | undefined;

    const open = !isOriginNetworkValid || !isDestinationNetworkValid;

    const InvalidNetwork = useMemo(() => {
        if (invalidNetworkProps?.wallet) {
            const InvalidNetwork = INVALID_NETWORKS[invalidNetworkProps.wallet.providerId];
            // eslint-disable-next-line no-console
            if (!InvalidNetwork) console.warn(`Invalid network has not been defined for ${invalidNetworkProps.wallet.providerId}`);
            return InvalidNetwork;
        } else {
            return undefined;
        }
    }, [invalidNetworkProps?.wallet]);

    return (
        <Modal open={open} closable={false} renderAtRoot>
            {InvalidNetwork && <InvalidNetwork {...invalidNetworkProps!} />}
        </Modal>
    );
}

export default InvalidNetworkOverlay;
