import { CustomToken } from "common/models";
import LocalStorageRepository from "../../common/LocalStorageRepository";
import { ICustomTokensRepository } from "domain/adapter/repository/ICustomTokensRepository";
import RepositoryError from "data_access/repository/error/RepositoryError";
import { CustomTokensRepositoryErrorCodes } from "./CustomTokensRepositoryErrorCodes";

export class CustomTokensRepository extends LocalStorageRepository<CustomToken[]> implements ICustomTokensRepository {
    constructor() {
        super("custom-tokens");
    }

    private async exists(token: CustomToken, customTokens?: CustomToken[]): Promise<boolean | undefined> {
        return customTokens?.some(
            (customToken) =>
                customToken.issuer === token.issuer && customToken.currency === token.currency && customToken.chainName === token.chainName,
        );
    }

    getCustomTokens(): Promise<CustomToken[] | undefined> {
        return this.get();
    }

    async getCustomTokensByChains([originChain, destionationChain]: [string, string]): Promise<CustomToken[]> {
        const customTokens = await this.getCustomTokens();

        return customTokens?.filter((token) => token.chainName === originChain || token.chainName === destionationChain) ?? [];
    }

    async setCustomToken(token: CustomToken): Promise<void> {
        const customTokens = await this.getCustomTokens();

        if (!customTokens) {
            return this.set([token]);
        } else {
            const exists = await this.exists(token, customTokens);

            if (exists) throw new RepositoryError(CustomTokensRepositoryErrorCodes.CUSTOM_TOKEN_ALREADY_ADDED);

            await this.set([...customTokens, token]);
        }
    }

    async deleteCustomToken(token: CustomToken): Promise<void> {
        const customTokens = await this.getCustomTokens();

        const exists = await this.exists(token, customTokens);

        if (!exists || !customTokens) throw new RepositoryError(CustomTokensRepositoryErrorCodes.CUSTOM_TOKEN_NOT_FOUND);

        const filteredTokens = customTokens.filter(
            (customToken) =>
                !(
                    customToken.issuer === token.issuer &&
                    customToken.currency === token.currency &&
                    customToken.chainName === token.chainName
                ),
        );

        await this.set(filteredTokens);
    }
}
