import MainPage from "ui/common/components/layout/MainPage/MainPage";
import { PropsWithChildren, useEffect, useState } from "react";
import HomeCard from "ui/home/components/surface/HomeCard/HomeCard";
import { HomeTabs } from "ui/home/components/navigation/HomeTabs/HomeTabs";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";
import { useLocation } from "react-router-dom";
import { HomeRoutes } from "ui/home/router/HomeRouter.types";
import { ActivityRoutes } from "ui/activity/router/ActivityRouter.types";

const HOME_TABS_ROUTES: string[] = [HomeRoutes.HOME, BridgeRoutes.BRIDGE, ActivityRoutes.ACTIVITY];

const HomePage = ({ children }: PropsWithChildren): JSX.Element => {
    const { pathname } = useLocation();
    const [showHomeTabs, setShowHomeTabs] = useState(HOME_TABS_ROUTES.includes(pathname));

    useEffect(() => {
        if (HOME_TABS_ROUTES.includes(pathname)) setShowHomeTabs(true);
        else setShowHomeTabs(false);
    }, [pathname]);

    return (
        <MainPage>
            {showHomeTabs && <HomeTabs />}
            <HomeCard>{children}</HomeCard>
        </MainPage>
    );
};

export default HomePage;
