import { ActionStepIconProps } from "ui/common/components/feedback/ActionStep/ActionStepIcon/ActionStepIcon.types";
import { LightCrossIcon, LightSuccessIcon, LoadingArrowIcon } from "ui/common/icons";
import { ActionStepStatus } from "ui/common/components/feedback/ActionStep/ActionStep.types";
import { CSSProperties, JSXElementConstructor } from "react";

const ActionStepIcon = ({ status, Icon: WaitingIcon, ...rest }: ActionStepIconProps): JSX.Element => {
    const icons: Record<ActionStepStatus, JSXElementConstructor<{ className?: string; style?: CSSProperties }>> = {
        idle: WaitingIcon,
        waiting: WaitingIcon,
        loading: LoadingArrowIcon,
        success: LightSuccessIcon,
        error: LightCrossIcon,
    };

    const Icon = icons[status];

    return <Icon {...rest} />;
};

export default ActionStepIcon;
