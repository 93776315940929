import LocalStorageRepository from "../common/LocalStorageRepository";
import { PersistedBridgeWallets } from "common/models/bridge/PersistedBridgeWallets";
import { IBridgeWalletsRepository } from "domain/adapter/repository/IBridgeWalletsRepository";
import { PersistedWallet } from "common/models/wallet/PersistedWallet";
import { BridgeSource } from "xchain-sdk";

export class BridgeWalletsRepository extends LocalStorageRepository<PersistedBridgeWallets> implements IBridgeWalletsRepository {
    constructor() {
        super("bridge-wallets");
    }

    getBridgeWallets(): Promise<PersistedBridgeWallets | undefined> {
        return this.get();
    }

    async setBridgeWallets(wallets: PersistedBridgeWallets): Promise<void> {
        await this.set(wallets);
    }

    async setOriginWallet(wallet: PersistedWallet | undefined): Promise<void> {
        const wallets = await this.getBridgeWallets();

        await this.setBridgeWallets({ ...wallets, originWallet: wallet });
    }

    async setDestinationWallet(wallet: PersistedWallet | undefined): Promise<void> {
        const wallets = await this.getBridgeWallets();

        await this.setBridgeWallets({ ...wallets, destinationWallet: wallet });
    }

    async setWalletSource(side: BridgeSource, wallet: PersistedWallet | undefined): Promise<void> {
        if (side === "origin") await this.setOriginWallet(wallet);
        else await this.setDestinationWallet(wallet);
    }
}
