import { Col, Typography } from "@peersyst/react-components";
import { Link } from "react-router-dom";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";
import { useTranslate } from "ui/locale";

const TransferListNothingToShow = (): JSX.Element => {
    const translate = useTranslate();
    const { originWallet, destinationWallet } = useBridgeWalletsState();
    return (
        <Col flex={1} justifyContent="center" alignItems="center">
            {originWallet.connection === "connected" && destinationWallet.connection === "connected" ? (
                <Typography variant="h6" fontWeight={400} color="grey.500" textAlign="center">
                    {translate("hereYouWillSeeYourTransfers")}
                </Typography>
            ) : (
                <Link to={BridgeRoutes.BRIDGE}>
                    <Typography variant="h6">{translate("connectYourWallets")}</Typography>
                </Link>
            )}
        </Col>
    );
};

export default TransferListNothingToShow;
