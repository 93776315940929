import { TabPanel, Tabs, createModal, useToast } from "@peersyst/react-components";
import Modal from "ui/common/components/feedback/Modal/Modal";
import { CreateBridgeModalProps, CreateBridgeModalTabs } from "./CreateBridgeModal.types";
import { useEffect, useState } from "react";
import { useTranslate } from "ui/locale";
import CreateBridgeForm from "../CreateBridgeForm/CreateBridgeForm";
import CreateBridgeSteps from "../CreateBridgeSteps/CreateBridgeSteps";
import useCreateBridge from "ui/bridge/query/useCreateBridge";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { useQueryClient } from "ui/query/react-query-overrides";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { BridgeSource } from "xchain-sdk";

/**
 * Stores the cleanup function for the create bridge request listeners.
 * There must be a single listener for each create bridge request event, so we need to remove the previous listeners before adding new ones.
 */
let removeSingletonCreateBridgeRequestListeners: (() => void) | undefined = undefined;

const CreateBridgeModal = createModal<CreateBridgeModalProps>(function CreateBridgeModal({ token, onClose, ...modalProps }): JSX.Element {
    const translate = useTranslate();
    const { showToast } = useToast();
    const [tabIndex, setTabIndex] = useState(CreateBridgeModalTabs.CREATE_BRIDGE);
    const [isError, setIsError] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const originChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);
    const queryClient = useQueryClient();
    const bridge = useBridgeState();

    const { mutate: createBridge, isLoading } = useCreateBridge();

    function handleContinue(): void {
        setTabIndex(CreateBridgeModalTabs.SIGN_TRANSACTION);
        createBridge({
            chainName: originChain.name,
            currency: token.currency,
            issuer: token.issuer!,
        });
    }

    useEffect(() => {
        // Remove any previous listeners before adding new ones
        removeSingletonCreateBridgeRequestListeners?.();

        const removeCreateBridgeRequestFailed = ControllerFactory.bridgeTransferController.on("createBridgeRequestFailed", () => {
            setIsError(true);
        });

        const removeCreateBridgeRequestConfirmed = ControllerFactory.bridgeTransferController.on("createBridgeRequestConfirmed", () => {
            setIsConfirmed(true);
            showToast(translate("creatingTokenBridge", { token: token.currency }), { type: "info" });
        });

        const removeCreateBridgeRequestBridgeCreated = ControllerFactory.bridgeTransferController.on(
            "createBridgeRequestBridgeCreated",
            () => {
                queryClient.invalidateQueries([Queries.CUSTOM_TOKENS, bridge]);
                showToast(translate("createdTokenBridge", { token: token.currency }), { type: "success" });
            },
        );

        // Should not remove listeners on unmount since we want to keep listening for those events even if the modal is closed
        // What we do instead is remove the listeners when the component is re-mounted.
        removeSingletonCreateBridgeRequestListeners = () => {
            removeCreateBridgeRequestFailed();
            removeCreateBridgeRequestConfirmed();
            removeCreateBridgeRequestBridgeCreated();
        };
    }, []);

    const handleClose = (): void => {
        if (isConfirmed || isError) queryClient.invalidateQueries([Queries.CUSTOM_TOKENS, bridge]);
        onClose?.();
    };

    return (
        <Modal
            onClose={handleClose}
            title={translate(tabIndex === CreateBridgeModalTabs.CREATE_BRIDGE ? "createBridge" : "nowSignTheTransaction")}
            css={{ width: "35rem" }}
            closable={tabIndex === CreateBridgeModalTabs.CREATE_BRIDGE || isError || isConfirmed}
            {...modalProps}
        >
            <Tabs index={tabIndex}>
                <TabPanel index={CreateBridgeModalTabs.CREATE_BRIDGE}>
                    <CreateBridgeForm onCancel={handleClose} onContinue={handleContinue} token={token} />
                </TabPanel>
                <TabPanel index={CreateBridgeModalTabs.SIGN_TRANSACTION}>
                    <CreateBridgeSteps isLoading={isLoading} onClose={handleClose} />
                </TabPanel>
            </Tabs>
        </Modal>
    );
});

export default CreateBridgeModal;
