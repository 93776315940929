import { BridgeBlockchainAddressProps } from "./BridgeBlockchainAddress.types";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import ChainBlockchainAddress from "ui/blockchain/components/display/ChainBlockchainAddress/ChainBlockchainAddress";

function BridgeBlockchainAddress({ source: side, ...blockchainAddressProps }: BridgeBlockchainAddressProps): JSX.Element {
    const chain = useBridgeSourceChainState(side, true);

    return <ChainBlockchainAddress chain={chain} {...blockchainAddressProps} />;
}

export default BridgeBlockchainAddress;
