import { ClockIcon } from "./ClockCallout.styles";
import { ClockCalloutProps } from "./ClockCallout.types";
import { Row, Typography } from "@peersyst/react-components";

function ClockCallout({ message, variant = "body1", style, className }: ClockCalloutProps): JSX.Element {
    return (
        <Row gap="0.75rem" style={style} className={className}>
            <ClockIcon />
            <Typography variant={variant}>{message}</Typography>
        </Row>
    );
}

export default ClockCallout;
