import { IBridgeController } from "ui/adapter/controllers/IBridgeController";
import State from "domain/common/State";
import { IBridgeState } from "../states/bridgeState";
import BridgeEvent from "../events/BridgeEvent";
import DomainError from "domain/error/DomainError";
import BridgeErrorCodes from "../BridgeErrorCodes";
import { Bridge, BridgeDirection, XChainBridge, XChainBridgeChain } from "xchain-sdk";
import DomainEvents from "domain/event";
import { IBridgeChainsController } from "ui/adapter/controllers/IBridgeChainsController";
import Amount from "common/utils/Amount";

export default class BridgeController implements IBridgeController {
    /**
     * Reference to the domain event emitter.
     */
    private domainEventEmitter = BridgeEvent;

    constructor(
        public readonly bridgeState: State<IBridgeState>,
        private readonly bridgeChainsController: IBridgeChainsController,
    ) {}

    onInit(): void {
        DomainEvents.bridgeChains.on("bridgeChainsChange", () => {
            this.setBridge(undefined);
        });

        DomainEvents.bridgeToken.on("bridgeVerifiedTokensLoad", (verifiedTokens) => {
            this.setBridge(verifiedTokens[0].xChainBridge);
        });
    }

    /**
     * Gets the current bridge config state.
     */
    private get bridge(): IBridgeState["bridge"] {
        return this.bridgeState.getState().bridge;
    }

    /**
     * Gets the current bridge config.
     */
    getBridge(): IBridgeState["bridge"] {
        return this.bridge;
    }

    /**
     * Gets the origin XChainBridgeChain.
     */
    getOriginXChainBridgeChain(): XChainBridgeChain | undefined {
        return this.bridge?.originXChainBridgeChain;
    }

    /**
     * Gets the destination XChainBridgeChain.
     */
    getDestinationXChainBridgeChain(): XChainBridgeChain | undefined {
        return this.bridge?.destinationXChainBridgeChain;
    }

    /**
     * Sets the bridge config.
     * @param xChainBridge The XChainBridge to set.
     */
    setBridge(xChainBridge: XChainBridge | undefined): void {
        let value: IBridgeState["bridge"];

        if (xChainBridge) {
            const { originChain, destinationChain } = this.bridgeChainsController.getBridgeChains();

            const lockingChainId = xChainBridge.lockingChain.id;
            const issuingChainId = xChainBridge.issuingChain.id;
            if (
                (lockingChainId !== originChain.name && lockingChainId !== destinationChain.name) ||
                (issuingChainId !== originChain.name && issuingChainId !== destinationChain.name)
            )
                throw new DomainError(BridgeErrorCodes.BRIDGE_DOES_NOT_CORRESPOND_TO_CHAINS);

            const direction =
                xChainBridge.lockingChain.id === originChain.name ? BridgeDirection.LOCKING_TO_ISSUING : BridgeDirection.ISSUING_TO_LOCKING;

            value = new Bridge(direction, xChainBridge);
        } else {
            value = undefined;
        }

        this.domainEventEmitter.emit("bridgeChange", value);
        this.bridgeState.setState({ bridge: value });
    }

    /**
     * Swaps the bridge.
     */
    swap(): void {
        this.bridgeState.setState((prevState) => ({
            bridge: prevState.bridge ? prevState.bridge.swapped() : undefined,
        }));
    }

    getCreateBridgeReward(_doorAddress: string): Promise<Amount> {
        const { originChain } = this.bridgeChainsController.getBridgeChains();

        return Promise.resolve(Amount.fromDecToken("25", { currency: originChain.nativeToken, decimals: originChain.nativeDecimals }));
    }
}
