import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeToken } from "common/models";

export default function useGetCustomTokens<T = BridgeToken[]>({
    enabled = true,
    ...restOptions
}: QueryOptions<BridgeToken[], unknown, T, any[]> = {}): QueryResult<T> {
    const bridge = useBridgeState();

    return useQuery([Queries.CUSTOM_TOKENS, bridge], () => ControllerFactory.bridgeTokenController.getCustomTokens(), {
        enabled: !!bridge && enabled,
        ...restOptions,
    });
}
