import { CustomToken } from "common/models";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { MutationOptions, MutationResult, useMutation, useQueryClient } from "ui/query/react-query-overrides";

export default function useDeleteCustomToken(
    options?: MutationOptions<void, unknown, CustomToken>,
): MutationResult<void, unknown, CustomToken> {
    const queryClient = useQueryClient();
    const bridge = useBridgeState();

    return useMutation((token: CustomToken) => ControllerFactory.bridgeTokenController.deleteCustomToken(token), {
        onSuccess(data, variables, context) {
            queryClient.invalidateQueries([Queries.CUSTOM_TOKENS, bridge]);
            options?.onSuccess?.(data, variables, context);
        },
        ...options,
    });
}
