import { CommonModalComponentProps, createModal, Tab } from "@peersyst/react-components";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { useTranslate } from "ui/locale";
import { BridgeOriginTokenSelectorModalTabs } from "./BridgeOriginTokenSelectorModal.types";
import {
    BridgeTokenSelectorTabGroup,
    BridgeTokenSelectorTabs,
    BridgeOriginTokenSelectorModalRoot,
    BridgeTokenSelectorTabPanel,
} from "./BridgeOriginTokenSelectorModal.styles";
import BridgeOriginVerifiedTokenSelector from "../BridgeOriginVerifiedTokenSelector/BridgeOriginVerifiedTokenSelector";
import { useState } from "react";
import BridgeOriginCustomTokenSelector from "../BridgeOriginCustomTokenSelector/BridgeOriginCustomTokenSelector";
import useBridgeOriginTokenSelectorTabs from "./hooks/useBridgeOriginTokenSelectorTabs";
import { TokenSelectorListItemData } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem.types";
import ControllerFactory from "ui/adapter/ControllerFactory";

const BridgeOriginTokenSelectorModal = createModal<CommonModalComponentProps>(function BridgeOriginTokenSelectorModal({
    onClose,
    ...restModalProps
}): JSX.Element {
    const translate = useTranslate();
    const { originChain } = useBridgeChainsState();
    const bridgeOriginTokenSelectorTabs = useBridgeOriginTokenSelectorTabs();

    const [selectedTab, setSelectedTab] = useState(bridgeOriginTokenSelectorTabs[0].index);

    const handleSelect = (token: TokenSelectorListItemData): void => {
        ControllerFactory.bridgeController.setBridge(token.xChainBridge);
        onClose?.();
    };

    return (
        <BridgeOriginTokenSelectorModalRoot
            title={translate("selectTokenOn", { network: originChain?.name })}
            onClose={onClose}
            renderAtRoot // Necessary to render the modal outside of the BridgeOriginAmountField
            {...restModalProps}
        >
            <BridgeTokenSelectorTabs index={selectedTab} onIndexChange={setSelectedTab}>
                <BridgeTokenSelectorTabGroup>
                    {bridgeOriginTokenSelectorTabs.map((tab) => (
                        <Tab key={tab.index} index={tab.index}>
                            {tab.label}
                        </Tab>
                    ))}
                </BridgeTokenSelectorTabGroup>
                <BridgeTokenSelectorTabPanel index={BridgeOriginTokenSelectorModalTabs.VERIFIED}>
                    <BridgeOriginVerifiedTokenSelector onSelect={handleSelect} />
                </BridgeTokenSelectorTabPanel>
                <BridgeTokenSelectorTabPanel index={BridgeOriginTokenSelectorModalTabs.CUSTOM}>
                    <BridgeOriginCustomTokenSelector onSelect={handleSelect} />
                </BridgeTokenSelectorTabPanel>
            </BridgeTokenSelectorTabs>
        </BridgeOriginTokenSelectorModalRoot>
    );
});

export default BridgeOriginTokenSelectorModal;
