import { Row, Typography } from "@peersyst/react-components";
import { ImageSelectItemProps } from "./ImageSelectItem.types";
import { SelectItemImage } from "./ImageSelectItem.styles";

function ImageSelectItem({ src, alt, label, style, className }: ImageSelectItemProps): JSX.Element {
    return (
        <Row flex={1} css={{ overflow: "hidden" }} gap="0.5rem" alignItems="center" style={style} className={className}>
            <SelectItemImage src={src || ""} alt={alt || `${label} logo`} />
            {typeof label === "string" ? (
                <Typography css={{ flex: 1, textOverflow: "ellipsis" }} variant="body2">
                    {label}
                </Typography>
            ) : (
                <Row flex={1} css={{ overflow: "hidden" }}>
                    {label}
                </Row>
            )}
        </Row>
    );
}

export default ImageSelectItem;
