import { TabGroup } from "@peersyst/react-components";
import RouteTab from "ui/router/components/RouterTabs/RouteTab/RouteTab";
import useHomeTabs from "./hooks/useHomeTabs";
import { HomeTabsRoot } from "./HomeTabs.styles";

export const HomeTabs = (): JSX.Element => {
    const tabs = useHomeTabs();

    return (
        <HomeTabsRoot>
            <TabGroup>
                {tabs.map((item, index) => (
                    <RouteTab to={item.path} index={index} key={item.label}>
                        {item.label}
                    </RouteTab>
                ))}
            </TabGroup>
        </HomeTabsRoot>
    );
};
