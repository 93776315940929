import { Row, Label } from "@peersyst/react-components";
import { TransferFieldProps } from "./TransferField.types";

const TransferField = ({ children, label, ...props }: TransferFieldProps): JSX.Element => {
    return (
        <Label variant="caption2" label={label} gap={0} {...props}>
            <Row flex={1} css={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                {children}
            </Row>
        </Label>
    );
};

export default TransferField;
