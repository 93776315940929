import { GroupDetailProps } from "./GroupDetail.types";
import GroupLabel from "ui/common/components/layout/GroupLabel/GroupLabel";
import { Loader, Row, Typography } from "@peersyst/react-components";
import clsx from "clsx";

export default function GroupDetail({ label, value, complement, isLoading = false, style, className }: GroupDetailProps): JSX.Element {
    const labelElement = complement ? (
        <Row alignItems="center" gap="0.25rem">
            {label}
            {complement}
        </Row>
    ) : (
        label
    );

    return (
        <GroupLabel placement="left" label={labelElement} gap="0.5rem" style={style} className={clsx("GroupLabel", className)}>
            <Typography variant="caption1" color="grey.200">
                {isLoading ? <Loader /> : value}
            </Typography>
        </GroupLabel>
    );
}
