import { useTranslate } from "ui/locale";
import { BridgeOriginCustomTokenSelectorNothingToShowProps } from "./BridgeOriginCustomTokenSelectorNothingToShow.types";
import NothingToShow from "ui/common/components/feedback/NothingToShow/NothingToShow";
import { BridgeSource, ChainType } from "xchain-sdk";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { Alert, Loader } from "@peersyst/react-components";
import { AddCustomTokenForm } from "../../AddCustomTokenForm/AddCustomTokenForm";
import CreateAndAddCustomTokenForm from "../../CreateAndAddCustomTokenForm/CreateAndAddCustomTokenForm";

export default function BridgeOriginCustomTokenSelectorNothingToShow({
    isEmptyTokensQuery,
    existingXChainBridge,
    isTokenAddressValid,
    isFiltering,
    tokenAddress,
}: BridgeOriginCustomTokenSelectorNothingToShowProps): JSX.Element {
    const translate = useTranslate();
    const sourceChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);

    if (isFiltering) return <NothingToShow showLabel={false} icon={<Loader fontSize="2.5rem" />} />;

    if (isEmptyTokensQuery && isTokenAddressValid) {
        if (existingXChainBridge) return <AddCustomTokenForm xChainBridge={existingXChainBridge} />;
        else if (sourceChain.type === ChainType.EVM && tokenAddress) return <CreateAndAddCustomTokenForm address={tokenAddress} />;
        else return <Alert content={translate("switchToNetworkToCreateBridge", { network: ChainType.EVM })} type="warning" />;
    }

    return <NothingToShow label={translate(isEmptyTokensQuery ? "searchNotFound" : "emptyCustomTokens")} />;
}
