import useFormatAmount from "ui/common/hooks/useFormatAmount";
import Amount from "common/utils/Amount";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { formatCurrency } from "common/utils/token";
import { useIsDestinationActive } from "./useIsDestinationActive";
import { useCallback } from "react";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";

export function useBridgeTransferFee(options: Omit<QueryOptions<boolean, unknown, string, any[]>, "select"> = {}): QueryResult<string> {
    const formatAmount = useFormatAmount();
    const { destinationXChainBridgeChain, originXChainBridgeChain } = useBridgeState() || {};
    const { destinationChain, originChain } = useBridgeChainsState();
    const { signatureReward: destinationSignatureReward = "0" } = destinationXChainBridgeChain || {};
    const { signatureReward: originSignatureReward = "0" } = originXChainBridgeChain || {};

    const select = useCallback(
        (isDestinationActive: boolean) =>
            // origin and destination chains must be defined here
            formatAmount(
                isDestinationActive
                    ? new Amount(
                          destinationSignatureReward,
                          destinationChain!.nativeDecimals,
                          formatCurrency(destinationChain!.nativeToken),
                      )
                    : new Amount(originSignatureReward, originChain!.nativeDecimals, formatCurrency(originChain!.nativeToken)),
            ),
        [formatAmount, destinationChain, originChain, destinationSignatureReward, originSignatureReward],
    );

    return useIsDestinationActive({ select, ...options });
}
