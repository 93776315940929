import CustomTokenForm, { CustomTokenFormSkeleton } from "ui/bridge/components/input/CustomTokenForm/CustomTokenForm";
import { AddCustomTokenFormProps } from "./AddCustomTokenForm.types";
import useAddCustomToken from "ui/bridge/query/useAddCustomToken";
import { useGetBridgeWalletTokenBalance } from "ui/wallet/query/useGetBridgeWalletTokenBalance";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { BridgeSource } from "xchain-sdk";
import { useGetSourceBridgeToken } from "ui/bridge/query/useGetSourceBridgeToken";
import { useTranslate } from "ui/locale";

export function AddCustomTokenForm({ xChainBridge }: AddCustomTokenFormProps): JSX.Element {
    const translate = useTranslate();

    const sourceChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);

    const { data: token, isLoading: isTokenLoading } = useGetSourceBridgeToken(BridgeSource.ORIGIN, xChainBridge);
    const { data: balance, isLoading: isBalanceLoading } = useGetBridgeWalletTokenBalance(BridgeSource.ORIGIN, xChainBridge);
    const { mutate: addCustomToken, isLoading: isAddCustomTokenLoading } = useAddCustomToken();

    function handleSubmit(): void {
        addCustomToken({
            chainName: sourceChain.name,
            currency: xChainBridge.lockingChain.issue.currency,
            issuer: xChainBridge.lockingChain.issue.issuer!.address,
        });
    }

    return isTokenLoading || !token ? (
        <CustomTokenFormSkeleton />
    ) : (
        <CustomTokenForm
            address={token.issuer!}
            currency={token.currency}
            decimals={token.decimals}
            balance={balance}
            isBalanceLoading={isBalanceLoading}
            onSubmit={handleSubmit}
            isSubmitting={isAddCustomTokenLoading}
            buttonLabel={translate("addToken")}
        />
    );
}
