import AmountField from "ui/common/components/input/AmountField/AmountField";
import { BridgeDestinationAmountFieldProps } from "./BridgeDestinationAmountField.types";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Typography } from "@peersyst/react-components";
import { BridgeSource } from "xchain-sdk";
import { useGetSourceToken } from "ui/bridge/query/useGetSourceToken";
import { formatCurrency } from "common/utils/token";

function BridgeDestinationAmountField({ disabled = false, ...rest }: BridgeDestinationAmountFieldProps): JSX.Element {
    const { destinationXChainBridgeChain: destinationChainConfig } = useBridgeState() || {};
    const { data: token } = useGetSourceToken(BridgeSource.DESTINATION);

    return (
        <AmountField
            suffix={
                !!destinationChainConfig && (
                    <Typography variant="body2" color="grey.200">
                        {token?.currency && formatCurrency(token.currency)}
                    </Typography>
                )
            }
            readonly
            disabled={disabled || !destinationChainConfig}
            maxDecimals={token?.decimals}
            {...rest}
        />
    );
}

export default BridgeDestinationAmountField;
