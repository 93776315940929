import Amount from "common/utils/Amount";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";

export default function useGetCreateBridgeReward(
    doorAddress: string,
    { enabled = true, ...restOptions }: QueryOptions<Amount, unknown, Amount, any[]> = {},
): QueryResult<Amount> {
    return useQuery(
        [Queries.GET_CREATE_BRIDGE_REWARD, doorAddress],
        () => ControllerFactory.bridgeController.getCreateBridgeReward(doorAddress),
        {
            enabled: !!doorAddress && enabled,
            ...restOptions,
        },
    );
}
