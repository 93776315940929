import PeersystLogo from "ui/common/components/display/PeersystLogo/PeersystLogo";
import { ExternalLinksWrapper, FooterRoot } from "ui/common/components/navigation/Footer/Footer.styles";
import { Col, Row, Typography, useTheme } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { FooterLinkProps } from "../../feedback/FooterLink/FooterLink.types";
import { FooterLink } from "../../feedback/FooterLink/FooterLink";
import { DiscordLogoIcon, EnvelopeIcon, XLogoIcon } from "ui/common/icons";
import { ProjectVersion } from "ui/common/containers/ProjectVersion/ProjectVersion";
import { config } from "common/config";

export default function Footer(): JSX.Element {
    const translate = useTranslate();
    const { spacing } = useTheme();

    const footerLinks: FooterLinkProps[] = [
        {
            href: config.footerLinks.discord,
            label: "Discord",
            Icon: DiscordLogoIcon,
        },
        {
            href: config.footerLinks.x,
            label: `@${config.footerLinks.x.split("/").pop()}`,
            Icon: XLogoIcon,
        },
        {
            href: config.footerLinks.featureRequest,
            label: translate("featureRequest"),
            Icon: EnvelopeIcon,
        },
    ];

    return (
        <FooterRoot>
            <Col flex={1} alignItems="center" gap={spacing[6]}>
                <Row alignItems="center" gap={spacing[2]}>
                    <Typography variant="body2" light>
                        {translate("proudlyDevelopedBy")}
                    </Typography>
                    <PeersystLogo />
                </Row>
                <ExternalLinksWrapper gap={spacing[8]}>
                    {footerLinks.map((link) => (
                        <FooterLink key={link.label} {...link} />
                    ))}
                    <ProjectVersion />
                </ExternalLinksWrapper>
            </Col>
        </FooterRoot>
    );
}
