import { RouteTabProps } from "./RouteTab.types";
import { Tab, useSetTab } from "@peersyst/react-components";
import { useEffect } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";

const RouteTab = ({ to, index, children, ...restTabProps }: RouteTabProps): JSX.Element => {
    const { pathname } = useLocation();

    const setTab = useSetTab();

    useEffect(() => {
        if (matchPath({ path: to, caseSensitive: true }, pathname) || pathname.includes(to)) setTab(index);
    }, [pathname]);

    return (
        <Link type="router" to={to}>
            <Tab index={index} {...restTabProps}>
                {children}
            </Tab>
        </Link>
    );
};

export default RouteTab;
