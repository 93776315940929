import { TokenSelectorListItem } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem";
import useDeleteCustomToken from "ui/bridge/query/useDeleteCustomToken";
import { useTranslate } from "ui/locale";
import { BridgeOriginCustomTokenSelectorListItemProps } from "./BridgeOriginCustomTokenSelectorListItem.types";
import { MoreAction } from "ui/common/components/input/More/More.types";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { BridgeSource } from "xchain-sdk";
import { useGetBridgeWalletTokenBalance } from "../../../../wallet/query/useGetBridgeWalletTokenBalance";

export default function BridgeOriginCustomTokenSelectorListItem({
    item,
    onSelect,
}: BridgeOriginCustomTokenSelectorListItemProps): JSX.Element {
    const translate = useTranslate();
    const { mutate: deleteCustomToken } = useDeleteCustomToken();
    const sourceChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);

    const { data: tokenBalance, isLoading: isBalanceLoading } = useGetBridgeWalletTokenBalance(BridgeSource.ORIGIN, item.xChainBridge, {
        enabled: !item.isPending,
    });

    function handleOnDeleteCustomToken(): void {
        deleteCustomToken({
            chainName: sourceChain.name,
            currency: item.xChainBridge.lockingChain.issue.currency,
            issuer: item.xChainBridge.lockingChain.issue.issuer!.address,
        });
    }

    const actions: MoreAction[] = [
        {
            label: translate("hideToken"),
            onClick: handleOnDeleteCustomToken,
        },
    ];

    return (
        <TokenSelectorListItem item={item} onSelect={onSelect} more={actions} balance={tokenBalance} isBalanceLoading={isBalanceLoading} />
    );
}

export const renderBridgeOriginCustomTokenSelectorListItem = (props: BridgeOriginCustomTokenSelectorListItemProps, index: number) => {
    return <BridgeOriginCustomTokenSelectorListItem key={`custom-token-selector-${index}`} {...props} />;
};
