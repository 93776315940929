import { Col } from "@peersyst/react-components";
import clsx from "clsx";
import { useTranslate } from "ui/locale";
import { useControlled } from "@peersyst/react-hooks";
import useFormatAmount from "ui/common/hooks/useFormatAmount";
import NumericField from "../NumericField/NumericField";
import { AmountFieldProps } from "./AmountField.types";
import { MaxAmountTypography } from "./AmountField.styles";
import Amount from "common/utils/Amount";

function AmountField({
    balance: balanceProp,
    style,
    className,
    defaultValue = "",
    value: valueProp,
    onChange: onChangeProp,
    error = false,
    maxDecimals,
    ...rest
}: AmountFieldProps): JSX.Element {
    const translate = useTranslate();
    const formatAmount = useFormatAmount();

    const [value, setValue] = useControlled(defaultValue, valueProp, onChangeProp);

    const balance = balanceProp
        ? balanceProp.gt("0")
            ? balanceProp
            : new Amount("0", balanceProp.decimals, balanceProp.currency)
        : undefined;
    const isValidAmount = !value || !balance || balance.canPay(value);

    const handleLoadMax = () => {
        setValue(balance!.formatAmount());
    };

    return (
        <Col style={style} className={clsx("AmountField", className)} gap={12}>
            <NumericField
                error={error || !isValidAmount}
                value={value}
                onChange={setValue}
                maxDecimals={maxDecimals || balance?.decimals}
                {...rest}
            />
            {balance !== undefined && (
                <MaxAmountTypography variant="caption2" isValidAmount={isValidAmount} onClick={handleLoadMax}>{`${translate(
                    "sendMax",
                )}: ${formatAmount(balance)}`}</MaxAmountTypography>
            )}
        </Col>
    );
}

export default AmountField;
