import { Queries } from "ui/query/queries";
import { QueryOptions, UseQueryResult, useQuery } from "ui/query/react-query-overrides";
import { TokenSelectorListItemData } from "../components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem.types";
import { filterAsync } from "ui/common/utils/filterAsync";
import { formatCurrency } from "common/utils/token";

export default function useFilterTokens(
    tokens: TokenSelectorListItemData[],
    query: string,
    options: QueryOptions<TokenSelectorListItemData[], unknown, TokenSelectorListItemData[], any[]> = {},
): UseQueryResult<TokenSelectorListItemData[], unknown> {
    return useQuery(
        [Queries.FILTER_TOKENS, tokens, query],
        async () => {
            return await filterAsync(tokens, (token) => {
                const label = token.label.toLowerCase();
                const issuer = token.xChainBridge.lockingChain.issue.issuer?.address.toLowerCase();
                const queryLower = query.toLowerCase();

                const matchesQuery = label.includes(queryLower) || issuer === queryLower;

                return matchesQuery;
            });
        },
        {
            select: (tokens) => tokens.map((token) => ({ ...token, label: formatCurrency(token.label) })),
            ...options,
        },
    );
}
