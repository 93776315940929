import { BridgeWalletPair } from "common/models";
import { createStore } from "zustand";

export type IBridgeWalletsState = BridgeWalletPair;

const bridgeWalletsState = createStore<IBridgeWalletsState>(() => ({
    originWallet: { connection: "disconnected" },
    destinationWallet: { connection: "disconnected" },
}));

export default bridgeWalletsState;
