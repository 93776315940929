import styled from "styled-components";
import AmountField from "ui/common/components/input/AmountField/AmountField";

export const BridgeOriginAmountTextField = styled(AmountField)`
    .TextField {
        .FormControlHint {
            display: none;
        }
    }
`;
