import { WalletProviderId } from "common/models";
import { WalletProvider } from "../WalletProvider";

export default function createWalletProviderFactory<WP extends Record<WalletProviderId, () => WalletProvider>>(
    providers: WP,
): { [K in keyof WP]: WP[K] extends () => infer T ? T : never } {
    return new Proxy({} as { [K in keyof WP]: WP[K] extends () => infer T ? T : never }, {
        get: (_target, key) => {
            return providers[key as WalletProviderId]();
        },
    });
}
