import { useTranslate } from "ui/locale";
import { WalletConnectionModalProps } from "./WalletConnectionModal.types";
import Modal from "ui/common/components/feedback/Modal/Modal";
import { ModalProps, createModal, useToast } from "@peersyst/react-components";
import { useEffect, useMemo } from "react";
import { WALLET_CONNECTIONS } from "../index";
import getWalletSourceProvider from "ui/bridge/utils/getWalletSourceProvider";
import useRequestWalletSideConnection from "ui/bridge/query/useRequestWalletSideConnection";
import type { LocaleTranslationResource, LocaleErrorResource } from "ui/locale/i18n.types.d.ts";
import useTranslationExists from "ui/locale/hooks/useTranslationExists";
import { config } from "common/config";
import { WalletConnectionContent } from "./WalletConnectionModal.styles";

const WalletConnectionModal = createModal<WalletConnectionModalProps & ModalProps>(function WalletConnectionModal({
    providerId,
    side,
    ...modalProps
}): JSX.Element {
    const translate = useTranslate();
    const translateError = useTranslate("error");
    const translationExists = useTranslationExists();
    const { showToast } = useToast();

    const { data, isLoading } = useRequestWalletSideConnection(side, providerId);

    useEffect(() => {
        const walletProvider = getWalletSourceProvider(side);

        const removeOnConnect = walletProvider?.on("connect", () => modalProps.close());
        const removeOnConnectionError = walletProvider?.on("connectionError", (_error, message) => {
            modalProps.close();
            showToast(translateError(message as LocaleErrorResource, { provider: config.walletProviders[providerId].name }), {
                type: "error",
            });
        });

        return () => {
            removeOnConnect?.();
            removeOnConnectionError?.();
        };
    }, [side]);

    const WalletProviderConnection = useMemo(() => WALLET_CONNECTIONS[providerId], [providerId]);

    const titleTranslationKey = `${providerId}ConnectionTitle`;
    const subtitleTranslationKey = `${providerId}ConnectionSubtitle`;
    const title = translationExists(titleTranslationKey) ? translate(titleTranslationKey as LocaleTranslationResource) : undefined;
    const subtitle = translationExists(subtitleTranslationKey) ? translate(subtitleTranslationKey as LocaleTranslationResource) : undefined;

    return (
        <Modal css={{ width: "39.5rem" }} title={title} subtitle={subtitle} {...modalProps}>
            <WalletConnectionContent>
                <WalletProviderConnection data={data} isLoading={isLoading} />
            </WalletConnectionContent>
        </Modal>
    );
});

export default WalletConnectionModal;
