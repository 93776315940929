import { Chip, Loader, Row, Skeleton, Typography, useTheme } from "@peersyst/react-components";
import { TokenSelectorListItemProps } from "./TokenSelectorListItem.types";
import { TokenSelectorListItemLogo, TokenSelectorListItemRoot } from "./TokenSelectorListItem.styles";
import { token_default_logo } from "ui/assets/images";
import { useTranslate } from "ui/locale";
import TokenVerificationPopover from "ui/common/components/display/TokenVerificationPopover/TokenVerificationPopover";
import More from "ui/common/components/input/More/More";
import AmountDisplay from "ui/common/components/display/AmountDisplay/AmountDisplay";
import Amount from "common/utils/Amount";

export function TokenSelectorListItem({
    item,
    onSelect,
    more,
    balance = new Amount("0", 1, ""),
    isBalanceLoading = false,
}: TokenSelectorListItemProps): JSX.Element {
    const { spacing } = useTheme();
    const translate = useTranslate();

    return (
        <TokenSelectorListItemRoot onClick={() => onSelect(item)} selectable={!item.isPending}>
            <Row alignItems="center" gap={spacing[3]}>
                <TokenSelectorListItemLogo src={item.icon || token_default_logo} />
                <Row alignItems="center" gap={spacing[1]}>
                    <Typography variant="body1">{item.label}</Typography>
                    <TokenVerificationPopover
                        verified={item.isVerified}
                        label={translate(item.isVerified ? "verifiedToken" : "unverifiedToken")}
                    />
                </Row>
            </Row>
            {item.isPending ? (
                <Chip
                    suffix={`(${translate("estimatedTimeArrivalAbbreviation")} 1${translate("minutesAbbreviation")})`}
                    prefix={<Loader />}
                    label="Creating"
                    size="sm"
                    rounded={false}
                    variant="accent"
                />
            ) : (
                <Row alignItems="center" gap={spacing[3]}>
                    {balance && (
                        <Skeleton loading={isBalanceLoading} css={{ width: "3rem" }}>
                            <AmountDisplay amount={balance} hideCurrency />
                        </Skeleton>
                    )}
                    {!!more && <More actions={more} />}
                </Row>
            )}
        </TokenSelectorListItemRoot>
    );
}

export const renderTokenSelectorListItem = (props: TokenSelectorListItemProps, index: number) => {
    return <TokenSelectorListItem key={`token-selector-${index}`} {...props} />;
};
