import { CreateConfig } from "@peersyst/react-components";
import { ExpandableIconRoot } from "ui/common/components/feedback/Expandable/ExpandableIcon/ExpandableIcon.styles";
import ArrowButton from "ui/common/components/input/ArrowButton/ArrowButton";

const components: CreateConfig["components"] = {
    Button: {
        defaultProps: {
            variant: "primary",
            size: "lg",
        },
    },
    Carousel: {
        defaultProps: {
            leftArrow: <ArrowButton direction="left" />,
            rightArrow: <ArrowButton direction="right" />,
            renderArrows: true,
            gap: 24,
        },
    },
    Chip: {
        defaultProps: {
            variant: "outlined",
            rounded: true,
        },
    },
    Popover: {
        defaultProps: {
            arrow: true,
            position: "top",
        },
    },
    Toast: {
        defaultProps: {
            position: "bottom-right",
        },
    },
    Skeleton: {
        defaultProps: {
            animation: "pulse",
        },
    },
    TextInput: {
        defaultProps: {
            errorElement: false,
        },
    },
    ExpandableDisplay: {
        defaultProps: {
            ExpandComponent: ExpandableIconRoot,
        },
    },
};

export default components;
