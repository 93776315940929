import ControllerFactory from "ui/adapter/ControllerFactory";
import { IWalletProvider } from "ui/adapter/providers/IWalletProvider";
import { BridgeSource } from "xchain-sdk";

export default function getWalletSourceProvider(side: BridgeSource): IWalletProvider | undefined {
    // Cannot return undefined as getter throws error if so.
    return side === BridgeSource.ORIGIN
        ? ControllerFactory.bridgeWalletsController.originWalletProvider
        : ControllerFactory.bridgeWalletsController.destinationWalletProvider;
}
