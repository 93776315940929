import { ITokensApi } from "domain/adapter/api/ITokensApi";
import { IBridgeChainsController } from "ui/adapter/controllers/IBridgeChainsController";
import { IBridgeTokenController } from "ui/adapter/controllers/IBridgeTokenController";
import {
    XChainBridge,
    BridgeSource,
    ChainType,
    XChainBridgeChainFormat,
    XChainBridgeFormat,
    XChainBridgeChain,
    XChainBridgeIssue,
    XChainAddress,
} from "xchain-sdk";
import { IBridgeManagerController } from "ui/adapter/controllers/IBridgeManagerController";
import DomainEvents from "domain/event";
import BridgeTokenEvent from "../../events/BridgeTokenEvent";
import { IBridgeProvidersController } from "ui/adapter/controllers/IBridgeProvidersControllers";
import { Token, TokenLike } from "common/models/wallet/Token";
import Amount from "common/utils/Amount";
import DomainError from "domain/error/DomainError";
import BridgeErrorCodes from "../../BridgeErrorCodes";
import { ICustomTokensRepository } from "domain/adapter/repository/ICustomTokensRepository";
import { BridgeToken, ChainDto, CustomToken, CustomTokenInfo } from "common/models";
import { isCustomTokenProvider } from "domain/adapter/utils/isCustomTokenProvider";
import { FilterXChainBridgeTokensOptions } from "./BridgeTokenController.types";

export class BridgeTokenController implements IBridgeTokenController {
    /**
     * Reference to the domain event emitter.
     */
    private readonly domainEventEmitter = BridgeTokenEvent;

    private pendingCustomBridgeTokens: BridgeToken[] = [];

    constructor(
        private readonly tokensApi: ITokensApi,
        private readonly bridgeChainsController: IBridgeChainsController,
        private readonly bridgeManagerController: IBridgeManagerController,
        private readonly bridgeProvidersController: IBridgeProvidersController,
        private readonly customTokensRepository: ICustomTokensRepository,
    ) {}

    onInit(): void {
        DomainEvents.bridgeManager.on("bridgeManagerLoad", async () => {
            const verifiedTokens = await this.getVerifiedTokens();
            this.domainEventEmitter.emit("bridgeVerifiedTokensLoad", verifiedTokens);
        });
    }

    /**
     * Filters `xChainBridges` by `tokens`.
     * @param xChainBridges Array of XChainBridges.
     * @param tokens Array of tokens.
     * @returns Filtered XChainBridges.
     */
    private filterXChainBridgeTokens(
        xChainBridges: XChainBridge[],
        tokens: TokenLike[],
        { areTokensVerified }: FilterXChainBridgeTokensOptions,
    ): BridgeToken[] {
        if (!tokens.length) return [];

        const filteredXChainBridgeTokens: BridgeToken[] = [];

        for (const xChainBridge of xChainBridges) {
            if (tokens.length === 0) break;

            const verifiedTokenIndex = tokens.findIndex(
                (token) =>
                    (token.issuer == xChainBridge.lockingChain.issue.issuer?.address &&
                        token.currency == xChainBridge.lockingChain.issue.currency) ||
                    (token.issuer == xChainBridge.issuingChain.issue.issuer?.address &&
                        token.currency == xChainBridge.issuingChain.issue.currency),
            );

            if (verifiedTokenIndex !== -1) {
                filteredXChainBridgeTokens.push(
                    new BridgeToken({ xChainBridge, imageUrl: tokens[verifiedTokenIndex]?.imageUrl, isVerified: areTokensVerified }),
                );
                tokens?.splice(verifiedTokenIndex, 1);
            }
        }

        return filteredXChainBridgeTokens;
    }

    /**
     * Gets the XChainBridgeChain corresponding to the given chain.
     * @param chain The chain.
     * @param xChainBridge The XChainBridge.
     */
    private getChainXChainBridgeChain(chain: ChainDto, xChainBridge: XChainBridge): XChainBridgeChainFormat<ChainType> {
        if (xChainBridge.lockingChain.id !== undefined && xChainBridge.lockingChain.id === chain.name) {
            return xChainBridge.lockingChain.for(chain.type as ChainType);
        } else if (xChainBridge.issuingChain.id !== undefined && xChainBridge.issuingChain.id === chain.name) {
            return xChainBridge.issuingChain.for(chain.type as ChainType);
        } else {
            throw new DomainError(BridgeErrorCodes.X_CHAIN_BRIDGE_DOES_NOT_CORRESPOND_TO_CHAIN);
        }
    }

    /**
     * Gets the XChainBridge corresponding to the given chain.
     * @param chain The chain.
     * @param xChainBridge The XChainBridge.
     */
    private getChainXChainBridge(chain: ChainDto, xChainBridge: XChainBridge): XChainBridgeFormat<ChainType> {
        return xChainBridge.for(chain.type as ChainType);
    }

    /**
     * Get verified tokens.
     * @returns Verified tokens.
     */
    async getVerifiedTokens(): Promise<BridgeToken[]> {
        const { originChain, destinationChain } = this.bridgeChainsController.getBridgeChains();
        const bridgeManager = this.bridgeManagerController.getBridgeManager();

        const [xChainBridges, verifiedTokens] = await Promise.all([
            bridgeManager.getXChainBridges(),
            this.tokensApi.findVerifiedTokens([originChain.name, destinationChain.name]),
        ]);

        return this.filterXChainBridgeTokens(xChainBridges, verifiedTokens, { areTokensVerified: true });
    }

    /**
     * Get custom tokens.
     * @returns Custom tokens.
     */
    async getCustomTokens(): Promise<BridgeToken[]> {
        const { originChain, destinationChain } = this.bridgeChainsController.getBridgeChains();
        const bridgeManager = this.bridgeManagerController.getBridgeManager();

        const [xChainBridges, customTokens] = await Promise.all([
            bridgeManager.getXChainBridges(),
            this.customTokensRepository.getCustomTokensByChains([originChain.name, destinationChain.name]),
        ]);

        return [
            ...this.pendingCustomBridgeTokens,
            ...this.filterXChainBridgeTokens(xChainBridges, customTokens, { areTokensVerified: false }),
        ];
    }

    /**
     * Gets the token corresponding to the given source and XChainBridge
     * @param source The bridge source.
     * @param xChainBridge The XChainBridge
     */
    async getBridgeSourceXChainBridgeToken(source: BridgeSource, xChainBridge: XChainBridge): Promise<Token> {
        const chain = this.bridgeChainsController.getSourceChain(source);
        const provider = await this.bridgeProvidersController.getSourceProvider(source);
        const xChainBridgeChainForChainType = this.getChainXChainBridgeChain(chain, xChainBridge);
        const xChainBridgeForChainType = this.getChainXChainBridge(chain, xChainBridge);

        return await provider.getXChainBridgeToken(xChainBridgeChainForChainType, xChainBridgeForChainType);
    }

    /**
     * Gets the token corresponding to the given source and token address
     * @param source The bridge source.
     * @param tokenAddress The token address.
     * @pre `tokenAddress` is the issuer
     */
    async getBridgeSourceToken(source: BridgeSource, tokenAddress: string): Promise<Token> {
        const provider = await this.bridgeProvidersController.getSourceProvider(source);

        if (!isCustomTokenProvider(provider)) throw new DomainError(BridgeErrorCodes.NOT_CUSTOM_TOKEN_PROVIDER);

        const [decimals, currency] = await Promise.all([provider.getTokenDecimals(tokenAddress), provider.getTokenCurrency(tokenAddress)]);

        return {
            decimals,
            issuer: tokenAddress,
            currency,
        };
    }

    /**
     * Gets the token balance corresponding to the given address, source and XChainBridge
     * @param address The address.
     * @param source The bridge source.
     * @param xChainBridge The XChainBridge.
     */
    async getBridgeSourceXChainBridgeTokenBalance(address: string, source: BridgeSource, xChainBridge: XChainBridge): Promise<Amount> {
        const chain = this.bridgeChainsController.getSourceChain(source);
        const provider = await this.bridgeProvidersController.getSourceProvider(source);
        const xChainBridgeChainForChainType = this.getChainXChainBridgeChain(chain, xChainBridge);
        const xChainBridgeForChainType = this.getChainXChainBridge(chain, xChainBridge);

        const [token, balance] = await Promise.all([
            provider.getXChainBridgeToken(xChainBridgeChainForChainType, xChainBridgeForChainType),
            provider.getXChainBridgeTokenBalance(address, xChainBridgeChainForChainType, xChainBridgeForChainType),
        ]);

        return Amount.fromIntToken(balance, token);
    }

    /**
     * Gets the token balance corresponding to the given source and token address
     * @param source The bridge source.
     * @param tokenAddress The token address.
     */
    async getBridgeSourceTokenBalance(source: BridgeSource, tokenAddress: string): Promise<Amount> {
        const provider = await this.bridgeProvidersController.getSourceProvider(source);

        if (!isCustomTokenProvider(provider)) throw new DomainError(BridgeErrorCodes.NOT_CUSTOM_TOKEN_PROVIDER);

        const [token, balance] = await Promise.all([
            this.getBridgeSourceToken(source, tokenAddress),
            provider.getTokenBalance(tokenAddress),
        ]);

        return Amount.fromIntToken(balance, token);
    }

    /**
     * Gets the token name corresponding to the given source and XChainBridge
     * @param source The bridge source.
     * @param xChainBridge The XChainBridge.
     */
    async getBridgeSourceXChainBridgeTokenName(source: BridgeSource, xChainBridge: XChainBridge<ChainType, ChainType>): Promise<string> {
        const chain = this.bridgeChainsController.getSourceChain(source);
        const provider = await this.bridgeProvidersController.getSourceProvider(source);
        const xChainBridgeChainForChainType = this.getChainXChainBridgeChain(chain, xChainBridge);
        const xChainBridgeForChainType = this.getChainXChainBridge(chain, xChainBridge);

        return provider.getXChainBridgeTokenName(xChainBridgeChainForChainType, xChainBridgeForChainType);
    }

    /**
     * Gets the token name corresponding to the given source and token address
     * @param source The bridge source.
     * @param tokenAddress The token address.
     */
    async getBridgeSourceTokenName(source: BridgeSource, tokenAddress: string): Promise<string> {
        const provider = await this.bridgeProvidersController.getSourceProvider(source);

        if (!isCustomTokenProvider(provider)) throw new DomainError(BridgeErrorCodes.NOT_CUSTOM_TOKEN_PROVIDER);

        return provider.getTokenName(tokenAddress);
    }

    async getSourceCustomTokenInfo(source: BridgeSource, tokenAddress: string): Promise<CustomTokenInfo> {
        const provider = await this.bridgeProvidersController.getSourceProvider(source);

        if (!isCustomTokenProvider(provider)) throw new DomainError(BridgeErrorCodes.NOT_CUSTOM_TOKEN_PROVIDER);

        const [token, balance, name] = await Promise.all([
            this.getBridgeSourceToken(source, tokenAddress),
            provider.getTokenBalance(tokenAddress),
            provider.getTokenName(tokenAddress),
        ]);

        return {
            ...token,
            balance: Amount.fromIntToken(balance, token),
            name,
        };
    }

    /**
     * Check if token address is valid.
     * @param address
     * @returns A boolean indicating if the token address is valid.
     */
    async isTokenAddressValid(tokenAddress: string): Promise<boolean> {
        const originProvider = await this.bridgeProvidersController.getOriginProvider();
        const isOriginTokenAddressValid = await originProvider.isTokenAddressValid(tokenAddress);

        if (isOriginTokenAddressValid) return true;

        const destinationProvider = await this.bridgeProvidersController.getDestinationProvider();
        const isDestinationTokenAddressValid = await destinationProvider.isTokenAddressValid(tokenAddress);

        return isDestinationTokenAddressValid;
    }

    /**
     * Find XChainBridge by query.
     * @param query issuer or currency.
     * @returns XChainBridge if found, otherwise undefined.
     */
    async findXChainBridgeByQuery(query: string): Promise<XChainBridge | undefined> {
        const bridgeManager = this.bridgeManagerController.getBridgeManager();
        const xChainBridges = await bridgeManager.getXChainBridges();

        return xChainBridges.find(
            (xChainBridge) =>
                !!xChainBridge.lockingChain.issue.issuer &&
                (xChainBridge.lockingChain.issue.issuer.address === query || xChainBridge.lockingChain.issue.currency === query),
        );
    }

    /**
     * Add custom token to the repository.
     */
    async addCustomToken(token: CustomToken): Promise<void> {
        return await this.customTokensRepository.setCustomToken(token);
    }

    /**
     * Delete custom token from the repository.
     * @param token Custom Token to be deleted
     */
    async deleteCustomToken(token: CustomToken): Promise<void> {
        return await this.customTokensRepository.deleteCustomToken(token);
    }

    addPendingCustomToken(token: CustomToken, doorAddress: string, type: ChainType): void {
        /**
         * We only know the locking side, since the issuing side hasn't been created yet (otherwise it wouldn't be pending).
         * We can use the locking side on both sides, since the issuing side shouldn't be used in a pending token.
         */
        const xChainBridgeIssue = new XChainBridgeIssue(token.currency, new XChainAddress(token.issuer, type));
        const xChainBridgeChain = new XChainBridgeChain(
            type,
            new XChainAddress(doorAddress, type),
            xChainBridgeIssue,
            // Not known and not necessary for a pending token
            "unknown",
            undefined,
            token.chainName,
        );
        const xChainBridge = new XChainBridge(xChainBridgeChain, xChainBridgeChain);

        this.pendingCustomBridgeTokens.push(new BridgeToken({ xChainBridge, isVerified: false, isPending: true }));
    }

    deletePendingCustomToken(token: CustomToken): void {
        this.pendingCustomBridgeTokens = this.pendingCustomBridgeTokens.filter(
            (pendingToken) =>
                !(
                    pendingToken.xChainBridge.lockingChain.issue.currency === token.currency &&
                    pendingToken.xChainBridge.lockingChain.issue.issuer?.address === token.issuer &&
                    pendingToken.xChainBridge.lockingChain.id === token.chainName
                ),
        );
    }
}
