import { metamask_invalid_network } from "ui/assets/images";
import { InvalidNetworkProps } from "../InvalidNetwork.types";
import { MetamaskInvalidNetworkImg, MetamaskInvalidNetworkRoot } from "./MetamaskInvalidNetwork.styles";
import { Typography } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";

function MetamaskInvalidNetwork({ switchToNetwork, chain }: InvalidNetworkProps): JSX.Element {
    const translate = useTranslate();
    return (
        <MetamaskInvalidNetworkRoot>
            <MetamaskInvalidNetworkImg src={metamask_invalid_network} />
            <Typography variant="body1" textAlign="center">
                {`${translate("please")}, `}
                <a css={{ cursor: "pointer" }} onClick={switchToNetwork}>
                    {translate("switchMetamaskNetwork", { network: chain.name })}
                </a>
            </Typography>
        </MetamaskInvalidNetworkRoot>
    );
}

export default MetamaskInvalidNetwork;
