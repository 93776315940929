export enum ConnectionError {
    REJECTED = "rejected",
    FAILED = "failed",
}

export enum NetworkType {
    MAINNET = "mainnet",
    TESTNET = "testnet",
    DEVNET = "devnet",
}
