import { Col, Divider, Typography, useTheme } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import BridgeTransactionStep from "../BridgeTransactionStep/BridgeTransactionStep";
import { BridgeTransferStage } from "xchain-sdk";
import Button from "ui/common/components/input/Button/Button";
import { CreateBridgeStepsProps } from "./CreateBridgeSteps.types";
import CreateBridgeTransferDetails from "../CreateBridgeTransferDetails/CreateBridgeTransferDetails";

export default function CreateBridgeSteps({ isLoading, onClose }: CreateBridgeStepsProps): JSX.Element {
    const { spacing } = useTheme();
    const translate = useTranslate();

    return (
        <Col gap={spacing[8]}>
            <Typography variant="body1" light>
                {translate("createBridgeSignTransaction")}
            </Typography>
            <BridgeTransactionStep stage={BridgeTransferStage.CREATE_BRIDGE_REQUEST} isFirst />
            <Divider />
            <CreateBridgeTransferDetails />
            <Button fullWidth loading={isLoading} onClick={onClose}>
                {translate("finish")}
            </Button>
        </Col>
    );
}
