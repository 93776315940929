import { PaginatedTransfersDto } from "common/models";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import { Queries } from "ui/query/queries";
import { UseInfiniteQueryResult, useInfiniteQuery } from "ui/query/react-query-overrides";

export interface UseGetPaginatedTransfersProps {
    page: number;
    pageSize: number;
}

export function usePaginatedTransfersEnabled(enabled = true): boolean {
    const { originChain, destinationChain } = useBridgeChainsState();
    const { originWallet, destinationWallet } = useBridgeWalletsState();

    return (
        enabled &&
        !!originChain &&
        !!destinationChain &&
        originWallet.connection === "connected" &&
        destinationWallet.connection === "connected"
    );
}

export default function useGetPaginatedTransfers(
    { page, pageSize }: UseGetPaginatedTransfersProps = { page: 1, pageSize: 10 },
): UseInfiniteQueryResult<PaginatedTransfersDto, unknown> {
    const { originChain, destinationChain } = useBridgeChainsState();
    const { originWallet, destinationWallet } = useBridgeWalletsState();

    const queryEnabled = usePaginatedTransfersEnabled();

    return useInfiniteQuery(
        [Queries.GET_TRANSFERS, page, pageSize, originChain, destinationChain, originWallet, destinationWallet],
        ({ pageParam = 1 }) => ControllerFactory.activityController.getPaginatedTranfers(pageParam, pageSize),
        {
            enabled: queryEnabled,
            staleTime: 3000,
        },
    );
}
