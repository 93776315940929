import { WalletProviderId } from "common/models";
import { JSXElementConstructor } from "react";
import { WalletConnectionProps } from "./WalletConnection.types";
import MetamaskConnection from "./MetamaskConnection/MetamaskConnection";
import XrplFaucetConnection from "./XrplFaucetConnection/XrplFaucetConnection";

export const WALLET_CONNECTIONS: Record<WalletProviderId, JSXElementConstructor<WalletConnectionProps>> = {
    metamask: MetamaskConnection,
    xrplFaucet: XrplFaucetConnection,
};
