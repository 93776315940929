import { BridgeWallet } from "common/models";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import { BridgeSource } from "xchain-sdk";

export default function useBridgeSourceWalletState<
    S extends (wallet: BridgeWallet) => any = (wallet: BridgeWallet) => BridgeWallet,
    R = S extends (wallet: BridgeWallet) => infer T ? T : any,
>(source: BridgeSource, select: S = ((wallet) => wallet) as S): R {
    const { originWallet, destinationWallet } = useBridgeWalletsState();

    return select(source === BridgeSource.ORIGIN ? originWallet : destinationWallet);
}
