import { DomainErrorCode } from "domain/error/DomainErrorCodes";
import { IXrpWalletProviderProvider } from "./interfaces/IXrpWalletProviderProvider";
import { IXrpWalletProviderSigner } from "./interfaces/IXrpWalletProviderSigner";
import { ChainType, FormattedBridge, TrustClaimTransaction, Unconfirmed } from "xchain-sdk";
import { WalletProvider } from "../WalletProvider";
import { ITrustClaimWalletProvider } from "ui/adapter/providers/IWalletProvider";
import DomainError from "domain/error/DomainError";
import { XrpWalletProviderErrors } from "./errors";

export abstract class XrpWalletProvider<
        Provider extends IXrpWalletProviderProvider = IXrpWalletProviderProvider,
        Signer extends IXrpWalletProviderSigner = IXrpWalletProviderSigner,
        Error extends DomainErrorCode = DomainErrorCode,
        RequestSignerResult = any,
    >
    extends WalletProvider<ChainType.XRP, Provider, Signer, Error, RequestSignerResult>
    implements ITrustClaimWalletProvider
{
    isTrustClaimRequired(bridge: FormattedBridge<ChainType.XRP>): boolean {
        return !bridge.isNativeDestinationIssue;
    }

    trustClaim(bridge: FormattedBridge<ChainType.XRP>): Promise<Unconfirmed<TrustClaimTransaction>> {
        if (bridge.isNativeDestinationIssue) throw new DomainError(XrpWalletProviderErrors.CANNOT_TRUST_CLAIM_WITH_NATIVE_CURRENCY);

        return this.signer.setTrustLine(
            bridge.destinationXChainBridgeChain.issue.issuer!,
            bridge.destinationXChainBridgeChain.issue.currency,
        );
    }

    async isClaimTrusted(bridge: FormattedBridge<ChainType.XRP>): Promise<boolean> {
        if (bridge.isNativeDestinationIssue) throw new DomainError(XrpWalletProviderErrors.CANNOT_CHECK_CLAIM_TRUST_WITH_NATIVE_CURRENCY);

        return this.provider.accountHasTrustLine(
            this.address,
            bridge.destinationXChainBridgeChain.issue.issuer!,
            bridge.destinationXChainBridgeChain.issue.currency,
        );
    }
}
