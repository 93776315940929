import SettingsRepository from "data_access/repository/settings/SettingsRepository";
import Factory from "../../common/utils/Factory";
import { ISettingsRepository } from "./repository/ISettingsRepository";
import { IBridgeChainsRepository } from "./repository/IBridgeChainsRepository";
import { BridgeChainsRepository } from "data_access/repository/bridge/BridgeChainsRepository";
import { IBridgeWalletsRepository } from "./repository/IBridgeWalletsRepository";
import { BridgeWalletsRepository } from "data_access/repository/bridge/BridgeWalletsRepository";
import { IXrplFaucetSeedsRepository } from "./repository/IXrplFaucetSeedsRepository";
import { XrplFaucetSeedsRepository } from "data_access/repository/wallet/xrplFaucet/XrplFaucetSeedsRepository";
import { ICustomTokensRepository } from "./repository/ICustomTokensRepository";
import { CustomTokensRepository } from "data_access/repository/bridge/custom-tokens/CustomTokensRepository";

export interface IRepositoryFactory {
    settingsRepository: ISettingsRepository;
    bridgeChainsRepository: IBridgeChainsRepository;
    bridgeWalletsRepository: IBridgeWalletsRepository;
    xrplFaucetSeedsRepository: IXrplFaucetSeedsRepository;
    customTokensRepository: ICustomTokensRepository;
}

export default Factory<IRepositoryFactory>({
    settingsRepository: () => new SettingsRepository(),
    bridgeChainsRepository: () => new BridgeChainsRepository(),
    bridgeWalletsRepository: () => new BridgeWalletsRepository(),
    xrplFaucetSeedsRepository: () => new XrplFaucetSeedsRepository(),
    customTokensRepository: () => new CustomTokensRepository(),
});
