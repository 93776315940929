import { CustomToken } from "common/models";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { MutationOptions, MutationResult, useMutation } from "ui/query/react-query-overrides";
import { Confirmed, CreateBridgeRequestTransaction } from "xchain-sdk";

export default function useCreateBridge(
    options: MutationOptions<Confirmed<CreateBridgeRequestTransaction>, unknown, CustomToken> = {},
): MutationResult<Confirmed<CreateBridgeRequestTransaction>, unknown, CustomToken> {
    return useMutation((token: CustomToken) => ControllerFactory.bridgeTransferController.createBridge(token), options);
}
