import { config } from "common/config";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren } from "react";

const AnalyticsProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
    const options = {
        api_host: config.posthog.host,
    };
    return (
        <PostHogProvider apiKey={config.posthog.apiKey} options={options}>
            {children}
        </PostHogProvider>
    );
};

export default AnalyticsProvider;
