export enum Queries {
    CHAINS = "chains",
    REQUEST_WALLET_CONNECTION = "request-wallet-connection",
    X_CHAIN_BRIDGES = "x-chain-bridges",
    DESTINATION_CAN_RECEIVE = "destination-can-receive",
    TRANSFER_IS_CREATE_ACCOUNT = "transfer-is-create-account",
    FILTER_TOKENS = "filter-tokens",
    VERIFIED_TOKENS = "verified-tokens",
    GET_TRANSFERS = "get-tranfers",
    CUSTOM_TOKENS = "custom-tokens",
    BRIDGE_SOURCE_TOKEN_BALANCE = "bridge-source-token-balance",
    BRIDGE_SOURCE_TOKEN = "bridge-source-token",
    FIND_X_CHAIN_BRIDGE_BY_QUERY = "find-x-chain-bridge-by-query",
    IS_TOKEN_ADDRESS_VALID = "is-token-address-valid",
    GET_CUSTOM_TOKEN_INFO = "get-custom-token-info",
    GET_CREATE_BRIDGE_REWARD = "get-create-bridge-reward",
    APP_VERSION = "app-version",
    IS_DESTINATION_ACTIVE = "is-destination-active",
}
