import Sequence from "ui/common/components/display/Sequence/Sequence";
import { ChainSequenceIcon } from "./ChainSequence.styles";
import { ChainSequenceProps } from "./ChainSequence.types";
import Chain from "ui/bridge/components/display/Chain/Chain";
import { spacing } from "ui/config/spacing";

const ChainSequence = ({ origin: sourceChain, destination: destinationChain }: ChainSequenceProps): JSX.Element => {
    return (
        <Sequence wrap wrapGap={spacing[4]} Icon={ChainSequenceIcon}>
            <Chain chain={sourceChain} />
            <Chain chain={destinationChain} />
        </Sequence>
    );
};

export default ChainSequence;
