import { metamask } from "ui/assets/images";
import ClockCallout from "ui/common/components/feedback/ClockCallout/ClockCallout";
import { useTranslate } from "ui/locale";
import { MetamaskConnectionRoot, MetamaskConnectionLogo } from "./MetamaskConnection.styles";

function MetamaskConnection(): JSX.Element {
    const translate = useTranslate();

    return (
        <MetamaskConnectionRoot>
            <MetamaskConnectionLogo src={metamask} />
            <ClockCallout message={translate("waitingMetamaskSign")} />
        </MetamaskConnectionRoot>
    );
}

export default MetamaskConnection;
