import { XrplFaucetProviderErrors } from "./errors";
import { XrpWalletProvider } from "../XrpWalletProvider";
import { IXrplFaucetProvider } from "domain/adapter/service/provider/IXrplFaucetProvider";
import { IXrplSigner } from "domain/adapter/service/signer/IXrplSigner";
import { IXrplFaucetService } from "domain/adapter/service/IXrplFaucetService";
import { ChainType } from "xchain-sdk";

export class XrplFaucetWalletProvider extends XrpWalletProvider<IXrplFaucetProvider, IXrplSigner, XrplFaucetProviderErrors, void> {
    constructor(private readonly xrplFaucetService: IXrplFaucetService) {
        super("xrplFaucet", ChainType.XRP);
    }

    protected getProvider(): Promise<IXrplFaucetProvider> {
        return this.xrplFaucetService.getProvider(this.chain.nodeUrl, this.chain.faucetUrl!);
    }

    protected getSigner(): Promise<IXrplSigner> {
        return this.xrplFaucetService.getSigner(this.provider);
    }

    protected recoverSigner(address: string): Promise<IXrplSigner | undefined> {
        return this.xrplFaucetService.recoverSigner(this.provider, address);
    }

    protected afterConnect(): void {
        const removeOnDisconnect = this.on("disconnect", () => {
            this.xrplFaucetService.removeSigner(this.address);
            removeOnDisconnect();
        });
    }
}
