import { Popover, Typography } from "@peersyst/react-components";
import { useState } from "react";
import { MoreIcon } from "ui/common/icons";
import ListItem from "../ListItem/ListItem";
import { MoreButton } from "./More.styles";
import { MoreProps } from "./More.types";

/**
 * Renders an icon button with `MoreIcon` that opens a popover with a list of actions
 */
export default function More({ actions }: MoreProps): JSX.Element {
    const [openPopover, setOpenPopover] = useState(false);

    return (
        <Popover
            showOn="click"
            visible={openPopover}
            onHide={() => setOpenPopover(false)}
            onShow={() => setOpenPopover(true)}
            arrow={false}
            position="bottom-end"
            offsetY={4}
            disablePortal={false}
        >
            <Popover.Content>
                <MoreButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopover(true);
                    }}
                >
                    <MoreIcon />
                </MoreButton>
            </Popover.Content>
            <Popover.Popper style={{ padding: 0 }} onClick={(e) => e.stopPropagation()}>
                {actions.map(({ label, onClick }, index) => (
                    <ListItem onClick={onClick} key={`more-action-${index}`}>
                        <Typography variant="body2">{label}</Typography>
                    </ListItem>
                ))}
            </Popover.Popper>
        </Popover>
    );
}
