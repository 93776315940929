import { useTranslate } from "ui/locale";
import { BridgeSourceProps } from "./BridgeSource.types";
import FormGroup from "ui/common/components/layout/FormGroup/FormGroup";
import BridgeChainSelector from "../BridgeChainSelector/BridgeChainSelector";
import BridgeWalletSelector from "../BridgeWalletSelector/BridgeWalletSelector";
import { Col, Divider } from "@peersyst/react-components";
import clsx from "clsx";
import { BridgeSource as BridgeSourceEnum } from "xchain-sdk";

function BridgeSource({ source, style, className }: BridgeSourceProps): JSX.Element {
    const translate = useTranslate();

    return (
        <FormGroup
            label={translate(source === BridgeSourceEnum.ORIGIN ? "from" : "to")}
            style={style}
            className={clsx("BridgeSource", className)}
        >
            <Col>
                <BridgeChainSelector
                    label={translate("network")}
                    source={source}
                    css={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                />
                <Divider />
                <BridgeWalletSelector label={translate("wallet")} side={source} css={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
            </Col>
        </FormGroup>
    );
}

export default BridgeSource;
