import { Col, Divider, useTheme } from "@peersyst/react-components";
import { BridgeTokenSelectorProps } from "./BridgeTokenSelector.types";
import TokenSelectorToolbar from "../../input/TokenSelectorToolbar/TokenSelectorToolbar";
import { useControlled, useDebounce } from "@peersyst/react-hooks";
import useFilterTokens from "ui/bridge/query/useFilterTokens";
import clsx from "clsx";
import { BridgeTokenSelectorList, BridgeTokenSelectorRoot } from "./BridgeTokenSelector.styles";
import { useEffect } from "react";

export default function BridgeTokenSelector({
    tokens,
    isLoading,
    onSelect,
    defaultQuery = "",
    onQueryChange: onQueryChangeProp,
    query: queryProp,
    isFiltering,
    nothingToShow,
    className,
    style,
    onTokensFiltered,
    renderItem,
}: BridgeTokenSelectorProps): JSX.Element {
    const { spacing } = useTheme();
    const [query, setQuery] = useControlled(defaultQuery, queryProp, onQueryChangeProp);
    const {
        value,
        handleChange,
        debouncedValue: debouncedQuery,
        debouncing: debouncingQuery,
    } = useDebounce(query, { onChange: setQuery, delay: 500 });
    const { data: filteredTokens = [], isLoading: isFilterTokensLoading } = useFilterTokens(tokens, debouncedQuery);

    useEffect(() => {
        onTokensFiltered?.(filteredTokens, debouncedQuery);
    }, [filteredTokens]);

    const tokenSelectorLoading = isFilterTokensLoading || isFiltering || debouncingQuery;

    return (
        <BridgeTokenSelectorRoot gap={spacing[8]} className={clsx("BridgeTokenSelector", className)} style={style}>
            <TokenSelectorToolbar query={value} onQueryChange={handleChange} isLoading={tokenSelectorLoading} />
            <Col flex={1} css={{ overflow: "hidden" }} gap={"1px" /* Avoids the divider of being hidden when scrolling */}>
                <Divider />
                <BridgeTokenSelectorList
                    tokens={filteredTokens}
                    isLoading={isLoading}
                    onSelect={onSelect}
                    renderItem={renderItem}
                    nothingToShow={nothingToShow}
                    gap={5}
                />
            </Col>
        </BridgeTokenSelectorRoot>
    );
}
