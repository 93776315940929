import styled, { css } from "styled-components";
import { ExpandableIconProps } from "./ExpandableIcon.types";
import { ChevronDownIcon } from "ui/common/icons";

export const ExpandableIconRoot = styled(ChevronDownIcon)<ExpandableIconProps>(
    ({ theme, open }) => css`
        color: ${theme.palette.grey[500]};
        font-size: 1.25rem;
        transform: rotate(${open ? "180deg" : "0deg"});
        transition: transform 0.4s;
    `,
);
