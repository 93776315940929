import { ChainDto } from "common/models";
import { ChainType } from "xchain-sdk";
import ServiceFactory from "./ServiceFactory";
import { IProvider } from "./service/provider/IProvider";

// Disabling eslint rule because this is a factory function and we only care about the IProvider interface.
// eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
export default function ProviderFactory(chain: ChainDto): Promise<IProvider> {
    switch (chain.type) {
        case ChainType.EVM:
            return ServiceFactory.ethersService.getProvider(chain.rpcUrl);
        case ChainType.XRP:
            return ServiceFactory.xrplService.getProvider(chain.nodeUrl);
        default:
            throw new Error(`Chain ${chain.type} not supported`);
    }
}
