import KeyValueLocalStorageRepository from "data_access/repository/common/KeyValueLocalStorageRepository";
import RepositoryError from "data_access/repository/error/RepositoryError";
import { IXrplFaucetSeedsRepository } from "domain/adapter/repository/IXrplFaucetSeedsRepository";
import { XrplFaucetSeedsRepositoryErrorCodes } from "./XrplFaucetSeedsRepositoryErrorCodes";

export class XrplFaucetSeedsRepository
    extends KeyValueLocalStorageRepository<Record<string, string>>
    implements IXrplFaucetSeedsRepository
{
    constructor() {
        super("xrp-faucet-seed");
    }

    async getSeed(address: string): Promise<string> {
        const seed = await this.get(address);

        if (!seed) throw new RepositoryError(XrplFaucetSeedsRepositoryErrorCodes.XRP_FAUCET_SEED_NOT_FOUND);

        return seed;
    }

    async setSeed(address: string, seed: string): Promise<void> {
        return this.set(address, seed);
    }

    async removeSeed(address: string): Promise<void> {
        return this.clear(address);
    }
}
