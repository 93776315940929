import ServiceError from "data_access/service/error/ServiceError";
import { ethers } from "ethers";
import { Web3ServiceErrorCodes } from "./Web3ServiceErrorCodes";
import { IWeb3Service } from "domain/adapter/service/IWeb3Service";
import { IWeb3Signer } from "domain/adapter/service/signer/IWeb3Signer";
import { Web3Signer } from "data_access/service/signer/evm/ethers/web3/Web3Signer";
import { IEthersProvider } from "domain/adapter/service/provider/IEthersProvider";

export default class Web3Service implements IWeb3Service {
    /**
     * Handles service errors
     * @param e Error
     * @param handlers Error handlers
     */
    private handleError(e: any, handlers?: Partial<Record<number | "default", Web3ServiceErrorCodes | (() => void)>>): any {
        if (e instanceof ServiceError) throw e;

        if (handlers?.[e.code]) {
            const handler = handlers[e.code];
            if (typeof handler === "function") handler();
            else throw new ServiceError(handler!);
        } else if (e.code === 4001 || e.code === "ACTION_REJECTED") throw new ServiceError(Web3ServiceErrorCodes.WEB3_REQUEST_REJECTED);
        else if (handlers?.["default"]) {
            const handler = handlers["default"];
            if (typeof handler === "function") handler();
            else throw new ServiceError(handler!);
        } else throw e;
    }

    /**
     * Gets the ethers web3 provider
     */
    private getEthersWeb3Provider(): ethers.providers.Web3Provider {
        if ((window as any).ethereum) {
            return new ethers.providers.Web3Provider((window as any).ethereum, "any");
        } else {
            throw new ServiceError(Web3ServiceErrorCodes.WEB3_PROVIDER_NOT_FOUND);
        }
    }

    async getSigner(provider: IEthersProvider): Promise<IWeb3Signer> {
        try {
            const ethersWeb3Provider = this.getEthersWeb3Provider();
            await ethersWeb3Provider.send("eth_requestAccounts", []);
            const ethersWeb3Signer = ethersWeb3Provider.getSigner();
            return new Web3Signer(ethersWeb3Signer, provider);
        } catch (e) {
            return this.handleError(e, {
                default: Web3ServiceErrorCodes.WEB3_SIGNER_CONNECTION_FAILED,
            });
        }
    }

    async recoverSigner(provider: IEthersProvider): Promise<IWeb3Signer | undefined> {
        try {
            const ethersWeb3Provider = this.getEthersWeb3Provider();
            const ethersWeb3Signer = ethersWeb3Provider.getSigner();
            return new Web3Signer(ethersWeb3Signer, provider);
        } catch (e) {
            return undefined;
        }
    }
}
