import { config } from "common/config";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeSource } from "xchain-sdk";
import { useConnectedBridgeSourceWalletState } from "../hooks/useConnectedBridgeSourceWalletState";

export default function useDestinationCanReceive({
    enabled = true,
    refetchInterval = config.destinationCanReceiveRefetchInterval,
    cacheTime = 0,
    staleTime = 0,
    ...restOptions
}: QueryOptions<boolean, unknown, boolean, any[]> = {}): QueryResult<boolean> {
    const destinationWallet = useConnectedBridgeSourceWalletState(BridgeSource.DESTINATION);
    const { originChain } = useBridgeChainsState();
    const { originXChainBridgeChain } = useBridgeState() || {};

    return useQuery(
        [Queries.DESTINATION_CAN_RECEIVE, destinationWallet, originChain, originXChainBridgeChain],
        () => ControllerFactory.bridgeTransferController.destinationCanReceive(),
        {
            enabled: !!destinationWallet && !!originChain && !!originXChainBridgeChain && enabled,
            refetchInterval,
            cacheTime,
            staleTime,
            ...restOptions,
        },
    );
}
