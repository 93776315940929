import { Col, useTheme } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { BridgeTransferGroupDetail, BridgeTransferFeeTooltipIcon } from "./BridgeTransferDetails.styles";
import { useBridgeTransferFee } from "ui/bridge/query/useBridgeTransferFee";
import { useBridgeTransferFeeTooltip } from "ui/bridge/query/useBridgeTransferFeeTooltip";
import { Tooltip } from "ui/common/components/feedback/Tooltip/Tooltip";

function BridgeTransferDetails(): JSX.Element {
    const translate = useTranslate();
    const { spacing } = useTheme();

    const { data: bridgeTransferFee, isLoading: isBridgeTransferFeeLoading } = useBridgeTransferFee();
    const { data: feeTooltip, isLoading: isBridgeTransferFeeTooltipLoading } = useBridgeTransferFeeTooltip();

    return (
        <Col gap={spacing[2]}>
            <BridgeTransferGroupDetail
                label={translate("bridgeTransferFee")}
                complement={
                    isBridgeTransferFeeTooltipLoading ? undefined : <Tooltip icon={<BridgeTransferFeeTooltipIcon />} content={feeTooltip} />
                }
                value={`~ ${bridgeTransferFee}`}
                isLoading={isBridgeTransferFeeLoading}
            />
            <BridgeTransferGroupDetail
                label={translate("estimatedTimeOfArrival")}
                value={`~ 30 ${translate("seconds")} - 3 ${translate("minutes")}`}
            />
        </Col>
    );
}

export default BridgeTransferDetails;
