import { MetamaskWalletProviderErrors } from "../evm/metamask/errors";
import { XrplFaucetProviderErrors } from "../xrp/xrplFaucet/errors";
import { EvmWalletProviderErrors } from "../evm/errors";
import { XrpWalletProviderErrors } from "../xrp/errors";

export enum WalletProviderErrors {
    WALLET_CONNECTION_REJECTED = "WALLET_CONNECTION_REJECTED",
    WALLET_CONNECTION_FAILED = "WALLET_CONNECTION_FAILED",
    WALLET_NOT_CONNECTED = "WALLET_NOT_CONNECTED",
}

export const WalletProviderErrorCodes = {
    ...WalletProviderErrors,
    ...EvmWalletProviderErrors,
    ...XrpWalletProviderErrors,
    ...MetamaskWalletProviderErrors,
    ...XrplFaucetProviderErrors,
};

export type WalletProviderErrorCode = keyof typeof WalletProviderErrorCodes;
