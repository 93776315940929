import styled, { css } from "styled-components";
import { VerifiedIcon, WarningIcon } from "ui/common/icons";

export const TokenVerifiedIcon = styled(VerifiedIcon)(
    ({ theme }) => css`
        color: ${theme.palette.accent};
        font-size: "1.25rem";
    `,
);

export const TokenUnverifiedIcon = styled(WarningIcon)(
    ({ theme }) => css`
        color: ${theme.palette.orange[20]};
        font-size: "1.25rem";
    `,
);
