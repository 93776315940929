import ControllerFactory from "ui/adapter/ControllerFactory";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";

export default function useIsTokenAddressValid(
    tokenAddress: string | undefined,
    { enabled = true, ...restOptions }: QueryOptions<boolean, unknown, boolean, any[]> = {},
): QueryResult<boolean> {
    return useQuery(
        [Queries.IS_TOKEN_ADDRESS_VALID, tokenAddress],
        () => ControllerFactory.bridgeTokenController.isTokenAddressValid(tokenAddress!),
        {
            enabled: !!tokenAddress && enabled,
            ...restOptions,
        },
    );
}
