import { Col, useTheme } from "@peersyst/react-components";
import Button from "ui/common/components/input/Button/Button";
import { useTranslate } from "ui/locale";
import BridgeSources from "../BridgeSources/BridgeSources";
import BridgeTransferInput from "../BridgeTransferInput/BridgeTransferInput";
import { BridgeFormData, BridgeFormFields } from "./BridgeForm.types";
import BridgeTransferDetails from "../BridgeTransferDetails/BridgeTransferDetails";
import useAreWalletsConnected from "../../hooks/useAreWalletsConnected";
import useIsBridgeConfigSet from "ui/bridge/hooks/useIsBridgeConfigSet";
import AlertCallout from "ui/common/components/feedback/AlertCallout/AlertCallout";
import useDestinationCanReceive from "ui/bridge/query/useDestinationCanReceive";
import useTransfer from "ui/bridge/query/useTransfer";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { useEffect, useRef, useState } from "react";
import BridgeTransferModal from "../BridgeTransferModal/BridgeTransferModal";
import { BridgeTransferStartData } from "xchain-sdk";
import { BridgeFormRoot } from "./BridgeForm.styles";
import { useTransferError } from "./hooks/useTransferError";

function BridgeForm(): JSX.Element {
    const translate = useTranslate();
    const translateError = useTranslate("error");
    const { spacing } = useTheme();

    const areWalletsConnected = useAreWalletsConnected();
    const isBridgeConfigSet = useIsBridgeConfigSet();
    const { data: destinationCanReceive, isFetching: destinationCanReceiveIsLoading } = useDestinationCanReceive({
        keepPreviousData: true,
    });

    const [transferError, setTransferError] = useTransferError();
    const { mutate: transfer, isLoading: transferring } = useTransfer({ onError: setTransferError });

    const startData = useRef<BridgeTransferStartData | undefined>(undefined);
    const [openBridgeTransferModal, setOpenBridgeTransferModal] = useState(false);

    useEffect(() => {
        if (transferring) {
            const removeOnStart = ControllerFactory.bridgeTransferController.on("start", (data) => {
                startData.current = data;
                setOpenBridgeTransferModal(true);
            });
            return () => {
                removeOnStart();
            };
        }
    }, [transferring]);

    const handleFormSubmit = async ({ amount }: BridgeFormData) => {
        transfer(amount);
    };

    return (
        <>
            <BridgeFormRoot onSubmit={handleFormSubmit}>
                <Col gap={spacing[8]}>
                    <Col gap={spacing[7]}>
                        <Col gap={spacing[5]}>
                            <BridgeSources />
                            {areWalletsConnected && <BridgeTransferInput name={BridgeFormFields.AMOUNT} required />}
                        </Col>
                        {destinationCanReceive === false && <AlertCallout type="error" content={translateError("accountCannotReceive")} />}
                        {!!transferError && <AlertCallout type="error" content={transferError} />}
                        {areWalletsConnected && isBridgeConfigSet && <BridgeTransferDetails />}
                    </Col>
                    <Button
                        type="submit"
                        disabled={!areWalletsConnected || !isBridgeConfigSet || destinationCanReceiveIsLoading || !destinationCanReceive}
                        loading={transferring}
                    >
                        {translate("transfer")}
                    </Button>
                </Col>
            </BridgeFormRoot>
            {startData.current && (
                <BridgeTransferModal
                    open={openBridgeTransferModal}
                    onClose={() => setOpenBridgeTransferModal(false)}
                    onExited={() => (startData.current = undefined)}
                    data={startData.current}
                />
            )}
        </>
    );
}

export default BridgeForm;
