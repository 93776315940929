import ControllerFactory from "ui/adapter/ControllerFactory";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { XChainBridge } from "xchain-sdk";

export default function useFindXChainBridgeByQuery(
    query: string | undefined,
    { enabled = true, ...restOptions }: QueryOptions<XChainBridge | undefined, unknown, XChainBridge | undefined, any[]> = {},
): QueryResult<XChainBridge | undefined> {
    return useQuery(
        [Queries.FIND_X_CHAIN_BRIDGE_BY_QUERY, query],
        () => ControllerFactory.bridgeTokenController.findXChainBridgeByQuery(query!),
        {
            enabled: !!query && enabled,
            ...restOptions,
        },
    );
}
