import { BridgeSource } from "xchain-sdk";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";
import Amount from "common/utils/Amount";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { useGetBridgeWalletTokenBalance } from "./useGetBridgeWalletTokenBalance";
import { config } from "common/config";

/**
 * Returns the wallet balance for the given BridgeSource. Uses the current bridge to get the XChainBridge.
 */
export function useGetSourceWalletBalance<TData = Amount>(
    source: BridgeSource,
    { refetchInterval = config.balanceRefetchInterval, ...restOptions }: QueryOptions<Amount, unknown, TData, any[]> = {},
): QueryResult<TData> {
    const bridge = useBridgeState();

    return useGetBridgeWalletTokenBalance<TData>(source, bridge?.xChainBridge, { refetchInterval, ...restOptions });
}
