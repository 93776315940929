import { IconButtonProps } from "@peersyst/react-components";
import { ArrowButtonRoot } from "ui/common/components/input/ArrowButton/ArrowButton.styles";
import { ChevronLeftIcon, ChevronRightIcon } from "ui/common/icons";

export interface ArrowButtonProps extends Omit<IconButtonProps, "children" | "size"> {
    direction: "left" | "right";
}

const ArrowButton = ({ direction, ...rest }: ArrowButtonProps): JSX.Element => (
    <ArrowButtonRoot {...rest}>{direction === "left" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ArrowButtonRoot>
);

export default ArrowButton;
