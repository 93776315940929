import { useTranslate } from "ui/locale";
import { BridgeTransferGroupDetail } from "../BridgeTransferDetails/BridgeTransferDetails.styles";
import { Col } from "@peersyst/react-components";
import { spacing } from "ui/config/spacing";
import useGetCreateBridgeReward from "ui/bridge/query/useGetCreateBridgeReward";
import useFormatAmount from "ui/common/hooks/useFormatAmount";
import Amount from "common/utils/Amount";

function CreateBridgeTransferDetails(): JSX.Element {
    const translate = useTranslate();

    const { data: createBridgeReward = new Amount("0", 1, ""), isLoading: isCreateBridgeRewardLoading } =
        useGetCreateBridgeReward("doorAddress");
    const formatAmount = useFormatAmount();

    return (
        <Col gap={spacing[2]}>
            <BridgeTransferGroupDetail
                label={translate("bridgeCreationFee")}
                value={isCreateBridgeRewardLoading ? translate("loading") : formatAmount(createBridgeReward)}
            />
            <BridgeTransferGroupDetail label={translate("estimatedCreationTime")} value={`~ 1 - 3 ${translate("minutes")}`} />
        </Col>
    );
}

export default CreateBridgeTransferDetails;
