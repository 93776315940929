import { Row } from "@peersyst/react-components";
import { Avatar, ChainNetworkBadge } from "./ChainAvatar.styles";
import { ChainAvatarProps } from "./ChainAvatar.types";
import { getNetworkType } from "common/utils/network";
import { NetworkType } from "common/models";

const ChainAvatar = ({ imageUrl, name }: ChainAvatarProps): JSX.Element => {
    const networkType = getNetworkType(name);

    return (
        <Row css={{ position: "relative", display: "inline-block" }}>
            <Avatar src={imageUrl} alt={`${name}-logo`} />
            {networkType !== NetworkType.MAINNET && <ChainNetworkBadge label={networkType} />}
        </Row>
    );
};

export default ChainAvatar;
