import { useTheme } from "@peersyst/react-components";
import BridgeSource from "../BridgeSource/BridgeSource";
import SwapButton from "../SwapButton/SwapButton";
import { BridgeSourcesRoot } from "./BridgeSources.styles";
import { BridgeSource as BridgeSourceEnum } from "xchain-sdk";

function BridgeSources(): JSX.Element {
    const { spacing } = useTheme();

    return (
        <BridgeSourcesRoot flex={1} gap={spacing[4]} alignItems="center">
            <BridgeSource source={BridgeSourceEnum.ORIGIN} />
            <SwapButton />
            <BridgeSource source={BridgeSourceEnum.DESTINATION} />
        </BridgeSourcesRoot>
    );
}

export default BridgeSources;
