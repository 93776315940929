import { BridgeOriginVerifiedTokenSelectorListItemProps } from "./BridgeOriginVerifiedTokenSelectorListItem.types";
import { BridgeSource } from "xchain-sdk";
import { useGetBridgeWalletTokenBalance } from "../../../../wallet/query/useGetBridgeWalletTokenBalance";
import { TokenSelectorListItem } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem";

export default function BridgeOriginVerifiedTokenSelectorListItem({
    item,
    onSelect,
}: BridgeOriginVerifiedTokenSelectorListItemProps): JSX.Element {
    const { data: tokenBalance, isLoading: isBalanceLoading } = useGetBridgeWalletTokenBalance(BridgeSource.ORIGIN, item.xChainBridge);

    return <TokenSelectorListItem item={item} onSelect={onSelect} balance={tokenBalance} isBalanceLoading={isBalanceLoading} />;
}

export const renderBridgeOriginVerifiedTokenSelectorListItem = (props: BridgeOriginVerifiedTokenSelectorListItemProps, index: number) => {
    return <BridgeOriginVerifiedTokenSelectorListItem key={`verified-token-selector-${index}`} {...props} />;
};
