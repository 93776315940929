import { LoaderIcon } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { TokenSelectorToolbarProps } from "./TokenSelectorToolbar.types";
import { Searchbar } from "./TokenSelectorToolbar.styles";

export default function TokenSelectorToolbar({ query, onQueryChange, isLoading }: TokenSelectorToolbarProps): JSX.Element {
    const translate = useTranslate();

    return (
        <Searchbar
            label={translate("search")}
            placeholder={translate("searchToken")}
            value={query}
            onChange={onQueryChange}
            suffix={isLoading ? <LoaderIcon /> : undefined}
        />
    );
}
