import { IWeb3Service } from "../../../../adapter/service/IWeb3Service";
import isServiceError from "domain/adapter/utils/isServiceError";
import { MetamaskWalletProviderErrors } from "./errors";
import { ConnectionError } from "common/models";
import { IServiceError } from "domain/adapter/service/IServiceError";
import { IWeb3Signer } from "domain/adapter/service/signer/IWeb3Signer";
import { ChainType } from "xchain-sdk";
import { IEthersService } from "domain/adapter/service/IEthersService";
import { EvmWalletProvider } from "../EvmWalletProvider";
import { IEthersProvider } from "domain/adapter/service/provider/IEthersProvider";
import { IMultipleChainWalletProvider } from "ui/adapter/providers/IWalletProvider";

export class MetamaskWalletProvider
    extends EvmWalletProvider<IEthersProvider, IWeb3Signer, MetamaskWalletProviderErrors, void>
    implements IMultipleChainWalletProvider
{
    constructor(
        private readonly ethersService: IEthersService,
        private readonly web3Service: IWeb3Service,
    ) {
        super("metamask", ChainType.EVM);
    }

    protected errorHandlers: Partial<Record<IServiceError["code"] | "default", MetamaskWalletProviderErrors | (() => void)>> = {
        WEB3_REQUEST_REJECTED: MetamaskWalletProviderErrors.METAMASK_REQUEST_REJECTED,
    };

    /**
     * Checks if chain is valid
     */
    private isChainValid(chainId: number): boolean {
        return chainId === this.chain.chainId;
    }

    /**
      Handles the validity of the given chain
     * @param chainId The chain id to handle
      TODO: Delete on double metamask refactor
     */
    private handleChainValidity(chainId: number): void {
        if (!this.isChainValid(chainId)) this.handleInvalidChain();
        else this.handleValidChain();
    }

    protected getProvider(): Promise<IEthersProvider> {
        return this.ethersService.getProvider(this.chain.rpcUrl);
    }

    protected getSigner(): Promise<IWeb3Signer> {
        return this.web3Service.getSigner(this.provider);
    }

    protected recoverSigner(): Promise<IWeb3Signer | undefined> {
        // Address is not taken into account since it is not needed for the recovery.
        // The first available address will be used.
        return this.web3Service.recoverSigner(this.provider);
    }

    protected async afterConnect(): Promise<void> {
        const chainId = await this.signer.getChain();

        if (!this.isChainValid(chainId)) this.handleInvalidChain();

        const removeOnAccountsChange = this.signer.onAccountsChange((accounts) => {
            if (accounts.length === 0) {
                this.disconnect();
            } else if (this.address?.toLowerCase() !== accounts[0].toLowerCase()) {
                this.connect(accounts[0]);
            }
        });
        // TODO: Delete on double metamask refactor
        const removeOnChainChange = this.signer.onChainChange((chainId) =>
            this.handleChainValidity(parseInt(chainId.replace("0x", ""), 16)),
        );
        // TODO: Delete on double metamask refactor
        const removeOnSetChain = this.on("setChain", async (chain) => {
            if (chain && chain.chainId) {
                const connectedChainId = await this.signer.getChain();
                this.handleChainValidity(connectedChainId);
            }
        });
        const removeOnDisconnect = this.on("disconnect", () => {
            removeOnAccountsChange();
            removeOnChainChange();
            removeOnSetChain();
            removeOnDisconnect();
        });
    }

    protected onConnectionError(e: any): [error: ConnectionError, code?: MetamaskWalletProviderErrors] | undefined {
        if (isServiceError(e)) {
            if (e.code === "WEB3_PROVIDER_NOT_FOUND") return [ConnectionError.FAILED, MetamaskWalletProviderErrors.METAMASK_NOT_INSTALLED];
            else if (e.code === "WEB3_REQUEST_REJECTED")
                return [ConnectionError.REJECTED, MetamaskWalletProviderErrors.METAMASK_REQUEST_REJECTED];
        }
    }

    async addChain(): Promise<void> {
        try {
            await this.signer.addChain({
                chainId: this.chain.chainId!,
                chainName: this.chain.name,
                rpcUrls: [this.chain.rpcUrl],
                blockExplorerUrls: [this.chain.explorerUrl],
                nativeCurrency: {
                    symbol: this.chain.nativeToken,
                    decimals: this.chain.nativeDecimals,
                },
            });
        } catch (e) {
            return this.handleError(e);
        }
    }

    async switchToChain(): Promise<void> {
        try {
            await this.signer.switchToChain(this.chain.chainId!);
        } catch (e) {
            let chainNotFound = false;

            this.handleError(e, {
                WEB3_CHAIN_NOT_FOUND: () => {
                    chainNotFound = true;
                },
                default: MetamaskWalletProviderErrors.COULD_NOT_SWITCH_METAMASK_CHAIN,
            });

            // Has to be done this way to perform the await
            if (chainNotFound) await this.addChain();
        }
    }
}
