import { TabGroup, TabPanel, Tabs } from "@peersyst/react-components";
import styled, { css } from "styled-components";
import Modal from "ui/common/components/feedback/Modal/Modal";

export const BridgeOriginTokenSelectorModalRoot = styled(Modal)`
    width: 33.625rem;
    height: 41.25rem;
    padding-bottom: 0;
    overflow: hidden;

    .ModalContainer {
        flex: 1;
        overflow: hidden;
    }
`;

export const BridgeTokenSelectorTabs = styled(Tabs)(
    () => css`
        row-gap: 2rem;
        flex: 1;
        overflow: hidden;
    `,
);

export const BridgeTokenSelectorTabGroup = styled(TabGroup)(
    ({ theme }) => css`
        .TabGroupContainer {
            justify-content: center;
        }
        border-bottom: 1px solid ${theme.palette.grey[600]};
    `,
);

export const BridgeTokenSelectorTabPanel = styled(TabPanel)`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;
