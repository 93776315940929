import getWalletSourceProvider from "ui/bridge/utils/getWalletSourceProvider";
import { MutationOptions, MutationResult, useMutation } from "ui/query/react-query-overrides";
import { BridgeSource } from "xchain-sdk";

export default function useSwitchToChain(
    side: BridgeSource,
    options?: MutationOptions<void, unknown, void>,
): MutationResult<void, unknown, void> {
    return useMutation(async () => {
        const walletProvider = getWalletSourceProvider(side)!;
        if (walletProvider.isMultipleChain()) {
            return await walletProvider.switchToChain();
        } else {
            // eslint-disable-next-line no-console
            console.error(
                `Tried to switch chain in a non-multiple chain wallet provider: ${walletProvider.providerId} ${walletProvider.address}`,
            );
        }
    }, options);
}
