import { useTranslate } from "ui/locale";
import { BridgeOriginTokenSelectorModalTabs, BridgeOriginTokenSelectorTab } from "../BridgeOriginTokenSelectorModal.types";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { useMemo } from "react";

export default function useBridgeOriginTokenSelectorTabs(): BridgeOriginTokenSelectorTab[] {
    const translate = useTranslate();
    const { originChain, destinationChain } = useBridgeChainsState();

    const bridgeOriginTokenSelectorTabs = useMemo(() => {
        const tabs = [
            {
                label: translate("verified"),
                index: BridgeOriginTokenSelectorModalTabs.VERIFIED,
            },
        ];

        if (originChain?.type !== "evm" || destinationChain?.type !== "evm") {
            tabs.push({
                label: translate("custom"),
                index: BridgeOriginTokenSelectorModalTabs.CUSTOM,
            });
        }

        return tabs;
    }, [originChain, destinationChain]);

    return bridgeOriginTokenSelectorTabs;
}
