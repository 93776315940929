import { Col, Typography, Divider, Label, useTheme } from "@peersyst/react-components";
import Button from "ui/common/components/input/Button/Button";
import { useTranslate } from "ui/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";
import AmountDisplay from "ui/common/components/display/AmountDisplay/AmountDisplay";
import { Link } from "react-router-dom";
import Amount from "common/utils/Amount";
import BridgeBlockchainAddress from "ui/bridge/containers/BridgeBlockchainAddress/BridgeBlockchainAddress";
import BridgeAddress from "ui/bridge/containers/BridgeAddress/BridgeAddres";
import { BridgeSource, BridgeTransferResult } from "xchain-sdk";
import { useGetSourceToken } from "ui/bridge/query/useGetSourceToken";

const SuccessBridgePage = (): JSX.Element => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const { spacing } = useTheme();
    const { data: destinationToken } = useGetSourceToken(BridgeSource.DESTINATION);

    if (!locationState.result) navigate(BridgeRoutes.BRIDGE, { replace: true });

    const transferResult = locationState.result as BridgeTransferResult;

    const amount = destinationToken ? Amount.fromDecToken(transferResult.amount, destinationToken) : undefined;

    return (
        <Col gap={spacing[6]} css={{ padding: spacing[8] }}>
            <Typography variant="h4" textAlign="center" fontWeight={700}>
                {translate("yourTransactionHasBeenSent")}
            </Typography>
            <Col gap={spacing[8]}>
                {transferResult.isCreateAccount && (
                    <Label label={translate("originTransactionHash")}>
                        <BridgeBlockchainAddress
                            source={BridgeSource.ORIGIN}
                            address={transferResult.createAccountCommit.hash}
                            action="link"
                            type="transaction"
                            variant="body1"
                        />
                    </Label>
                )}
                {!transferResult.isCreateAccount && (
                    <>
                        <Label label={translate("originTransactionHash")}>
                            <BridgeBlockchainAddress
                                source={BridgeSource.ORIGIN}
                                address={transferResult.commit.hash}
                                action="link"
                                type="transaction"
                                variant="body1"
                            />
                        </Label>
                        <Label label={translate("destinationTransactionHash")}>
                            <BridgeBlockchainAddress
                                source={BridgeSource.DESTINATION}
                                address={transferResult.createClaim.hash}
                                action="link"
                                type="transaction"
                                variant="body1"
                            />
                        </Label>
                    </>
                )}
                <Label label={translate("fromAddress")}>
                    <BridgeAddress source={BridgeSource.ORIGIN} address={transferResult.originAddress} />
                </Label>
                <Label label={translate("toAddress")}>
                    <BridgeAddress source={BridgeSource.DESTINATION} address={transferResult.destinationAddress} />
                </Label>
                {amount && (
                    <Label label={translate("receive")}>
                        <AmountDisplay amount={amount} />
                    </Label>
                )}
                <Divider color="grey.600" />
                <Link to={BridgeRoutes.BRIDGE}>
                    <Button fullWidth>{translate("done")}</Button>
                </Link>
            </Col>
        </Col>
    );
};

export default SuccessBridgePage;
