import { useIsDestinationActive } from "./useIsDestinationActive";
import { useCallback } from "react";
import { useTranslate } from "ui/locale";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";

export function useBridgeTransferFeeTooltip(
    options: Omit<QueryOptions<boolean, unknown, string, any[]>, "select"> = {},
): QueryResult<string> {
    const translate = useTranslate();

    const select = useCallback(
        (isDestinationActive: boolean) =>
            isDestinationActive ? translate("paidByTheDestinationAccount") : translate("paidByTheOriginAccount"),
        [translate],
    );

    return useIsDestinationActive({ select, ...options });
}
