import { Col, Expandable, Row, Typography } from "@peersyst/react-components";
import ChainSequence from "ui/activity/components/display/ChainSequence/ChainSequence";
import { TransferProps } from "./Transfer.types";
import { useTranslate } from "ui/locale";
import { spacing } from "ui/config/spacing";
import Amount from "common/utils/Amount";
import TransferField from "./TransferField/TransferField";
import BlockchainAddressExplorerLink from "ui/blockchain/components/feedback/BlockchainAddressExplorerLink/BlockchainAddressExplorerLink";
import DateDisplay from "ui/common/components/display/DateDisplay/DateDisplay";
import { DateFormat } from "ui/common/components/display/DateDisplay/hooks/formatDate.types";
import { TransferToken } from "./TransferToken/TransferToken";
import { formatCurrency } from "common/utils/token";
import { BridgeDirection } from "xchain-sdk";

const Transfer = ({ transfer }: TransferProps): JSX.Element => {
    const translate = useTranslate();

    const amount = new Amount(
        transfer.value,
        transfer.direction === BridgeDirection.LOCKING_TO_ISSUING
            ? transfer.issuingChainToken.decimals
            : transfer.lockingChainToken.decimals,
        formatCurrency(transfer.lockingChainToken.currency),
    );

    return (
        <Expandable className="Transfer">
            <Expandable.Display>
                <Row flex={1} alignItems="center" justifyContent="space-between">
                    <ChainSequence origin={transfer.sourceChain} destination={transfer.destinationChain} />
                    <Row alignItems="center" gap={spacing[4]}>
                        <TransferToken
                            tokenImageUrl={transfer.lockingChainToken.imageUrl}
                            lockingChainImageUrl={transfer.lockingChain.imageUrl}
                        />
                        <Col justifyContent="center" alignItems="start">
                            <Row gap={spacing[2]}>
                                <Typography variant="body1" textAlign="right" fontWeight={700}>
                                    {amount.formatAmount()} {amount.currency}
                                </Typography>
                            </Row>
                            <DateDisplay variant="caption2" color="grey.400" textAlign="right" date={transfer.createdAt} />
                        </Col>
                    </Row>
                </Row>
            </Expandable.Display>
            <Expandable.Body>
                <Expandable.Content>
                    <Col gap={spacing[4]}>
                        <TransferField label={translate("from")}>
                            <BlockchainAddressExplorerLink
                                chain={transfer.sourceChain}
                                address={transfer.from}
                                variant="body1"
                                type="account"
                            />
                        </TransferField>
                        <TransferField label={translate("to")}>
                            <BlockchainAddressExplorerLink
                                variant="body1"
                                type="account"
                                address={transfer.to}
                                chain={transfer.destinationChain}
                            />
                        </TransferField>
                    </Col>
                </Expandable.Content>
                <Expandable.Footer>
                    <Row alignItems="flex-start" justifyContent="space-between">
                        <TransferField label={translate("amount")}>
                            <Row gap={spacing[2]}>
                                <Typography variant="body1">
                                    {amount.formatAmount()} {amount.currency}
                                </Typography>
                            </Row>
                        </TransferField>
                        <TransferField label={translate("received")}>
                            <Row gap={spacing[2]}>
                                <Typography variant="body1">
                                    {amount.formatAmount()} {amount.currency}
                                </Typography>
                            </Row>
                        </TransferField>
                        <TransferField label={translate("date")} css={{ width: "fit-content" }}>
                            <Row gap={spacing[2]}>
                                <DateDisplay format={DateFormat.DATE_TIME} variant="body1" date={transfer.createdAt} />
                            </Row>
                        </TransferField>
                    </Row>
                </Expandable.Footer>
            </Expandable.Body>
        </Expandable>
    );
};

export default Transfer;
