import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { MutationOptions, MutationResult, useMutation, useQueryClient } from "ui/query/react-query-overrides";
import { BridgeSource, BridgeTransferResult } from "xchain-sdk";
import { useConnectedBridgeSourceWalletState } from "../hooks/useConnectedBridgeSourceWalletState";

export default function useTransfer(
    options?: MutationOptions<BridgeTransferResult, unknown, string>,
): MutationResult<BridgeTransferResult, unknown, string> {
    const { originChain, destinationChain } = useBridgeChainsState();
    const originWallet = useConnectedBridgeSourceWalletState(BridgeSource.ORIGIN);
    const destinationWallet = useConnectedBridgeSourceWalletState(BridgeSource.DESTINATION);
    const bridge = useBridgeState();

    const queryClient = useQueryClient();
    return useMutation((amount: string) => ControllerFactory.bridgeTransferController.transfer(amount), {
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries([Queries.GET_TRANSFERS, originChain, destinationChain, originWallet, destinationWallet]),
                queryClient.invalidateQueries([
                    Queries.BRIDGE_SOURCE_TOKEN_BALANCE,
                    originWallet?.address,
                    BridgeSource.ORIGIN,
                    JSON.stringify(bridge?.xChainBridge),
                ]),
                queryClient.invalidateQueries([
                    Queries.BRIDGE_SOURCE_TOKEN_BALANCE,
                    destinationWallet?.address,
                    BridgeSource.ORIGIN,
                    JSON.stringify(bridge?.xChainBridge),
                ]),
                queryClient.invalidateQueries([Queries.IS_DESTINATION_ACTIVE, destinationWallet?.address]),
            ]);
        },
        ...options,
    });
}
