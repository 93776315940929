import { ChainDto } from "common/models";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { BridgeSource } from "xchain-sdk";

export function useBridgeSourceChainState<A extends boolean = false>(
    source: BridgeSource,
    assert: A = false as A,
): A extends true ? ChainDto : ChainDto | undefined {
    const { originChain, destinationChain } = useBridgeChainsState();

    const chain = source === BridgeSource.ORIGIN ? originChain : destinationChain;

    if (assert && !chain) throw new Error(`Chain ${source} is not set`);

    return chain as A extends true ? ChainDto : ChainDto | undefined;
}
