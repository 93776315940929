import { IBridgeController } from "ui/adapter/controllers/IBridgeController";
import { IBridgeWalletsController } from "ui/adapter/controllers/IBridgeWalletsController";
import { IBridgeTransferController } from "../../../ui/adapter/controllers/IBridgeTransferController";
import { IWalletProvider } from "ui/adapter/providers/IWalletProvider";
import DomainError from "domain/error/DomainError";
import BridgeErrorCodes from "../BridgeErrorCodes";
import {
    Bridge,
    BridgeTransferResult,
    BridgeManagerEvents,
    isCreateBridgeRequestXChainWallet,
    Confirmed,
    CreateBridgeRequestTransaction,
} from "xchain-sdk";
import { IBridgeManagerController } from "ui/adapter/controllers/IBridgeManagerController";
import { IBridgeChainsController } from "ui/adapter/controllers/IBridgeChainsController";
import { IBridgeTokenController } from "ui/adapter/controllers/IBridgeTokenController";
import { CustomToken } from "common/models";

export default class BridgeTransferController implements IBridgeTransferController {
    constructor(
        private readonly bridgeChainsController: IBridgeChainsController,
        private readonly bridgeWalletsController: IBridgeWalletsController,
        private readonly bridgeManagerController: IBridgeManagerController,
        private readonly bridgeController: IBridgeController,
        private readonly bridgeTokenController: IBridgeTokenController,
    ) {}

    /**
     * Gets the origin wallet provider.
     */
    private getOriginWalletProvider(): IWalletProvider {
        const originWalletProvider = this.bridgeWalletsController.originWalletProvider;
        if (!originWalletProvider) throw new DomainError(BridgeErrorCodes.ORIGIN_WALLET_PROVIDER_NOT_SET);
        return originWalletProvider;
    }

    /**
     * Gets the destination wallet provider.
     */
    private getDestinationWalletProvider(): IWalletProvider {
        const destinationWalletProvider = this.bridgeWalletsController.destinationWalletProvider;
        if (!destinationWalletProvider) throw new DomainError(BridgeErrorCodes.DESTINATION_WALLET_PROVIDER_NOT_SET);
        return destinationWalletProvider;
    }

    private getBridge(): Bridge {
        const bridge = this.bridgeController.getBridge();
        if (!bridge) throw new DomainError(BridgeErrorCodes.BRIDGE_NOT_SET);
        return bridge;
    }

    /**
     * Swaps the bridge.
     */
    swap(): void {
        this.bridgeChainsController.swap();
        this.bridgeWalletsController.swap();
        this.bridgeController.swap();
    }

    /**
     * Check if the transfer is a create account.
     */
    transferCanCreateAccount(): boolean {
        const bridge = this.getBridge();

        return bridge.isNativeOriginIssue;
    }

    /**
     * Check if the destination can receive the issued tokens.
     */
    async destinationCanReceive(): Promise<boolean> {
        const destinationWalletProvider = this.getDestinationWalletProvider();

        if (this.transferCanCreateAccount()) return true;
        else return await destinationWalletProvider.isActive();
    }

    /**
     * Transfers the tokens.
     * @pre Destination can receive the tokens.
     */
    async transfer(amount: string): Promise<BridgeTransferResult> {
        const bridgeManager = this.bridgeManagerController.getBridgeManager();

        const bridge = this.getBridge();
        const originWalletProvider = this.getOriginWalletProvider();
        const destinationWalletProvider = this.getDestinationWalletProvider();

        const result = await bridgeManager.transfer(bridge, originWalletProvider, destinationWalletProvider, amount);
        return result;
    }

    /**
     * Given a token address, created a bridge.
     * @pre Token address is valid.
     * @pre `originXChainBridgeChain` and `originWalletProvider` have the same `type`.
     * @param tokenAddress The address of the token.
     */
    async createBridge(token: CustomToken): Promise<Confirmed<CreateBridgeRequestTransaction>> {
        const bridgeManager = this.bridgeManagerController.getBridgeManager();
        const originXChainBridgeChain = this.getBridge().originXChainBridgeChain;
        const originWalletProvider = this.getOriginWalletProvider();

        if (!isCreateBridgeRequestXChainWallet(originWalletProvider)) {
            throw new DomainError(BridgeErrorCodes.WALLET_PROVIDER_DOES_NOT_SUPPORT_BRIDGE_CREATION);
        }

        this.bridgeTokenController.addPendingCustomToken(token, originXChainBridgeChain.doorAddress.address, originXChainBridgeChain.type);

        try {
            const res = await bridgeManager.createBridgeRequest(
                originXChainBridgeChain.doorAddress.address,
                token.issuer,
                originWalletProvider,
            );

            // Use then/catch to not wait for the creation to finish.
            res.waitCreation()
                .then(() => {
                    this.bridgeTokenController.deletePendingCustomToken(token);
                    this.bridgeTokenController.addCustomToken(token);
                })
                .catch(() => {
                    this.bridgeTokenController.deletePendingCustomToken(token);
                });

            return res;
        } catch (e) {
            this.bridgeTokenController.deletePendingCustomToken(token);
            throw e;
        }
    }

    /**
     * Sets a listener for the given event.
     * @param event
     * @param listener
     * @returns
     */
    on<Event extends keyof BridgeManagerEvents>(event: Event, listener: BridgeManagerEvents[Event]): () => void {
        const bridgeManager = this.bridgeManagerController.getBridgeManager();
        return bridgeManager.on(event, listener);
    }
}
