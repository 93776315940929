import { Fragment, PropsWithChildren } from "react";
import { ToastProvider } from "@peersyst/react-components";
import QueryClientProvider from "./query/QueryClientProvider";
import { ConfigProvider } from "ui/config";
import ErrorHandler from "./common/components/feedback/ErrorHandler/ErrorHandler";
import AnalyticsProvider from "./analytics/AnalyticsProvider";

const Providers = ({ children }: PropsWithChildren<unknown>): JSX.Element => (
    <Fragment>
        <AnalyticsProvider>
            <ConfigProvider>
                <ToastProvider>
                    <ErrorHandler>
                        <QueryClientProvider>{children}</QueryClientProvider>
                    </ErrorHandler>
                </ToastProvider>
            </ConfigProvider>
        </AnalyticsProvider>
    </Fragment>
);

export default Providers;
