import { useMemo } from "react";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";
import { useTranslate } from "ui/locale";
import { HomeTab } from "../HomeTabs.types";
import { ActivityRoutes } from "ui/activity/router/ActivityRouter.types";

export default function useHomeTabs(): HomeTab[] {
    const translate = useTranslate();

    return useMemo(
        () => [
            {
                path: BridgeRoutes.BRIDGE,
                label: translate("bridge"),
            },
            {
                path: ActivityRoutes.ACTIVITY,
                label: translate("activity"),
            },
        ],
        [translate],
    );
}
