import { BridgeTransactionStepProps } from "./BridgeTransactionStep.types";
import TransactionStep from "ui/bridge/components/feedback/TransactionStep/TransactionStep";
import useBridgeSourceWalletState from "ui/bridge/hooks/useBridgeSourceWalletState";
import { LocaleErrorResource, LocaleTranslationResource, useTranslate } from "ui/locale";
import { useEffect, useState } from "react";
import ControllerFactory from "ui/adapter/ControllerFactory";
import UIError from "ui/error/UIError";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { BridgeSource, Confirmed, Transaction } from "xchain-sdk";

function BridgeTransactionStep({ stage, isFirst = false }: BridgeTransactionStepProps): JSX.Element {
    const translate = useTranslate();
    const translateError = useTranslate("error");

    const bridgeSide = stage.toLocaleLowerCase().includes("claim") ? BridgeSource.DESTINATION : BridgeSource.ORIGIN;

    const bridgeChain = useBridgeSourceChainState(bridgeSide, true);
    const bridgeWallet = useBridgeSourceWalletState(bridgeSide);

    const [waiting, setWaiting] = useState(isFirst);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [transaction, setTransaction] = useState<Transaction | undefined>(undefined);

    useEffect(() => {
        // @pre: transfer has started
        const removeOnStageRequested = ControllerFactory.bridgeTransferController.on(`${stage}Requested`, () => {
            setWaiting(true);
            setLoading(false);
            setSuccess(false);
            setError(undefined);
        });
        const removeOnStageSigned = ControllerFactory.bridgeTransferController.on(`${stage}Signed`, () => {
            setWaiting(false);
            setLoading(true);
        });
        const removeOnStageConfirmed = ControllerFactory.bridgeTransferController.on(`${stage}Confirmed`, (tx: Confirmed<Transaction>) => {
            setTransaction(tx);
            setLoading(false);
            setSuccess(true);
        });
        const removeOnStageError = ControllerFactory.bridgeTransferController.on(`${stage}Failed`, (error) => {
            setLoading(false);
            const uiError = new UIError(error.code, error.params);
            setError(
                translateError(
                    [uiError.message as LocaleErrorResource, "somethingWentWrong"],
                    uiError.params ? { ...uiError.params } : undefined,
                ),
            );
        });
        return () => {
            removeOnStageRequested();
            removeOnStageSigned();
            removeOnStageConfirmed();
            removeOnStageError();
        };
    }, [stage]);

    if (bridgeWallet.connection !== "connected") throw new Error(`${bridgeSide.capitalize()} wallet is not connected`);

    return (
        <TransactionStep
            address={bridgeWallet.address}
            chain={bridgeChain}
            title={translate([
                `${bridgeChain.type}${stage.capitalize()}StageTitle` as LocaleTranslationResource,
                `${stage}StageTitle` as LocaleTranslationResource,
            ])}
            subtitle={{
                default: translate("signatureMessage", { providerId: bridgeWallet.providerId }),
                loading: translate("broadcastingMessage", { chain: bridgeChain.name }),
            }}
            waiting={waiting}
            loading={loading}
            success={success}
            error={error}
            transaction={transaction}
        />
    );
}

export default BridgeTransactionStep;
