import { BridgeToken, BridgeToken__factory } from "@peersyst/xrp-evm-contracts";
import { XChainTypes } from "@peersyst/xrp-evm-contracts/dist/typechain-types/contracts/BridgeDoorMultiToken";
import { EVM_NATIVE_DECIMALS } from "common/constants/evm.constants";
import { Token } from "common/models/wallet/Token";
import { IEthersProvider } from "domain/adapter/service/provider/IEthersProvider";
import { constants } from "ethers";
import { ChainType, EthersXChainProvider, XChainBridgeChainFormat, XChainBridgeFormat } from "xchain-sdk";

export class EthersProvider extends EthersXChainProvider implements IEthersProvider {
    /**
     * Gets the token contract.
     * @param tokenAddress The token address.
     */
    protected getTokenContract(tokenAddress: string): BridgeToken {
        return BridgeToken__factory.connect(tokenAddress, this.ethersProvider);
    }

    /**
     * Gets the token of the specified xChainBridgeChain.
     * @param doorAddress The bridge door address.
     * @param xChainBridge The XChainBridge config.
     */
    async getXChainBridgeChainToken(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainTypes.BridgeConfigStruct,
    ): Promise<Token> {
        const tokenContract = await this.getBridgeTokenContract(xChainBridgeChain.doorAddress, xChainBridge);
        const [decimals, currency] = await Promise.all([tokenContract.decimals(), tokenContract.symbol()]);

        return {
            currency,
            issuer: xChainBridgeChain.issue.issuer,
            decimals,
        };
    }

    /**
     * Gets the balance of a xChainBridgeChain token.
     * @param address The address of the account.
     * @param xChainBridgeChain The XChainBridgeChain in EVM format.
     * @param xChainBridge The XChainBridge in EVM format.
     */
    async getXChainBridgeChainTokenBalance(
        address: string,
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<string> {
        const tokenContract = await this.getBridgeTokenContract(xChainBridgeChain.doorAddress, xChainBridge);
        const balance = await tokenContract.balanceOf(address);
        return balance.toString();
    }

    /**
     * Gets the decimals of a xChainBridgeChain token
     * @param xChainBridgeChain The XChainBridgeChain in EVM format.
     * @param xChainBridge The XChainBridge in EVM format.
     */
    async getXChainBridgeChainTokenDecimals(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<number> {
        const tokenContract = await this.getBridgeTokenContract(xChainBridgeChain.doorAddress, xChainBridge);
        return await tokenContract.decimals();
    }

    /**
     * Gets the name of a xChainBridgeChain token
     * @param xChainBridgeChain The XChainBridgeChain in EVM format.
     * @param xChainBridge The XChainBridge in EVM format.
     */
    async getXChainBridgeChainTokenName(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<string> {
        const tokenContract = await this.getBridgeTokenContract(xChainBridgeChain.doorAddress, xChainBridge);
        return await tokenContract.name();
    }

    async getXChainBridgeToken(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<Token> {
        if (xChainBridgeChain.issue.issuer === constants.AddressZero)
            return Promise.resolve({
                currency: xChainBridgeChain.issue.currency,
                decimals: EVM_NATIVE_DECIMALS,
            });
        else return this.getXChainBridgeChainToken(xChainBridgeChain, xChainBridge);
    }

    async getXChainBridgeTokenBalance(
        address: string,
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<string> {
        if (xChainBridgeChain.issue.issuer === constants.AddressZero) return this.getNativeBalance(address);
        else return this.getXChainBridgeChainTokenBalance(address, xChainBridgeChain, xChainBridge);
    }

    async getXChainBridgeTokenDecimals(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<number> {
        if (xChainBridgeChain.issue.issuer === constants.AddressZero) return Promise.resolve(EVM_NATIVE_DECIMALS);
        else return this.getXChainBridgeChainTokenDecimals(xChainBridgeChain, xChainBridge);
    }

    async getXChainBridgeTokenName(
        xChainBridgeChain: XChainBridgeChainFormat<ChainType.EVM>,
        xChainBridge: XChainBridgeFormat<ChainType.EVM>,
    ): Promise<string> {
        if (xChainBridgeChain.issue.issuer === constants.AddressZero) return Promise.resolve(xChainBridgeChain.issue.currency);
        else return this.getXChainBridgeChainTokenName(xChainBridgeChain, xChainBridge);
    }

    async getTokenDecimals(tokenAddress: string): Promise<number> {
        const tokenContract = this.getTokenContract(tokenAddress);
        return await tokenContract.decimals();
    }

    async getTokenName(tokenAddress: string): Promise<string> {
        const tokenContract = this.getTokenContract(tokenAddress);
        return await tokenContract.name();
    }

    async getTokenCurrency(tokenAddress: string): Promise<string> {
        const tokenContract = this.getTokenContract(tokenAddress);
        return tokenContract.symbol();
    }

    async getTokenBalance(tokenAddress: string): Promise<string> {
        const tokenContract = this.getTokenContract(tokenAddress);
        const balance = await tokenContract.balanceOf(tokenAddress);
        return balance.toString();
    }

    isTokenAddressValid(address: string): Promise<boolean> {
        return this.isErc20Address(address);
    }
}
