import { useEffect, useState } from "react";
import { useTranslate } from "ui/locale";
import Amount from "common/utils/Amount";
import useBridgeState from "ui/adapter/state/useBridgeState";
import useFormatAmount from "ui/common/hooks/useFormatAmount";
import { BridgeSource } from "xchain-sdk";
import { useGetSourceToken } from "ui/bridge/query/useGetSourceToken";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import { isDestinationCannotPaySignatureRewardError, isInsufficientCreateAccountAmountError } from "../utils/transferError";

export function useTransferError(): [error: string | undefined, setError: (error: unknown) => void] {
    const translateError = useTranslate("error");
    const formatAmount = useFormatAmount();

    const [error, setError] = useState<string | undefined>(undefined);

    const { originXChainBridgeChain, destinationXChainBridgeChain } = useBridgeState() || {};
    const { data: originToken } = useGetSourceToken(BridgeSource.ORIGIN);
    const destinationChain = useBridgeSourceChainState(BridgeSource.DESTINATION);

    const bridge = useBridgeState();
    const wallets = useBridgeWalletsState();

    useEffect(() => {
        setError(undefined);
    }, [bridge, wallets]);

    const setTransferError = (error: unknown) => {
        if (isInsufficientCreateAccountAmountError(error)) {
            const minCreateAccountAmount =
                !!originXChainBridgeChain && !!originXChainBridgeChain?.minAccountCreate && !!originToken
                    ? Amount.fromIntToken(originXChainBridgeChain.minAccountCreate, originToken)
                    : undefined;

            setError(
                translateError("insufficientCreateAccountAmount", {
                    minCreateAccountAmount: minCreateAccountAmount ? formatAmount(minCreateAccountAmount) : "-",
                }),
            );
        } else if (isDestinationCannotPaySignatureRewardError(error)) {
            const signatureRewardAmount =
                !!destinationXChainBridgeChain && !!destinationChain
                    ? new Amount(
                          destinationXChainBridgeChain.signatureReward,
                          destinationChain.nativeDecimals,
                          destinationChain.nativeToken,
                      )
                    : undefined;
            setError(
                translateError("destinationCannotPaySignatureReward", {
                    signatureReward: signatureRewardAmount ? formatAmount(signatureRewardAmount) : "-",
                }),
            );
        }
    };

    return [error, setTransferError];
}
