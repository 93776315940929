import { BridgeSource } from "xchain-sdk";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Token } from "common/models/wallet/Token";
import { useGetSourceBridgeToken } from "./useGetSourceBridgeToken";

/**
 * Returns the token for the given BridgeSource. Uses the current bridge to get the XChainBridge.
 */
export function useGetSourceToken<TData = Token>(
    source: BridgeSource,
    options?: QueryOptions<Token, unknown, TData, any[]>,
): QueryResult<TData> {
    const bridge = useBridgeState();

    return useGetSourceBridgeToken<TData>(source, bridge?.xChainBridge, options);
}
