import { CustomTokenInfo } from "common/models";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeSource } from "xchain-sdk";

export default function useGetSourceCustomTokenInfo(
    tokenAddress: string,
    source: BridgeSource,
    { enabled = true, ...restOptions }: QueryOptions<CustomTokenInfo, unknown, CustomTokenInfo, any[]> = {},
): QueryResult<CustomTokenInfo> {
    return useQuery(
        [Queries.GET_CUSTOM_TOKEN_INFO, tokenAddress, source],
        () => ControllerFactory.bridgeTokenController.getSourceCustomTokenInfo(source, tokenAddress),
        {
            enabled: !!tokenAddress && enabled,
            ...restOptions,
        },
    );
}
