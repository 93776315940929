import { BasePageProps } from "../BasePage/BasePage.types";
import Header from "ui/common/components/navigation/Header/Header";
import { MainPageContent, MainPageRoot } from "ui/common/components/layout/MainPage/MainPage.styles";
import MainLayout from "../MainLayout/MainLayout";

const MainPage = ({ children, ...rest }: BasePageProps): JSX.Element => {
    return (
        <MainPageRoot {...rest}>
            <Header />
            <MainPageContent>
                <MainLayout>{children}</MainLayout>
            </MainPageContent>
        </MainPageRoot>
    );
};

export default MainPage;
