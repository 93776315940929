import { RouteObject } from "react-router-dom";
import SuccessBridgePage from "ui/bridge/pages/SuccessBridgePage/SuccessBridgePage";
import BridgePage from "./pages/BridgePage/BridgePage";

export enum BridgeRoutes {
    BRIDGE = "/bridge",
    BRIDGE_SUCCESS = "/bridge/success",
}

export const useBridgeRoutes = (): RouteObject[] => {
    return [
        {
            path: BridgeRoutes.BRIDGE,
            element: <BridgePage />,
        },
        {
            path: BridgeRoutes.BRIDGE_SUCCESS,
            element: <SuccessBridgePage />,
        },
    ];
};
