import { RouteObject } from "react-router-dom";
import ActivityPage from "../pages/ActivityPage";
import { ActivityRoutes } from "./ActivityRouter.types";

export const useActivityRoutes = (): RouteObject[] => {
    return [
        {
            path: ActivityRoutes.ACTIVITY,
            element: <ActivityPage />,
        },
    ];
};
