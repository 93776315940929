import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeSource } from "xchain-sdk";
import { Queries } from "ui/query/queries";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { useConnectedBridgeSourceWalletState } from "../hooks/useConnectedBridgeSourceWalletState";
import { config } from "common/config";

export function useIsDestinationActive<T = boolean>({
    enabled = true,
    refetchInterval = config.destinationIsActiveRefetchInterval,
    cacheTime = 0,
    staleTime = 0,
    ...restOptions
}: QueryOptions<boolean, unknown, T, any[]> = {}): QueryResult<T> {
    const destinationWallet = useConnectedBridgeSourceWalletState(BridgeSource.DESTINATION);

    return useQuery<boolean, unknown, T, any[]>(
        [Queries.IS_DESTINATION_ACTIVE, destinationWallet?.address],
        () => ControllerFactory.bridgeWalletsController.destinationWalletProvider!.isActive(),
        {
            enabled: !!destinationWallet && enabled,
            refetchInterval,
            cacheTime,
            staleTime,
            ...restOptions,
        },
    );
}
