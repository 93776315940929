import { Typography } from "@peersyst/react-components";
import useGetProjectVersion from "ui/common/query/useGetProjectVersion";
import { ProjectVersionIcon, ProjectVersionRoot } from "./ProjectVersion.styles";

export function ProjectVersion(): JSX.Element {
    const { data: projectVersion } = useGetProjectVersion();

    return (
        <ProjectVersionRoot>
            <ProjectVersionIcon />
            <Typography variant="body2" color="placeholder">
                {`Backend ${projectVersion?.backend}, Frontend ${projectVersion?.frontend}`}
            </Typography>
        </ProjectVersionRoot>
    );
}
