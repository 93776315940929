import { Row } from "@peersyst/react-components";
import ExplorerLink from "ui/common/components/feedback/ExplorerLink/ExplorerLink";
import { BlockchainAddressExplorerLinkProps } from "./BlockchainAddressExplorerLink.types";
import ChainBlockchainAddress from "../../display/ChainBlockchainAddress/ChainBlockchainAddress";
import { spacing } from "ui/config/spacing";
import { buildExplorerUrl } from "ui/blockchain/utils/buildExplorerUrl";
import { ChainType } from "xchain-sdk";

const BlockchainAddressExplorerLink = ({ chain, address, type, ...props }: BlockchainAddressExplorerLinkProps): JSX.Element => {
    return (
        <Row wrap wrapGap={spacing[2]} alignItems="center" justifyContent="space-between" css={{ width: "100%" }}>
            <ChainBlockchainAddress length={16} address={address} chain={chain} type={type} {...props} />
            <ExplorerLink url={buildExplorerUrl(chain.explorerUrl, chain.type as ChainType, address, type)} css={{ textAlign: "end" }} />
        </Row>
    );
};

export default BlockchainAddressExplorerLink;
