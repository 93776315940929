import { EventsFactory } from "common/utils/events";
import BridgeChainsEvent from "domain/bridge/events/BridgeChainsEvent";
import BridgeEvent from "domain/bridge/events/BridgeEvent";
import BridgeDoorsEvent from "domain/bridge/events/BridgeDoorsEvent";
import BridgeManagerEvent from "domain/bridge/events/BridgeManagerEvent";
import BridgeTokenEvent from "domain/bridge/events/BridgeTokenEvent";

const DomainEvents = EventsFactory({
    bridgeConfig: BridgeEvent,
    bridgeChains: BridgeChainsEvent,
    bridgeDoors: BridgeDoorsEvent,
    bridgeManager: BridgeManagerEvent,
    bridgeToken: BridgeTokenEvent,
});

export default DomainEvents;
