import { ConnectionError, ChainDto } from "common/models";
import { EventEmitter } from "common/utils/events";

export type WalletProviderEvents = {
    setChain: (chain: ChainDto | undefined) => void;
    // TODO: Delete isChainValid on double metamask refactor
    connect: (address: string, isChainValid: boolean) => void;
    disconnect: () => void;
    connectionError: (error: ConnectionError, message: string) => void;
    // TODO: Delete on double metamask refactor
    invalidChain: () => void;
    validChain: () => void;
};

const WalletProviderEventEmitter = EventEmitter<WalletProviderEvents>;

export default WalletProviderEventEmitter;
