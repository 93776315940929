import { ChainDto } from "common/models";
import ProviderFactory from "domain/adapter/ProviderFactory";
import { BridgeDoor, ChainType, EvmBridgeDoor, XrpBridgeDoor } from "xchain-sdk";

export default async function BridgeDoorFactory(doorAddress: string, chain: ChainDto): Promise<BridgeDoor> {
    const provider = await ProviderFactory(chain);

    switch (chain.type) {
        case ChainType.EVM:
            return new EvmBridgeDoor(provider, doorAddress, chain.name);
        case ChainType.XRP:
            return new XrpBridgeDoor(provider, doorAddress, chain.name);
        default:
            throw new Error(`Chain ${chain.type} not supported`);
    }
}
