import { EventEmitter } from "common/utils/events";
import { IBridgeChainsState } from "../states/bridgeChainsState";

type BridgeChainsEvents = {
    bridgeChainsChange: (chains: IBridgeChainsState, prevChains: IBridgeChainsState) => void;
    bridgeChainsLoad: (chains: IBridgeChainsState) => void;
    bridgeChainsSwap: (chains: IBridgeChainsState) => void;
};

const BridgeChainsEvent = new EventEmitter<BridgeChainsEvents>();

export default BridgeChainsEvent;
