import { XrplProvider } from "data_access/service/provider/xrp/xrpl/XrplProvider";
import { IXrplService } from "domain/adapter/service/IXrplService";
import { IXrplProvider } from "domain/adapter/service/provider/IXrplProvider";
import { Client } from "xrpl";

export class XrplService implements IXrplService {
    getProvider(nodeUrl: string): Promise<IXrplProvider> {
        return Promise.resolve(new XrplProvider(new Client(nodeUrl)));
    }
}
