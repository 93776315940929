import { Row } from "@peersyst/react-components";
import { SequenceProps } from "./Sequence.types";
import { ChevronRightIcon } from "ui/common/icons";
import { spacing } from "ui/config/spacing";
import useSequenceItems from "./hooks/useSequenceItems";

const Sequence = ({ children, Icon = ChevronRightIcon, ...props }: SequenceProps): JSX.Element => {
    const sequenceItems = useSequenceItems({ children, Icon });

    return (
        <Row gap={spacing[3]} alignItems="center" {...props}>
            {sequenceItems}
        </Row>
    );
};

export default Sequence;
