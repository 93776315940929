import { Col, Typography } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { ProcessImage, ProcessErrorIcon } from "./BridgeAttestations.styles";
import { useEffect, useState } from "react";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { useTheme } from "styled-components";

function BridgeAttestations(): JSX.Element {
    const translate = useTranslate();
    const translateError = useTranslate("error");
    const { spacing } = useTheme();

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        // @pre: transfer has started
        const removeOnAttestationsStarted = ControllerFactory.bridgeTransferController.on("attestationsFailed", () => {
            setIsError(true);
        });
        return () => {
            removeOnAttestationsStarted();
        };
    }, []);

    return (
        <Col gap={spacing[4]} alignItems={"center"} flex={1}>
            {isError && (
                <>
                    <ProcessErrorIcon />
                    <Typography variant="body1" color="grey.200">
                        {translateError("attestationsError")}
                    </Typography>
                </>
            )}
            {!isError && (
                <>
                    <ProcessImage />
                    <Typography variant="body1" color="grey.200">
                        {translate("transactionIsProcessing")}
                    </Typography>
                </>
            )}
        </Col>
    );
}

export default BridgeAttestations;
