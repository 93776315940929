import { EthersProvider } from "data_access/service/provider/evm/ethers/EthersProvider";
import { IEthersService } from "domain/adapter/service/IEthersService";
import { IEthersProvider } from "domain/adapter/service/provider/IEthersProvider";
import { providers } from "ethers";

export default class EthersService implements IEthersService {
    getProvider(rpcUrl: string): Promise<IEthersProvider> {
        return Promise.resolve(new EthersProvider(new providers.JsonRpcProvider(rpcUrl)));
    }
}
