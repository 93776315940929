import { Col, Loader, Skeleton, useTheme } from "@peersyst/react-components";
import { CustomTokenFormProps } from "./CustomTokenForm.types";
import { CustomTokenFormLabel, CustomTokenFormRoot, CustomTokenFormTypography } from "./CustomTokenForm.styles";
import Button from "ui/common/components/input/Button/Button";
import { useTranslate } from "ui/locale";
import { BridgeSource } from "xchain-sdk";
import BridgeBlockchainAddress from "../../../containers/BridgeBlockchainAddress/BridgeBlockchainAddress";
import useFormatAmount from "ui/common/hooks/useFormatAmount";
import Amount from "common/utils/Amount";

export default function CustomTokenForm({
    address,
    name,
    currency,
    decimals,
    balance = new Amount("0", 1, ""),
    isBalanceLoading,
    onSubmit,
    isSubmitting,
    buttonLabel,
}: CustomTokenFormProps): JSX.Element {
    const { spacing } = useTheme();
    const translate = useTranslate();
    const formatAmount = useFormatAmount();

    return (
        <CustomTokenFormRoot elevation={0}>
            <Col gap={spacing[5]}>
                <CustomTokenFormLabel label={translate("address")}>
                    <BridgeBlockchainAddress
                        address={address}
                        source={BridgeSource.ORIGIN}
                        type="account"
                        variant="heading"
                        action="copy"
                        ellipsis="middle"
                        css={{ maxWidth: "50%" }}
                    />
                </CustomTokenFormLabel>
                {!!name && (
                    <CustomTokenFormLabel label={translate("name")}>
                        <CustomTokenFormTypography>{name}</CustomTokenFormTypography>
                    </CustomTokenFormLabel>
                )}
                <CustomTokenFormLabel label={translate("symbol")}>
                    <CustomTokenFormTypography>{currency}</CustomTokenFormTypography>
                </CustomTokenFormLabel>
                <CustomTokenFormLabel label={translate("decimals")}>
                    <CustomTokenFormTypography>{decimals}</CustomTokenFormTypography>
                </CustomTokenFormLabel>
                <CustomTokenFormLabel label={translate("yourBalance")}>
                    {isBalanceLoading ? <Loader /> : <CustomTokenFormTypography>{formatAmount(balance)}</CustomTokenFormTypography>}
                </CustomTokenFormLabel>
                <Button loading={isSubmitting} onClick={onSubmit}>
                    {buttonLabel}
                </Button>
            </Col>
        </CustomTokenFormRoot>
    );
}

export function CustomTokenFormSkeleton(): JSX.Element {
    return <Skeleton width="100%" height="18rem" />;
}
