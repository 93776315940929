import { Row, Typography } from "@peersyst/react-components";
import { ChainProps } from "./Chain.types";
import { spacing } from "ui/config/spacing";
import ChainAvatar from "../ChainAvatar/ChainAvatar";
import { normalizeChainName } from "common/utils/network";

const Chain = ({ chain }: ChainProps): JSX.Element => {
    const { name, imageUrl = "" } = chain;

    return (
        <Row gap={spacing[3]} justifyContent="center" alignItems="center">
            <ChainAvatar name={name} imageUrl={imageUrl} />
            <Typography variant="body1">{normalizeChainName(name)}</Typography>
        </Row>
    );
};

export default Chain;
