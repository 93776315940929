import { useState } from "react";
import { Typography } from "@peersyst/react-components";
import { AngleDownIcon } from "ui/common/icons";
import BridgeOriginTokenSelectorModal from "../BridgeOriginTokenSelectorModal/BridgeOriginTokenSelectorModal";
import { BridgeOriginTokenSelectorButton } from "./BridgeOriginTokenSelector.styles";
import { useGetSourceToken } from "ui/bridge/query/useGetSourceToken";
import { BridgeSource } from "xchain-sdk";
import { formatCurrency } from "common/utils/token";

function BridgeOriginTokenSelector(): JSX.Element {
    const [openBridgeOriginTokenSelectorModal, setOpenBridgeOriginTokenSelectorModal] = useState(false);
    const { data: tokenCode } = useGetSourceToken(BridgeSource.ORIGIN, { select: (token) => formatCurrency(token.currency) });

    return (
        <>
            {tokenCode && (
                <BridgeOriginTokenSelectorButton variant="text" size="sm" onClick={() => setOpenBridgeOriginTokenSelectorModal(true)}>
                    <Typography variant="body2">{tokenCode}</Typography>
                    <AngleDownIcon />
                </BridgeOriginTokenSelectorButton>
            )}
            <BridgeOriginTokenSelectorModal
                open={openBridgeOriginTokenSelectorModal}
                onClose={() => setOpenBridgeOriginTokenSelectorModal(false)}
            />
        </>
    );
}

export default BridgeOriginTokenSelector;
