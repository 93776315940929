import { BridgeWalletSelectorProps } from "./BridgeWalletSelector.types";
import { config } from "common/config";
import { useTranslate } from "ui/locale";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { useMemo } from "react";
import useBridgeWalletsState from "ui/adapter/state/useBridgeWalletState";
import ImageSelectItem from "ui/common/components/input/ImageSelectItem/ImageSelectItem";
import { BridgeWalletSelectorRoot } from "./BridgeWalletSelector.styles";
import { WalletProvider, WalletProviderId } from "common/models";
import { Hash, Row, useModal } from "@peersyst/react-components";
import WalletConnectionModal from "ui/wallet/containers/connection/WalletConnectionModal/WalletConnectionModal";
import BridgeWalletSelectorOptions from "./BridgeWalletSelectorOptions/BridgeWalletSelectorOptions";

function BridgeWalletSelector({ side, placeholder: placeholderProp, disabled = false, ...rest }: BridgeWalletSelectorProps): JSX.Element {
    const translate = useTranslate();
    const { showModal } = useModal();

    const placeholder = placeholderProp ?? translate("connect");

    const { originChain, destinationChain } = useBridgeChainsState();
    const chain = side === "origin" ? originChain : destinationChain;

    const walletProviders = useMemo(() => {
        if (!chain) return [];
        else return Object.values(config.walletProviders).filter((wallet) => wallet.chainType === chain.type);
    }, [side, chain]);

    const { originWallet, destinationWallet } = useBridgeWalletsState();
    const connectedWallet = side === "origin" ? originWallet : destinationWallet;

    const renderValue = () =>
        connectedWallet.connection === "connected" && (
            <Row flex={1} justifyContent="space-between" alignItems="center">
                <ImageSelectItem
                    src={config.walletProviders[connectedWallet.providerId].imageUrl}
                    label={<Hash variant="body2" hash={connectedWallet.address} />}
                />
                <BridgeWalletSelectorOptions side={side} />
            </Row>
        );

    const handleChange = (wallet: WalletProvider) => {
        const providerId = wallet.providerId as WalletProviderId;
        showModal(WalletConnectionModal, {
            providerId,
            side,
        });
    };

    return (
        <BridgeWalletSelectorRoot
            renderValue={renderValue}
            onChange={handleChange}
            wallets={walletProviders}
            disabled={!chain || disabled}
            dropdownElement={<></>}
            placeholder={placeholder}
            {...rest}
        />
    );
}

export default BridgeWalletSelector;
