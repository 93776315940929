// Load polyfills
import "common/polyfills";
// Load locale
import "ui/locale/i18n";
// Load OpenApi config
import "data_access/api/OpenApiConfig";

import { createRoot } from "react-dom/client";
import Providers from "./ui/Providers";
import Router from "./ui/router/Router";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <Providers>
        <Router />
    </Providers>,
);
