import { Col } from "@peersyst/react-components";
import styled, { css } from "styled-components";
import InfiniteList from "ui/common/components/display/InfiniteList/InfiniteList";
import { TransferListRootProps } from "./TransferList.types";

export const TransferListRoot = styled(Col)<TransferListRootProps>(
    ({ isEmpty }) => css`
        min-height: min(18rem, 50vh);
        height: 30rem;
        overflow: auto;

        justify-content: ${isEmpty ? "center" : "flex-start"};
    `,
);

export const TransferInfiniteList = styled(InfiniteList)(
    () => css`
        padding: 2rem;

        .Transfer:not(:last-child) {
            margin-bottom: 1rem;
        }
    `,
) as typeof InfiniteList;
