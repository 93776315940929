import { HealthCheckResultDto } from "common/models";
import { IHealthApi } from "domain/adapter/api/IHealthApi";
import { IHealthController } from "ui/adapter/controllers/IHealthController";

export default class HealthController implements IHealthController {
    constructor(private readonly healthApi: IHealthApi) {}

    check(): Promise<HealthCheckResultDto> {
        return this.healthApi.check();
    }
}
