import styled from "styled-components";
import WalletSelect from "ui/wallet/components/input/WalletSelect/WalletSelect";

export const BridgeWalletSelectorRoot = styled(WalletSelect)`
    .DisplayContent {
        max-width: unset;

        &.Placeholder {
            text-decoration: underline;
        }
    }

    .SelectDropdown {
        display: none;
    }

    &&& .SelectDisplay {
        padding-right: 0.35rem;

        .DisplayContent {
            margin-right: 0;
            width: 100%;
        }
    }
`;
