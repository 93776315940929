import { EventEmitter } from "common/utils/events";
import { BridgeManager } from "xchain-sdk";

type BridgeManagerEvents = {
    bridgeManagerLoad: (bridgeManager: BridgeManager | undefined) => void;
};

const BridgeManagerEvent = new EventEmitter<BridgeManagerEvents>();

export default BridgeManagerEvent;
