import { Typography } from "@peersyst/react-components";
import { FooterLinkRoot } from "./FooterLink.styles";
import { FooterLinkProps } from "./FooterLink.types";

export function FooterLink({ href, label, Icon }: FooterLinkProps): JSX.Element {
    return (
        <FooterLinkRoot href={href} target="_blank" rel="noreferrer">
            {Icon && <Icon />}
            <Typography variant="body2">{label}</Typography>
        </FooterLinkRoot>
    );
}
