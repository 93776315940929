import { EventEmitter } from "common/utils/events";
import { BridgeDoor } from "xchain-sdk";

type BridgeDoorsEvents = {
    bridgeDoorsLoad: (bridgeDoors: [BridgeDoor, BridgeDoor] | undefined) => void;
};

const BridgeDoorsEvent = new EventEmitter<BridgeDoorsEvents>();

export default BridgeDoorsEvent;
