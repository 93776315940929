import { BridgeOriginVerifiedTokenSelectorProps } from "./BridgeOriginVerifiedTokenSelector.types";
import { TokenSelectorListItemData } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem.types";
import useGetVerifiedTokens from "ui/bridge/query/useGetVerifiedTokens";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import { BridgeSource } from "xchain-sdk";
import { clsx } from "clsx";
import { buildTokenSelectorListItemFromBridgeToken } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/utils/buildTokenSelectorListItemFromBridgeToken";
import { renderBridgeOriginVerifiedTokenSelectorListItem } from "./BridgeOriginVerifiedTokenSelectorListItem/BridgeOriginVerifiedTokenSelectorListItem";
import BridgeTokenSelector from "ui/bridge/components/display/BridgeTokenSelector/BridgeTokenSelector";

export default function BridgeOriginVerifiedTokenSelector({
    onSelect,
    className,
    style,
}: BridgeOriginVerifiedTokenSelectorProps): JSX.Element {
    const sourceChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);
    const { data: tokens = [], isLoading } = useGetVerifiedTokens<TokenSelectorListItemData[]>({
        select: (data) => {
            return data.map((token) => buildTokenSelectorListItemFromBridgeToken(token, sourceChain.name));
        },
    });

    return (
        <BridgeTokenSelector
            tokens={tokens}
            isLoading={isLoading}
            renderItem={renderBridgeOriginVerifiedTokenSelectorListItem}
            onSelect={onSelect}
            className={clsx("BridgeOriginVerifiedTokenSelector", className)}
            style={style}
        />
    );
}
