import Amount from "common/utils/Amount";
import { formatCurrency } from "common/utils/token";
import { useIsDestinationActive } from "./useIsDestinationActive";
import { useCallback } from "react";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";
import { useBridgeSourceChainState } from "../hooks/useBridgeSourceChainState";
import { BridgeSource } from "xchain-sdk";
import useBridgeState from "ui/adapter/state/useBridgeState";

export function useBridgeOriginSignatureRewardToPay(
    options: Omit<QueryOptions<boolean, unknown, Amount, any[]>, "select"> = {},
): QueryResult<Amount> {
    const { originXChainBridgeChain } = useBridgeState() || {};
    const { nativeDecimals: originDecimals, nativeToken: originNativeToken } = useBridgeSourceChainState(BridgeSource.DESTINATION, true);
    const { signatureReward: originSignatureReward = "0" } = originXChainBridgeChain || {};

    const select = useCallback(
        (isDestinationActive: boolean) =>
            new Amount(isDestinationActive ? "0" : originSignatureReward, originDecimals, formatCurrency(originNativeToken)),
        [originSignatureReward, originDecimals, originNativeToken],
    );

    return useIsDestinationActive({ select, ...options });
}
