import { xrp_loader } from "ui/assets/images";
import ClockCallout from "ui/common/components/feedback/ClockCallout/ClockCallout";
import { useTranslate } from "ui/locale";
import { XrplFaucetConnectionLoader, XrplFaucetConnectionRoot } from "./XrplFaucetConnection.styles";

function XrplFaucetConnection(): JSX.Element {
    const translate = useTranslate();

    return (
        <XrplFaucetConnectionRoot>
            <XrplFaucetConnectionLoader src={xrp_loader} />
            <ClockCallout message={translate("thisMightTakeAFewMinutes")} />
        </XrplFaucetConnectionRoot>
    );
}

export default XrplFaucetConnection;
