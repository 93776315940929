import BridgeManagerEvent from "../events/BridgeManagerEvent";
import { BridgeDoor, BridgeManager } from "xchain-sdk";
import DomainEvents from "domain/event";
import DomainError from "domain/error/DomainError";
import BridgeErrorCodes from "../BridgeErrorCodes";
import { IBridgeManagerController } from "ui/adapter/controllers/IBridgeManagerController";

export class BridgeManagerController implements IBridgeManagerController {
    /**
     * Reference to the domain event emitter.
     */
    private readonly domainEventEmitter = BridgeManagerEvent;

    private _bridgeManager: BridgeManager | undefined;
    private get bridgeManager(): BridgeManager {
        if (!this._bridgeManager) throw new DomainError(BridgeErrorCodes.BRIDGE_DOORS_NOT_SET);
        return this._bridgeManager;
    }
    private set bridgeManager(bridgeDoors: BridgeManager | undefined) {
        this._bridgeManager = bridgeDoors;
    }

    onInit(): void {
        DomainEvents.bridgeDoors.on("bridgeDoorsLoad", (bridgeDoors) => {
            this.loadBridgeManager(bridgeDoors);
        });
    }

    private async loadBridgeManager(bridgeDoors: [BridgeDoor, BridgeDoor] | undefined): Promise<void> {
        if (bridgeDoors) {
            const [mainchainBridgeDoor, sidechainBridgeDoor] = bridgeDoors;

            this.bridgeManager = await BridgeManager.createAsync(mainchainBridgeDoor, sidechainBridgeDoor);

            this.domainEventEmitter.emit("bridgeManagerLoad", this.bridgeManager);
        } else {
            this.bridgeManager = undefined;
        }
    }

    getBridgeManager(): BridgeManager {
        return this.bridgeManager;
    }
}
