import { WalletProviderId, ConnectionError, ChainDto } from "common/models";
import { IMultipleChainWalletProvider, IWalletProvider } from "ui/adapter/providers/IWalletProvider";
import { WalletProviderErrorCode, WalletProviderErrors } from "./errors/WalletProviderErrors";
import WalletProviderEventEmitter from "./events/WalletProviderEventEmitter";
import { WalletProviderEvents } from "./events/WalletProviderEventEmitter";
import {
    ChainType,
    CreateClaimTransaction,
    CommitTransaction,
    CreateAccountCommitTransaction,
    Unconfirmed,
    FormattedBridge,
    ClaimId,
} from "xchain-sdk";
import { IWalletProviderProvider } from "./interfaces/IWalletProviderProvider";
import { IWalletProviderSigner } from "./interfaces/IWalletProviderSigner";
import { IServiceError } from "domain/adapter/service/IServiceError";
import isServiceError from "domain/adapter/utils/isServiceError";
import DomainError from "domain/error/DomainError";
import { DomainErrorCode } from "domain/error/DomainErrorCodes";

export abstract class WalletProvider<
    Type extends ChainType = ChainType,
    Provider extends IWalletProviderProvider<Type> = IWalletProviderProvider<Type>,
    Signer extends IWalletProviderSigner<Type> = IWalletProviderSigner<Type>,
    Error extends DomainErrorCode = DomainErrorCode,
    RequestSignerResult = any,
> implements IWalletProvider
{
    /**
     * WalletProvider event emitter
     */
    protected eventEmitter = new WalletProviderEventEmitter();

    /**
     * Wallet provider ID
     */
    providerId: WalletProviderId;
    /**
     * Wallet chain type
     */
    type: Type;

    /**
     * Wallet address
     */
    private _address: string | undefined = undefined;
    get address(): string {
        if (!this._address) throw new Error(WalletProviderErrors.WALLET_NOT_CONNECTED);

        return this._address;
    }
    private set address(address: string | undefined) {
        this._address = address;
    }

    /**
     * Chain the wallet is connected to.
     * This can be changing when an external agent calls the setChain method.
     */
    private _chain: ChainDto | undefined = undefined;
    protected get chain(): ChainDto {
        if (!this._chain) throw new Error("Chain is not set");
        return this._chain;
    }
    setChain(chain: ChainDto | undefined): void {
        this._chain = chain;
        this.eventEmitter.emit("setChain", chain);
    }

    private _provider: Provider | undefined;
    protected get provider(): Provider {
        if (!this._provider) {
            throw new Error("Provider not connected");
        }
        return this._provider;
    }
    protected set provider(provider: Provider | undefined) {
        this._provider = provider;
    }

    private _signer: Signer | undefined;
    protected get signer(): Signer {
        if (!this._signer) {
            throw new Error("Signer not connected");
        }
        return this._signer;
    }
    protected set signer(signer: Signer | undefined) {
        this._signer = signer;
    }

    constructor(providerId: WalletProviderId, type: Type) {
        this.providerId = providerId;
        this.type = type;
    }

    /**
     * Error handlers that can be defined to handle generic errors.
     */
    protected errorHandlers: Partial<Record<IServiceError["code"], Error | (() => void)>> = {};

    /**
     * Handles an error.
     * @param e The error to handler.
     * @param handlers The error handlers.
     */
    protected handleError(e: any, handlers: Partial<Record<IServiceError["code"] | "default", Error | (() => void)>> = {}): any {
        handlers = { ...this.errorHandlers, ...handlers };

        if (isServiceError(e) && handlers?.[e.code]) {
            const handler = handlers[e.code];
            if (typeof handler === "function") {
                const val = handler();
                if (val !== undefined) throw val;
            } else throw new DomainError(handler!);
        } else if (handlers?.["default"]) {
            const handler = handlers["default"];
            if (typeof handler === "function") {
                const val = handler();
                if (val !== undefined) throw val;
            } else throw new DomainError(handler!);
        } else throw e;
    }

    /**
     * Gets the provider.
     */
    protected abstract getProvider(): Promise<Provider>;

    /**
     * Requests the signer.
     * This method can be implemented if the signer has to be requested.
     * For example, XUMM requires a sign in request.
     */
    protected requestSigner(): Promise<RequestSignerResult> {
        return Promise.resolve(null as RequestSignerResult);
    }

    /**
     * Gets the signer.
     */
    protected abstract getSigner(data: RequestSignerResult): Promise<Signer>;

    /**
     * Recovers a signer.
     * @param address The of the signer to recover.
     */
    protected abstract recoverSigner(address: string): Promise<Signer | undefined>;

    /**
     * Runs just before connecting the wallet.
     * Can be overridden to execute logic before connecting.
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected beforeConnect(): Promise<void> | void {}

    /**
     * Runs just after connecting the wallet.
     * Can be overridden to execute logic after connecting.
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected afterConnect(): Promise<void> | void {}

    /**
     * Custom connection error handler
     * @param e The error
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onConnectionError(e: any): [error: ConnectionError, code?: WalletProviderErrorCode] | undefined {
        return [ConnectionError.FAILED];
    }

    /**
     * Handles connection error and emits the `connectionError` event
     * @param e Any error
     */
    protected handleConnectionError(e: any): void {
        const errorRes = this.onConnectionError(e) || [ConnectionError.FAILED];
        const error = errorRes[0];
        let code = errorRes[1];

        if (!code) {
            code =
                error === ConnectionError.REJECTED
                    ? WalletProviderErrors.WALLET_CONNECTION_REJECTED
                    : WalletProviderErrors.WALLET_CONNECTION_FAILED;
        }

        const message = code.toCamelCase();

        this.eventEmitter.emit("connectionError", error, message);
    }

    /**
     * Completes the connection.
     * Can execute code before and after connecting and gets the signer if it's not set.
     */
    private async completeConnection(): Promise<void> {
        await Promise.resolve(this.beforeConnect());

        this.connect(await this.signer.getAddress());

        await Promise.resolve(this.afterConnect());
    }

    /**
     * Completes the request connection.
     * @param data The data returned by the request connection method.
     */
    private async completeRequestConnection(data: RequestSignerResult): Promise<void> {
        try {
            // Equals to Xumm's verify sign in or Metamask's get signer
            this.signer = await this.getSigner(data);

            await this.completeConnection();
        } catch (e) {
            this.handleConnectionError(e);
        }
    }

    /**
     * Completes the recover connection.
     */
    private async completeRecoverConnection(): Promise<void> {
        await this.completeConnection();
    }

    /**
     * Requests a connection to the wallet
     */
    async requestConnection(): Promise<RequestSignerResult> {
        try {
            this.provider = await this.getProvider();

            // Equals to Xumm's sign in request
            const res = await Promise.resolve(this.requestSigner());

            this.completeRequestConnection(res);

            return res;
        } catch (e) {
            this.disconnect();
            throw e;
        }
    }

    /**
     * Recovers the connection to the wallet
     */
    async recoverConnection(address: string): Promise<void> {
        try {
            this.provider = await this.getProvider();
            this.signer = await this.recoverSigner(address);

            if (this.signer) await this.completeRecoverConnection();
            else this.disconnect();
        } catch (_e) {
            this.disconnect();
        }
    }

    /**
     * Connect the wallet and emits the `connect` event
     * @param address Connected address
     * @param isChainValid If the chain is valid
     */
    connect(address: string, isChainValid = true): void {
        this.address = address;

        this.eventEmitter.emit("connect", address, isChainValid);
    }

    /**
     * Disconnect the wallet and emits the `disconnect` event
     */
    disconnect(): void {
        this.eventEmitter.emit("disconnect");

        this.provider = undefined;
        this.signer = undefined;
        this.address = undefined;
    }

    /**
     * Gets the wallet address
     */
    getAddress(): Promise<string> {
        return Promise.resolve(this.address);
    }

    /**
     * Checks if the wallet account is active
     */
    async isActive(): Promise<boolean> {
        return this.provider.isAccountActive(this.address);
    }

    /**
     * Gets the native balance of the wallet as a string integer.
     */
    async getBalance(): Promise<string> {
        return this.provider.getNativeBalance(this.address);
    }

    /**
     * Handles invalid chains and emits the `invalidChain` event
     * TODO: Delete on double metamask refactor
     */
    protected handleInvalidChain(): void {
        this.eventEmitter.emit("invalidChain");
    }

    /**
     * Handles valid chain and emits the `validChain` event
     * TODO: Delete on double metamask refactor
     */
    protected handleValidChain(): void {
        this.eventEmitter.emit("validChain");
    }

    /**
     * Adds a listener for the specified event
     */
    on<Event extends keyof WalletProviderEvents>(event: Event, listener: WalletProviderEvents[Event]): () => void {
        return this.eventEmitter.on(event, listener);
    }

    /**
     * Claims the transfer
     * @param originAddress Address of the origin account in `chainType` format.
     * @param bridge Bridge in `chainType` format.
     */
    async createClaim(originAddress: string, bridge: FormattedBridge<Type>): Promise<Unconfirmed<CreateClaimTransaction>> {
        try {
            return await this.signer.createClaim(originAddress, bridge);
        } catch (e) {
            return this.handleError(e);
        }
    }

    /**
     * Commits the transfer
     * @param claimId Claim ID of the transfer.
     * @param destinationAddress Address of the destination account in `chainType` format.
     * @param bridge Bridge in `chainType` format.
     * @param amount Amount committed as a string integer.
     */
    async commit(
        claimId: ClaimId,
        destinationAddress: string,
        bridge: FormattedBridge<Type>,
        amount: string,
    ): Promise<Unconfirmed<CommitTransaction>> {
        try {
            return await this.signer.commit(claimId, destinationAddress, bridge, amount);
        } catch (e) {
            return this.handleError(e);
        }
    }

    /**
     * Activates an account and transfers the specified amount to it
     * @param destinationAddress Address of the destination account in `chainType` format.
     * @param bridge Bridge in `chainType` format.
     * @param amount Amount to transfer as a string integer.
     **/
    async createAccountCommit(
        destinationAddress: string,
        bridge: FormattedBridge<Type>,
        amount: string,
    ): Promise<Unconfirmed<CreateAccountCommitTransaction>> {
        try {
            return await this.signer.createAccountCommit(destinationAddress, bridge, amount);
        } catch (e) {
            return this.handleError(e);
        }
    }

    /**
     * Checks if a claim is attested.
     * @pre This is the destination wallet.
     * @param claimId A previously created and valid claim ID of the claim to check.
     * @param bridge Bridge in `chainType` format.
     */
    isClaimAttested(claimId: ClaimId, bridge: FormattedBridge<Type>): Promise<boolean> {
        return this.provider.isClaimAttested(this.address, claimId, bridge);
    }

    /**
     * Checks if a create account is attested.
     * @pre This is the destination wallet.
     * @param bridge Bridge in `chainType` format.
     */
    isCreateAccountCommitAttested(): Promise<boolean> {
        return this.provider.isCreateAccountCommitAttested(this.address);
    }

    isMultipleChain(): this is IMultipleChainWalletProvider {
        return "addChain" in this && "switchToChain" in this;
    }
}
