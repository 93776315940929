import { ActionStepProps, ActionStepStatus } from "ui/common/components/feedback/ActionStep/ActionStep.types";
import { useEffect, useState } from "react";
import { Row } from "@peersyst/react-components";
import ActionStepIcon from "ui/common/components/feedback/ActionStep/ActionStepIcon/ActionStepIcon";

import { cx } from "@peersyst/react-utils";
import {
    ActionStepRoot,
    ActionStepSubtitle,
    ActionStepSubtitleWrapper,
    ActionStepTitle,
} from "ui/common/components/feedback/ActionStep/ActionSteps.styles";

const ActionStep = ({
    title,
    subtitle,
    Icon,
    waiting,
    loading,
    success,
    error,
    className,
    children,
    ...rest
}: ActionStepProps): JSX.Element => {
    const [status, setStatus] = useState<ActionStepStatus>("idle");

    useEffect(() => {
        if (success) setStatus("success");
        else if (error) setStatus("error");
        else if (loading) setStatus("loading");
        else if (waiting) setStatus("waiting");
        else setStatus("idle");
    }, [waiting, loading, success, error]);

    return (
        <ActionStepRoot className={cx("action-step", status, className)} {...rest}>
            <ActionStepTitle>{title}</ActionStepTitle>
            <ActionStepSubtitleWrapper status={status} className={cx("action-step-subtitle", status)}>
                <ActionStepIcon status={status} css={{ fontSize: "1.5rem" }} Icon={Icon} />
                <ActionStepSubtitle>
                    {status === "error" ? error || subtitle["error"] : subtitle[status] || subtitle.default}
                </ActionStepSubtitle>
            </ActionStepSubtitleWrapper>
            {children && (
                <Row flex={1} css={{ marginLeft: "2rem" }}>
                    {children}
                </Row>
            )}
        </ActionStepRoot>
    );
};

export default ActionStep;
