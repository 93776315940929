import { useEffect, useState } from "react";
import RepositoryFactory from "domain/adapter/RepositoryFactory";
import ControllerFactory from "ui/adapter/ControllerFactory";
import ServiceFactory from "domain/adapter/ServiceFactory";
import useGetChains from "ui/bridge/query/useGetChains";

export function useLoad(): boolean {
    const [loading, setLoading] = useState(true);

    // Prefetch chains
    useGetChains();

    useEffect(() => {
        async function startUseLoad() {
            await RepositoryFactory.init();
            await ServiceFactory.init();
            await ControllerFactory.init();
            setLoading(false);
        }
        startUseLoad();
    }, []);

    return loading;
}
