import { EventEmitter } from "common/utils/events";
import { Bridge } from "xchain-sdk";

type BridgeEvents = {
    bridgeChange: (bridge: Bridge | undefined) => void;
};

const BridgeEvent = new EventEmitter<BridgeEvents>();

export default BridgeEvent;
