import { PersistedBridgeChains } from "common/models/bridge/PersistedBridgeChains";
import LocalStorageRepository from "../common/LocalStorageRepository";
import { IBridgeChainsRepository } from "domain/adapter/repository/IBridgeChainsRepository";

export class BridgeChainsRepository extends LocalStorageRepository<PersistedBridgeChains> implements IBridgeChainsRepository {
    constructor() {
        super("bridge-chains");
    }

    getBridgeChains(): Promise<PersistedBridgeChains | undefined> {
        return this.get();
    }

    async setBridgeChains(chains: PersistedBridgeChains): Promise<void> {
        await this.set(chains);
    }

    async setOriginChain(chain: string | undefined): Promise<void> {
        const chains = await this.getBridgeChains();

        await this.setBridgeChains({ ...chains, originChain: chain });
    }

    async setDestinationChain(chain: string | undefined): Promise<void> {
        const chains = await this.getBridgeChains();

        await this.setBridgeChains({ ...chains, destinationChain: chain });
    }
}
