import { useTranslate } from "ui/locale";
import { TransactionStepProps } from "./TransactionStep.types";
import ActionStep from "ui/common/components/feedback/ActionStep/ActionStep";
import { SignatureIcon } from "ui/common/icons";
import ChainAddress from "../../display/ChainAddress/ChainAddress";
import BlockchainAddress from "ui/blockchain/components/display/BlockchainAddress/BlockchainAddress";
import { Row } from "@peersyst/react-components";
import { useTheme } from "styled-components";
import { ChainType } from "xchain-sdk";

function TransactionStep({ address, chain, transaction, subtitle, ...actionStepProps }: TransactionStepProps): JSX.Element {
    const translate = useTranslate();
    const { spacing } = useTheme();

    return (
        <ActionStep
            subtitle={{
                ...subtitle,
                success: (
                    <Row flex={1} gap={spacing[2]}>
                        <span css={{ fontWeight: 700 }}>
                            {translate(transaction ? "signed" : "alreadySigned", { context: "feminine" })}
                        </span>
                        {transaction && (
                            <BlockchainAddress
                                url={chain.explorerUrl}
                                chainType={chain.type as ChainType}
                                address={transaction.hash}
                                action="link"
                                type="transaction"
                                variant="body1"
                            />
                        )}
                    </Row>
                ),
            }}
            Icon={SignatureIcon}
            {...actionStepProps}
        >
            <ChainAddress address={address} chain={chain} />
        </ActionStep>
    );
}

export default TransactionStep;
