import { Label, Typography } from "@peersyst/react-components";
import styled, { css } from "styled-components";

export const CreateBridgeFormLabel = styled(Label).attrs({
    alignment: "space-between",
    placement: "left",
    variant: "caption1",
})(
    ({ theme }) => css`
        .Label {
            color: ${theme.palette.text};
        }
    `,
);

export const CreateBridgeFormTypography = styled(Typography).attrs({
    variant: "body1",
    light: true,
})(() => css``);
