import { KeyValueRepository } from "./KeyValueRepository";

export default class KeyValueLocalStorageRepository<Def extends Record<string, any>> extends KeyValueRepository<Def> {
    protected set<K extends keyof Def>(key: K, value: Def[K]): Promise<void> {
        return Promise.resolve(localStorage.setItem(this.buildKey(key), JSON.stringify(value)));
    }

    protected async get<K extends keyof Def>(key: K): Promise<Def[K] | undefined> {
        const item = localStorage.getItem(this.buildKey(key));

        return Promise.resolve(item ? JSON.parse(item) : undefined);
    }

    protected async clear(key: keyof Def): Promise<void> {
        return Promise.resolve(localStorage.removeItem(this.buildKey(key)));
    }
}
