import { XrplFaucetProvider } from "data_access/service/provider/xrp/xrpl/xrplFaucet/XrplFaucetProvider";
import { XrplSigner } from "data_access/service/signer/xrp/xrpl/XrplSigner";
import { IXrplFaucetSeedsRepository } from "domain/adapter/repository/IXrplFaucetSeedsRepository";
import { IXrplFaucetService } from "domain/adapter/service/IXrplFaucetService";
import { IXrplFaucetProvider } from "domain/adapter/service/provider/IXrplFaucetProvider";
import { IXrplSigner } from "domain/adapter/service/signer/IXrplSigner";
import { Client, Wallet } from "xrpl";

export class XrplFaucetService implements IXrplFaucetService {
    constructor(protected readonly xrplFaucetSeedsRepository: IXrplFaucetSeedsRepository) {}

    getProvider(nodeUrl: string, faucetUrl: string): Promise<IXrplFaucetProvider> {
        return Promise.resolve(new XrplFaucetProvider(new Client(nodeUrl), faucetUrl));
    }

    async getSigner(provider: IXrplFaucetProvider): Promise<IXrplSigner> {
        const wallet = await provider.generateFundedWallet();
        await this.xrplFaucetSeedsRepository.setSeed(wallet.address, wallet.seed!);
        return new XrplSigner(wallet, provider);
    }

    async recoverSigner(provider: IXrplFaucetProvider, address: string): Promise<IXrplSigner | undefined> {
        try {
            const seed = await this.xrplFaucetSeedsRepository.getSeed(address);
            return new XrplSigner(Wallet.fromSeed(seed), provider);
        } catch (_e) {
            return undefined;
        }
    }

    removeSigner(address: string): Promise<void> {
        return this.xrplFaucetSeedsRepository.removeSeed(address);
    }
}
