import { Row } from "@peersyst/react-components";
import styled from "styled-components";
import { CpuIcon } from "ui/common/icons";

export const ProjectVersionIcon = styled(CpuIcon)(({ theme }) => ({
    color: theme.palette.placeholder,
}));

export const ProjectVersionRoot = styled(Row)(({ theme }) => ({
    alignItems: "center",
    gap: theme.spacing[2],
}));
