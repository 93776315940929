import { LabelProps } from "@peersyst/react-components";
import { GroupLabelRoot } from "ui/common/components/layout/GroupLabel/GroupLabel.styles";

export type GroupLabelProps = Omit<LabelProps, "variant">;

const GroupLabel = ({ label, placement = "top", alignment, gap = "0.25rem", ...rest }: GroupLabelProps): JSX.Element => {
    const isVertical = placement === "top" || placement === "bottom";
    return (
        <GroupLabelRoot
            label={label}
            variant={isVertical ? "caption2" : "caption1"}
            gap={gap}
            placement={placement}
            alignment={alignment || (isVertical ? "start" : "space-between")}
            {...rest}
        />
    );
};

export default GroupLabel;
