import { Col, Divider, Row, Typography, useTheme } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { CreateBridgeFormLabel, CreateBridgeFormTypography } from "./CreateBridgeForm.styles";
import { CreateBridgeFormProps } from "./CreateBridgeForm.types";
import Button from "ui/common/components/input/Button/Button";
import { ArrowRightIcon } from "ui/common/icons";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";

export default function CreateBridgeForm({ onCancel, onContinue, token }: CreateBridgeFormProps): JSX.Element {
    const { spacing } = useTheme();
    const translate = useTranslate();
    const { originChain } = useBridgeChainsState();

    return (
        <Col gap={spacing[8]} flex={1}>
            <Typography variant="body1" light>
                {translate("createBridgeExplanation")}
            </Typography>

            <Divider />

            <Col gap={spacing[1]}>
                <CreateBridgeFormLabel label={translate("chain")}>
                    <CreateBridgeFormTypography>{originChain?.name}</CreateBridgeFormTypography>
                </CreateBridgeFormLabel>
                <CreateBridgeFormLabel label={translate("currency")}>
                    <CreateBridgeFormTypography>{token.currency}</CreateBridgeFormTypography>
                </CreateBridgeFormLabel>
            </Col>

            <Row gap={spacing[4]}>
                <Button variant="secondary" fullWidth onClick={onCancel}>
                    <Typography variant="heading">{translate("cancel")}</Typography>
                </Button>
                <Button fullWidth onClick={onContinue}>
                    <Row alignItems="center" gap={spacing[2]}>
                        <Typography variant="heading">{translate("okContinue")}</Typography>
                        <ArrowRightIcon fontSize="1.5rem" />
                    </Row>
                </Button>
            </Row>
        </Col>
    );
}
