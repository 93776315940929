import { ChainType, FormattedBridge, TrustCommitTransaction, Unconfirmed, CreateBridgeRequestTransaction } from "xchain-sdk";
import { WalletProvider } from "../WalletProvider";
import { DomainErrorCode } from "domain/error/DomainErrorCodes";
import { ICreateBridgeRequestWalletProvider, ITrustCommitWalletProvider } from "ui/adapter/providers/IWalletProvider";
import { IEvmWalletProviderProvider } from "./interfaces/IEvmWalletProviderProvider";
import { IEvmWalletProviderSigner } from "./interfaces/IEvmWalletProviderSigner";
import { EvmWalletProviderErrors } from "./errors";
import DomainError from "domain/error/DomainError";

export abstract class EvmWalletProvider<
        Provider extends IEvmWalletProviderProvider = IEvmWalletProviderProvider,
        Signer extends IEvmWalletProviderSigner = IEvmWalletProviderSigner,
        Error extends DomainErrorCode = DomainErrorCode,
        RequestSignerResult = any,
    >
    extends WalletProvider<ChainType.EVM, Provider, Signer, Error, RequestSignerResult>
    implements ITrustCommitWalletProvider, ICreateBridgeRequestWalletProvider
{
    isTrustCommitRequired(bridge: FormattedBridge<ChainType.EVM>): boolean {
        return !bridge.isNativeOriginIssue;
    }

    async trustCommit(bridge: FormattedBridge<ChainType.EVM>): Promise<Unconfirmed<TrustCommitTransaction>> {
        if (bridge.isNativeOriginIssue) throw new DomainError(EvmWalletProviderErrors.CANNOT_TRUST_COMMIT_WITH_NATIVE_TOKEN);

        try {
            return await this.signer.approveBridgeTokenContract(bridge);
        } catch (e) {
            return this.handleError(e);
        }
    }

    async isCommitTrusted(bridge: FormattedBridge<ChainType.EVM>): Promise<boolean> {
        if (bridge.isNativeOriginIssue) throw new DomainError(EvmWalletProviderErrors.CANNOT_CHECK_COMMIT_TRUST_WITH_NATIVE_TOKEN);

        return this.provider.isBridgeTokenContractApproved(this.address, bridge);
    }

    async createBridgeRequest(
        doorAddress: string,
        tokenAddress: string,
        issuingDoorAddress: string,
    ): Promise<Unconfirmed<CreateBridgeRequestTransaction>> {
        return await this.signer.createBridgeRequest(doorAddress, tokenAddress, issuingDoorAddress);
    }
}
