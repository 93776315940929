import { Row } from "@peersyst/react-components";
import { TransferTokenProps } from "./TransferToken.types";
import { LockingChainAvatar, TransferTokenAvatar } from "./TransferToken.styles";
import { token_default_logo } from "ui/assets/images";
import clsx from "clsx";

export function TransferToken({ tokenImageUrl = "", lockingChainImageUrl = "", className, ...restProps }: TransferTokenProps): JSX.Element {
    return (
        <Row className={clsx("TransferToken", className)} css={{ position: "relative", display: "inline-block" }} {...restProps}>
            <TransferTokenAvatar className="TransferTokenAvatar" src={tokenImageUrl} fallback={token_default_logo} alt="token-logo" />
            <LockingChainAvatar className="LockingChainAvatar" src={lockingChainImageUrl} alt="chain-logo" />
        </Row>
    );
}
