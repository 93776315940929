import styled, { css, DefaultTheme, FlattenInterpolation, ThemeProps } from "styled-components";
import { Button, ButtonSize, ButtonVariant } from "@peersyst/react-components";
import { ButtonProps } from "ui/common/components/input/Button/Button.types";
import { lighten } from "@peersyst/react-utils";

const smSize = css(
    () => css`
        height: 1.5rem;
        padding: 0 0.25rem;
    `,
);

const mdSize = css(
    () => css`
        height: 2rem;
        padding: 0 0.375rem;
    `,
);

const lgSize = css(
    () => css`
        height: 2.5rem;
        padding: 0 0.5rem;
    `,
);

const buttonSizes: Record<ButtonSize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
    sm: smSize,
    md: mdSize,
    lg: lgSize,
};

const primaryStyles = css(({ theme }) => ({
    backgroundColor: theme.palette.primary,
    color: theme.palette.grey[100],
    ["&:hover"]: {
        backgroundColor: theme.palette.purple[70],
    },
    ["&:active"]: {
        backgroundColor: theme.palette.purple[90],
    },
    ["&:disabled"]: {
        backgroundColor: theme.palette.purple[50],
        opacity: 0.4,
    },
}));

const secondaryStyles = css(({ theme }) => ({
    color: theme.palette.accent,
    border: "2px solid currentColor",
    backgroundColor: "transparent",
    ["&:hover"]: {
        backgroundColor: theme.palette.purple[80],
    },
    ["&:active"]: {
        backgroundColor: theme.palette.purple[90],
    },
    ["&:disabled"]: {
        opacity: 0.4,
    },
}));

const tertiaryStyles = css(({ theme }) => ({
    color: theme.palette.accent,
    backgroundColor: "transparent",
    ["&:hover"]: {
        backgroundColor: theme.palette.purple[80],
    },
    ["&:active"]: {
        backgroundColor: theme.palette.purple[90],
    },
    ["&:disabled"]: {
        opacity: 0.4,
    },
}));

const textStyles = css(({ theme }) => ({
    color: theme.palette.accent,
    backgroundColor: "transparent",
    ["&:hover"]: {
        color: lighten(theme.palette.purple[30], 0.2),
    },
    ["&:active"]: {
        color: lighten(theme.palette.purple[30], 0.4),
    },
    ["&:disabled"]: {
        opacity: 0.4,
    },
}));

const variantStyles: Record<ButtonVariant, ReturnType<typeof css>> = {
    primary: primaryStyles,
    secondary: secondaryStyles,
    tertiary: tertiaryStyles,
    text: textStyles,
};

export const ButtonRoot = styled(Button)<ButtonProps>(({ size = "lg", variant = "primary", theme }) => {
    return css`
        font-size: 0.875rem;
        ${buttonSizes[size]};
        border-radius: ${theme.borderRadiusSm};
        border: 0;
        font-weight: 600;
        text-transform: none;

        &.Loading {
            opacity: 1;
        }
        ${variantStyles[variant!]};
    `;
});
