import BridgeTokenSelector from "ui/bridge/components/display/BridgeTokenSelector/BridgeTokenSelector";
import { BridgeOriginCustomTokenSelectorProps } from "./BridgeOriginCustomTokenSelector.types";
import useGetCustomTokens from "ui/bridge/query/useGetCustomTokens";
import { TokenSelectorListItemData } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/TokenSelectorListItem.types";
import { BridgeSource } from "xchain-sdk";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";
import clsx from "clsx";
import useFindXChainBridgeByQuery from "ui/bridge/query/useFindXChainBridgeByQuery";
import { useState } from "react";
import useIsTokenAddressValid from "ui/bridge/query/useIsTokenAddressValid";
import BridgeOriginCustomTokenSelectorNothingToShow from "./BridgeOriginCustomTokenSelectorNothingToShow/BridgeOriginCustomTokenSelectorNothingToShow";
import { renderBridgeOriginCustomTokenSelectorListItem } from "./BridgeOriginCustomTokenSelectorListItem/BridgeOriginCustomTokenSelectorListItem";
import { buildTokenSelectorListItemFromBridgeToken } from "ui/bridge/components/display/TokenSelectorList/TokenSelectorListItem/utils/buildTokenSelectorListItemFromBridgeToken";

export default function BridgeOriginCustomTokenSelector({ onSelect, className, style }: BridgeOriginCustomTokenSelectorProps): JSX.Element {
    const sourceChain = useBridgeSourceChainState(BridgeSource.ORIGIN, true);
    const { data: tokens = [], isLoading } = useGetCustomTokens<TokenSelectorListItemData[]>({
        select: (data) => {
            return data.map((token) => buildTokenSelectorListItemFromBridgeToken(token, sourceChain.name));
        },
    });

    const [query, setQuery] = useState("");
    const [emptyQuery, setEmptyQuery] = useState<string | undefined>();

    const handleTokensFiltered = (filteredTokens: TokenSelectorListItemData[], filteredTokensQuery: string): void => {
        if (filteredTokens.length === 0 && filteredTokensQuery !== "") setEmptyQuery(filteredTokensQuery);
        else setEmptyQuery(undefined);
    };

    const { data: isTokenAddressValid, isLoading: isTokenAddressValidLoading } = useIsTokenAddressValid(emptyQuery);
    const { data: existingXChainBridge, isLoading: isExistingXChainBridgeLoading } = useFindXChainBridgeByQuery(emptyQuery, {
        enabled: isTokenAddressValid && !!emptyQuery,
    });

    const isFiltering = isExistingXChainBridgeLoading || isTokenAddressValidLoading;

    return (
        <BridgeTokenSelector
            tokens={tokens}
            onSelect={onSelect}
            isLoading={isLoading}
            isFiltering={isFiltering}
            onTokensFiltered={handleTokensFiltered}
            query={query}
            defaultQuery={query}
            onQueryChange={setQuery}
            renderItem={renderBridgeOriginCustomTokenSelectorListItem}
            nothingToShow={
                <BridgeOriginCustomTokenSelectorNothingToShow
                    isEmptyTokensQuery={!!emptyQuery}
                    existingXChainBridge={existingXChainBridge}
                    isTokenAddressValid={isTokenAddressValid}
                    isFiltering={isFiltering}
                    tokenAddress={emptyQuery}
                />
            }
            className={clsx("BridgeOriginCustomTokenSelector", className)}
            style={style}
        />
    );
}
