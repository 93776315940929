import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import clsx from "clsx";

export default function MoreIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="MoreIcon" className={clsx(undefined, "Icon", className)} fill="none">
            <path
                d="M12 24C11.175 24 10.469 23.7065 9.882 23.1195C9.294 22.5315 9 21.825 9 21C9 20.175 9.294 19.4685 9.882 18.8805C10.469 18.2935 11.175 18 12 18C12.825 18 13.5315 18.2935 14.1195 18.8805C14.7065 19.4685 15 20.175 15 21C15 21.825 14.7065 22.5315 14.1195 23.1195C13.5315 23.7065 12.825 24 12 24ZM12 15C11.175 15 10.469 14.706 9.882 14.118C9.294 13.531 9 12.825 9 12C9 11.175 9.294 10.4685 9.882 9.8805C10.469 9.2935 11.175 9 12 9C12.825 9 13.5315 9.2935 14.1195 9.8805C14.7065 10.4685 15 11.175 15 12C15 12.825 14.7065 13.531 14.1195 14.118C13.5315 14.706 12.825 15 12 15ZM12 6C11.175 6 10.469 5.706 9.882 5.118C9.294 4.531 9 3.825 9 3C9 2.175 9.294 1.469 9.882 0.882C10.469 0.294 11.175 0 12 0C12.825 0 13.5315 0.294 14.1195 0.882C14.7065 1.469 15 2.175 15 3C15 3.825 14.7065 4.531 14.1195 5.118C13.5315 5.706 12.825 6 12 6Z"
                fill="black"
            />
        </SvgIcon>
    );
}
