import DomainError from "domain/error/DomainError";
import { IBridgeDoorsController } from "ui/adapter/controllers/IBridgeDoorsController";
import { BridgeDoor } from "xchain-sdk";
import BridgeErrorCodes from "../BridgeErrorCodes";
import { IBridgeApi } from "domain/adapter/api/IBridgeApi";
import DomainEvents from "domain/event";
import { ChainDto } from "common/models";
import BridgeDoorFactory from "../bridgeDoors/BridgeDoorFactory";
import BridgeDoorsEvent from "../events/BridgeDoorsEvent";

export class BridgeDoorsController implements IBridgeDoorsController {
    /**
     * Reference to the domain event emitter.
     */
    private readonly domainEventEmitter = BridgeDoorsEvent;

    private _bridgeDoors: [BridgeDoor, BridgeDoor] | undefined;
    private get bridgeDoors(): [BridgeDoor, BridgeDoor] {
        if (!this._bridgeDoors) throw new DomainError(BridgeErrorCodes.BRIDGE_DOORS_NOT_SET);
        return this._bridgeDoors;
    }
    private set bridgeDoors(bridgeDoors: [BridgeDoor, BridgeDoor] | undefined) {
        this._bridgeDoors = bridgeDoors;
    }

    constructor(private readonly bridgeApi: IBridgeApi) {}

    onInit(): void {
        DomainEvents.bridgeChains.on("bridgeChainsLoad", ({ originChain, destinationChain }) => {
            this.loadBridgeDoors(originChain, destinationChain);
        });

        DomainEvents.bridgeChains.on("bridgeChainsChange", ({ originChain, destinationChain }) => {
            this.loadBridgeDoors(originChain, destinationChain);
        });
    }

    private async loadBridgeDoors(originChain: ChainDto | undefined, destinationChain: ChainDto | undefined): Promise<void> {
        if (originChain && destinationChain) {
            const { mainchainDoorAddress, mainchain, sidechainDoorAddress, sidechain } = await this.bridgeApi.findBridgeDoorPair([
                originChain.name,
                destinationChain.name,
            ]);

            this.bridgeDoors = await Promise.all([
                BridgeDoorFactory(mainchainDoorAddress, mainchain),
                BridgeDoorFactory(sidechainDoorAddress, sidechain),
            ]);

            this.domainEventEmitter.emit("bridgeDoorsLoad", this.bridgeDoors);
        } else {
            this.bridgeDoors = undefined;

            this.domainEventEmitter.emit("bridgeDoorsLoad", undefined);
        }
    }

    getBridgeDoors(): [BridgeDoor, BridgeDoor] {
        return this.bridgeDoors;
    }
}
