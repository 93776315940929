import { CommonModalComponentProps } from "@peersyst/react-components";
import { CustomTokenInfo } from "common/models";

export type CreateBridgeModalProps = {
    token: CustomTokenInfo;
} & CommonModalComponentProps;

export enum CreateBridgeModalTabs {
    CREATE_BRIDGE,
    SIGN_TRANSACTION,
}
