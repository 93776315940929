import { ServiceErrorCode } from "./ServiceErrorCodes";

export default class ServiceError extends Error {
    code: ServiceErrorCode;
    params?: Record<string, any>;

    constructor(code: ServiceErrorCode, params?: Record<string, any>) {
        super(code);

        this.name = "ServiceError";
        this.code = code;
        this.params = params;
    }
}
