import { EventEmitter } from "common/utils/events";
import { BridgeToken } from "common/models";

type BridgeTokenEvents = {
    bridgeVerifiedTokensLoad: (tokens: BridgeToken[]) => void;
};

const BridgeTokenEvent = new EventEmitter<BridgeTokenEvents>();

export default BridgeTokenEvent;
