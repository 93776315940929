import { Select, SelectOption } from "@peersyst/react-components";
import { ChainSelectProps } from "./ChainSelect.types";
import { ChainDto } from "common/models";
import { useMemo } from "react";
import ImageSelectItem from "ui/common/components/input/ImageSelectItem/ImageSelectItem";

function ChainSelect({ chains, ...restProps }: ChainSelectProps): JSX.Element {
    const options: SelectOption<ChainDto>[] = useMemo(
        () =>
            chains.map((chain) => ({
                label: <ImageSelectItem src={chain.imageUrl} label={chain.name} />,
                value: chain,
            })),
        [chains],
    );

    return <Select options={options} compare={(a, b) => a.name === b.name} {...restProps} />;
}

export default ChainSelect;
