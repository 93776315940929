import styled, { css } from "styled-components";
import { Image } from "@peersyst/react-components";
import { Avatar } from "ui/bridge/components/display/ChainAvatar/ChainAvatar.styles";

export const TransferTokenAvatar = styled(Avatar)(
    () => css`
        border-radius: 999px;
        width: 1.75rem;
        height: 1.75rem;
        aspect-ratio: 1;
    `,
);

export const LockingChainAvatar = styled(Image)(
    ({ theme }) => css`
        border-radius: 999px;
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        aspect-ratio: 1;
        bottom: 60%;
        top: 40%;
        right: -20%;
        border: 2px solid ${theme.palette.grey[700]};
    `,
);
