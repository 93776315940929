import useGetChains from "ui/bridge/query/useGetChains";
import { BridgeChainSelectorProps } from "./BridgeChainSelector.types";
import ChainSelect from "ui/bridge/components/input/ChainSelect/ChainSelect";
import { useTranslate } from "ui/locale";
import useBridgeChainsState from "ui/adapter/state/useBridgeChainsState";
import { useMemo } from "react";
import { ChainDto } from "common/models";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { BridgeSource } from "xchain-sdk";

function BridgeChainSelector({
    disabled = false,
    placeholder: placeholderProp,
    source: side,
    ...rest
}: BridgeChainSelectorProps): JSX.Element {
    const translate = useTranslate();

    const placeholder = placeholderProp ?? translate("select");

    const { originChain, destinationChain } = useBridgeChainsState();

    const [sideChain, otherSideChain] = side === "origin" ? [originChain, destinationChain] : [destinationChain, originChain];

    const { data: allChains = [], isLoading } = useGetChains();
    const chains = useMemo(() => {
        if (!otherSideChain) return allChains;
        else return allChains.filter((chain) => chain.name !== otherSideChain.name);
    }, [allChains, otherSideChain]);

    const handleChange = (chain: ChainDto) => {
        if (side === BridgeSource.ORIGIN) ControllerFactory.bridgeChainsController.setOriginChain(chain);
        else ControllerFactory.bridgeChainsController.setDestinationChain(chain);
    };

    return (
        <ChainSelect
            chains={chains}
            value={sideChain}
            onChange={handleChange}
            disabled={disabled || isLoading}
            placeholder={placeholder}
            {...rest}
        />
    );
}

export default BridgeChainSelector;
