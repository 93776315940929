import CustomTokenForm, { CustomTokenFormSkeleton } from "ui/bridge/components/input/CustomTokenForm/CustomTokenForm";
import { CreateAndAddCustomTokenFormProps } from "./CreateAndAddCustomTokenForm.types";
import { useTranslate } from "ui/locale";
import { useState } from "react";
import CreateBridgeModal from "../CreateBridgeModal/CreateBridgeModal";
import useGetSourceCustomTokenInfo from "ui/bridge/query/useGetSourceCustomTokenInfo";
import { BridgeSource } from "xchain-sdk";

export default function CreateAndAddCustomTokenForm({ address }: CreateAndAddCustomTokenFormProps): JSX.Element {
    const translate = useTranslate();
    const [open, setOpen] = useState(false);

    const { data: token, isLoading } = useGetSourceCustomTokenInfo(address, BridgeSource.ORIGIN);

    function handleSubmit(): void {
        setOpen(true);
    }

    return (
        <>
            {isLoading || !token ? (
                <CustomTokenFormSkeleton />
            ) : (
                <CustomTokenForm
                    address={address}
                    currency={token.currency}
                    decimals={token.decimals}
                    balance={token.balance}
                    name={token.name}
                    onSubmit={handleSubmit}
                    buttonLabel={translate("createBridgeAndAddToken")}
                />
            )}
            {token && <CreateBridgeModal open={open} onClose={() => setOpen(false)} token={token} />}
        </>
    );
}
