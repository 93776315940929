import { MetamaskWalletProvider } from "./evm/metamask/MetamaskWalletProvider";
import createWalletProviderFactory from "./utils/createWalletProviderFactory";
import { XrplFaucetWalletProvider } from "./xrp/xrplFaucet/XrplFaucetWalletProvider";
import ServiceFactory from "domain/adapter/ServiceFactory";

const WalletProviderFactory = createWalletProviderFactory({
    metamask: () => new MetamaskWalletProvider(ServiceFactory.ethersService, ServiceFactory.web3Service),
    xrplFaucet: () => new XrplFaucetWalletProvider(ServiceFactory.xrplFaucetService),
});

export default WalletProviderFactory;
