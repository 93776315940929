import { IProvider } from "domain/adapter/service/provider/IProvider";
import DomainError from "domain/error/DomainError";
import { IBridgeProvidersController } from "ui/adapter/controllers/IBridgeProvidersControllers";
import BridgeErrorCodes from "../BridgeErrorCodes";
import DomainEvents from "domain/event";
import ProviderFactory from "domain/adapter/ProviderFactory";
import { BridgeSource } from "xchain-sdk";
import { IBridgeChainsState } from "../states/bridgeChainsState";

export class BridgeProvidersController implements IBridgeProvidersController {
    private _originProviderPromise: Promise<IProvider> | undefined;
    private get originProviderPromise(): Promise<IProvider> {
        if (!this._originProviderPromise) throw new DomainError(BridgeErrorCodes.ORIGIN_PROVIDER_NOT_SET);
        return this._originProviderPromise;
    }
    private set originProviderPromise(provider: Promise<IProvider> | undefined) {
        this._originProviderPromise = provider;
    }

    private _destinationProviderPromise: Promise<IProvider> | undefined;
    private get destinationProviderPromise(): Promise<IProvider> {
        if (!this._destinationProviderPromise) throw new DomainError(BridgeErrorCodes.DESTINATION_PROVIDER_NOT_SET);
        return this._destinationProviderPromise;
    }
    private set destinationProviderPromise(provider: Promise<IProvider> | undefined) {
        this._destinationProviderPromise = provider;
    }

    onInit(): void {
        DomainEvents.bridgeChains.on("bridgeChainsLoad", (chains) => {
            this.handleProvidersLoad(chains);
        });

        DomainEvents.bridgeChains.on("bridgeChainsSwap", () => {
            this.handleProvidersSwap();
        });

        DomainEvents.bridgeChains.on("bridgeChainsChange", (chains, prevChains) => {
            this.handleProvidersChange(chains, prevChains);
        });
    }

    private async handleProvidersLoad({ originChain, destinationChain }: IBridgeChainsState): Promise<void> {
        const providerPromises: Promise<IProvider>[] = [];
        if (originChain) providerPromises.push((this.originProviderPromise = ProviderFactory(originChain)));
        if (destinationChain) providerPromises.push((this.destinationProviderPromise = ProviderFactory(destinationChain)));
        await Promise.all(providerPromises);
    }

    private async handleProvidersChange(
        { originChain, destinationChain }: IBridgeChainsState,
        { originChain: prevOriginChain, destinationChain: prevDestinationChain }: IBridgeChainsState,
    ): Promise<void> {
        const providerPromises: Promise<IProvider>[] = [];
        if (originChain !== prevOriginChain) {
            if (originChain) providerPromises.push((this.originProviderPromise = ProviderFactory(originChain)));
            else this.originProviderPromise = undefined;
        }
        if (destinationChain !== prevDestinationChain) {
            if (destinationChain) providerPromises.push((this.destinationProviderPromise = ProviderFactory(destinationChain)));
            else this.destinationProviderPromise = undefined;
        }
        await Promise.all(providerPromises);
    }

    private handleProvidersSwap(): void {
        const originProviderPromise = this.originProviderPromise;
        this.originProviderPromise = this.destinationProviderPromise;
        this.destinationProviderPromise = originProviderPromise;
    }

    getOriginProvider(): Promise<IProvider> {
        return this.originProviderPromise;
    }

    getDestinationProvider(): Promise<IProvider> {
        return this.destinationProviderPromise;
    }

    getSourceProvider(source: BridgeSource): Promise<IProvider> {
        return source === BridgeSource.ORIGIN ? this.getOriginProvider() : this.getDestinationProvider();
    }
}
