import { Select, SelectOption } from "@peersyst/react-components";
import { WalletProvider } from "common/models";
import { useMemo } from "react";
import { WalletSelectProps } from "./WalletSelect.types";
import ImageSelectItem from "ui/common/components/input/ImageSelectItem/ImageSelectItem";

function WalletSelect({ wallets, ...restProps }: WalletSelectProps): JSX.Element {
    const options: SelectOption<WalletProvider>[] = useMemo(
        () =>
            wallets.map((wallet) => ({
                label: <ImageSelectItem src={wallet.imageUrl} label={wallet.name} />,
                value: wallet,
            })),
        [wallets],
    );

    return <Select options={options} {...restProps} />;
}

export default WalletSelect;
