import { ExplorerLinkProps } from "./ExplorerLink.types";
import { useTranslate } from "ui/locale";
import clsx from "clsx";
import { ExplorerLinkRoot } from "./ExplorerLink.styles";

const ExplorerLink = ({ url, className, ...props }: ExplorerLinkProps): JSX.Element => {
    const translate = useTranslate();

    return (
        <ExplorerLinkRoot href={url} target="_blank" rel="noreferrer" className={clsx("ExplorerLink", className)} {...props}>
            {translate("seeInExplorer")}
        </ExplorerLinkRoot>
    );
};

export default ExplorerLink;
