import { useToast } from "@peersyst/react-components";
import { PropsWithChildren } from "react";
import useTranslate from "ui/locale/hooks/useTranslate";
import UIErrorEvent from "ui/error/UIErrorEvent";
import useSubscribeToError from "ui/error/hooks/useSubscribeToError";
import { LocaleErrorResource } from "ui/locale";

const ErrorHandler = ({ children }: PropsWithChildren): JSX.Element => {
    const translateError = useTranslate("error");
    const { showToast } = useToast();

    const handleUiError = (event: UIErrorEvent) => {
        showToast(translateError([event.error.message as LocaleErrorResource, "somethingWentWrong"]), { type: event.error.severity });
    };

    useSubscribeToError({ handleUiError });

    return <>{children}</>;
};

export default ErrorHandler;
