import { BasePageRoot, BasePageWrapper, BottomBackground, MobileBottomBackground, TopBackground } from "./BasePage.styles";
import { BasePageProps } from "./BasePage.types";
import Footer from "ui/common/components/navigation/Footer/Footer";

const BasePage = ({ children, ...rest }: BasePageProps): JSX.Element => (
    <BasePageRoot {...rest}>
        <BasePageWrapper>{children}</BasePageWrapper>
        <TopBackground />
        <BottomBackground />
        <MobileBottomBackground />
        <Footer />
    </BasePageRoot>
);

export default BasePage;
