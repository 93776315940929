import { IBridgeApi } from "domain/adapter/api/IBridgeApi";
import { IBridgeChainsController } from "ui/adapter/controllers/IBridgeChainsController";
import { IBridgeChainsState } from "../states/bridgeChainsState";
import State from "domain/common/State";
import { IBridgeChainsRepository } from "domain/adapter/repository/IBridgeChainsRepository";
import { ChainDto } from "common/models";
import BridgeChainsEvent from "../events/BridgeChainsEvent";
import DomainError from "domain/error/DomainError";
import BridgeErrorCodes from "../BridgeErrorCodes";
import { BridgeSource } from "xchain-sdk";

export class BridgeChainsController implements IBridgeChainsController {
    /**
     * Reference to the domain event emitter.
     */
    private readonly domainEventEmitter = BridgeChainsEvent;

    /**
     * Gets the current bridge chains state.
     */
    private get bridgeChains(): IBridgeChainsState {
        return this.bridgeChainsState.getState();
    }

    constructor(
        private readonly bridgeApi: IBridgeApi,
        public readonly bridgeChainsState: State<IBridgeChainsState>,
        private readonly bridgeChainsRepository: IBridgeChainsRepository,
    ) {}

    async onInit(): Promise<void> {
        await this.recoverBridgeChains();
    }

    private async recoverBridgeChains(): Promise<void> {
        const chains = await this.getChains();
        const bridgeChains = await this.bridgeChainsRepository.getBridgeChains();

        if (bridgeChains) {
            let originChain: ChainDto | undefined;
            let destinationChain: ChainDto | undefined;

            for (let i = 0; i < chains.length && (!originChain || !destinationChain); i++) {
                if (chains[i].name === bridgeChains.originChain) originChain = chains[i];
                else if (chains[i].name === bridgeChains.destinationChain) destinationChain = chains[i];
            }

            this.bridgeChainsState.setState({
                originChain,
                destinationChain,
            });
        }

        this.domainEventEmitter.emit("bridgeChainsLoad", this.bridgeChains);
    }

    getChains(): Promise<ChainDto[]> {
        return this.bridgeApi.findAllChains();
    }

    getOriginChain(): ChainDto {
        if (!this.bridgeChains.originChain) throw new DomainError(BridgeErrorCodes.ORIGIN_CHAIN_NOT_SET);
        return this.bridgeChains.originChain;
    }

    setOriginChain(chain: ChainDto): void {
        const chains = this.bridgeChains;
        const nextChains: IBridgeChainsState = {
            ...chains,
            originChain: chain,
        };
        this.domainEventEmitter.emit("bridgeChainsChange", nextChains, chains);
        this.bridgeChainsState.setState(nextChains);
        this.bridgeChainsRepository.setOriginChain(chain.name);
    }

    getDestinationChain(): ChainDto {
        if (!this.bridgeChains.destinationChain) throw new DomainError(BridgeErrorCodes.DESTINATION_CHAIN_NOT_SET);
        return this.bridgeChains.destinationChain;
    }

    setDestinationChain(chain: ChainDto): void {
        const chains = this.bridgeChains;
        const nextChains: IBridgeChainsState = {
            ...chains,
            destinationChain: chain,
        };
        this.domainEventEmitter.emit("bridgeChainsChange", nextChains, chains);
        this.bridgeChainsState.setState(nextChains);
        this.bridgeChainsRepository.setDestinationChain(chain.name);
    }

    getBridgeChains(): NonNullable<Required<IBridgeChainsState>> {
        const originChain = this.getOriginChain();
        const destinationChain = this.getDestinationChain();

        return {
            originChain,
            destinationChain,
        };
    }

    getSourceChain(source: BridgeSource): ChainDto {
        return source === BridgeSource.ORIGIN ? this.getOriginChain() : this.getDestinationChain();
    }

    swap(): void {
        this.bridgeChainsState.setState((prevState) => {
            const swappedChains = {
                originChain: prevState.destinationChain,
                destinationChain: prevState.originChain,
            };

            this.domainEventEmitter.emit("bridgeChainsSwap", swappedChains);

            // Persist swap
            this.bridgeChainsRepository.setBridgeChains({
                originChain: swappedChains.originChain?.name,
                destinationChain: swappedChains.destinationChain?.name,
            });

            return swappedChains;
        });
    }
}
