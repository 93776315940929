import { Col, Typography } from "@peersyst/react-components";
import { FormGroupProps } from "./FormGroup.types";
import clsx from "clsx";

const FormGroup = ({ children, label, className, style }: FormGroupProps): JSX.Element => (
    <Col gap={20} flex={1} className={clsx("FormGroup", className)} style={style}>
        <Typography variant="heading" fontWeight={600} color="grey.400">
            {label}
        </Typography>
        {children}
    </Col>
);

export default FormGroup;
