import Amount from "common/utils/Amount";
import ControllerFactory from "ui/adapter/ControllerFactory";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import { BridgeSource, XChainBridge } from "xchain-sdk";

/**
 * Returns the bridge token balance for the given address, BridgeSource and XChainBridge.
 */
export function useGetSourceBridgeTokenBalance<TData = Amount>(
    address: string | undefined,
    source: BridgeSource,
    xChainBridge: XChainBridge | undefined,
    { enabled = true, ...restOptions }: QueryOptions<Amount, unknown, TData, any[]> = {},
): QueryResult<TData> {
    const bridge = useBridgeState();
    return useQuery<Amount, unknown, TData, any[]>(
        [Queries.BRIDGE_SOURCE_TOKEN_BALANCE, address, source, JSON.stringify(xChainBridge), bridge?.direction],
        () => ControllerFactory.bridgeTokenController.getBridgeSourceXChainBridgeTokenBalance(address!, source, xChainBridge!),
        {
            enabled: !!address && !!bridge && !!xChainBridge && enabled,
            ...restOptions,
        },
    );
}
