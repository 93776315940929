import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import clsx from "clsx";

export default function NotFoundIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="NotFoundIcon" className={clsx(undefined, "Icon", className)} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66327 6.77551C2.66327 6.49937 2.88713 6.27551 3.16327 6.27551H20.5918C20.868 6.27551 21.0918 6.49937 21.0918 6.77551C21.0918 7.05165 20.868 7.27551 20.5918 7.27551H3.16327C2.88713 7.27551 2.66327 7.05165 2.66327 6.77551ZM2.66327 11.7551C2.66327 11.479 2.88713 11.2551 3.16327 11.2551H11.8776C12.1537 11.2551 12.3776 11.479 12.3776 11.7551C12.3776 12.0312 12.1537 12.2551 11.8776 12.2551H3.16327C2.88713 12.2551 2.66327 12.0312 2.66327 11.7551ZM3.16327 16.2347C2.88713 16.2347 2.66327 16.4586 2.66327 16.7347C2.66327 17.0108 2.88713 17.2347 3.16327 17.2347H11.8776C12.1537 17.2347 12.3776 17.0108 12.3776 16.7347C12.3776 16.4586 12.1537 16.2347 11.8776 16.2347H3.16327ZM16.5036 13.8913C16.6989 13.6961 17.0154 13.6961 17.2107 13.8913L19.3469 16.0276L21.4832 13.8913C21.6784 13.6961 21.995 13.6961 22.1903 13.8913C22.3856 14.0866 22.3856 14.4032 22.1903 14.5985L20.0541 16.7347L22.1903 18.8709C22.3856 19.0662 22.3856 19.3828 22.1903 19.578C21.995 19.7733 21.6784 19.7733 21.4832 19.578L19.3469 17.4418L17.2107 19.578C17.0154 19.7733 16.6989 19.7733 16.5036 19.578C16.3083 19.3828 16.3083 19.0662 16.5036 18.8709L18.6398 16.7347L16.5036 14.5985C16.3083 14.4032 16.3083 14.0866 16.5036 13.8913Z"
                fill="#B480FF"
            />
        </SvgIcon>
    );
}
