import { WalletProviderId } from "common/models";
import { Queries } from "ui/query/queries";
import { QueryOptions, QueryResult, useQuery } from "ui/query/react-query-overrides";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { BridgeSource } from "xchain-sdk";

export default function useRequestWalletSideConnection<T = any, S = T>(
    side: BridgeSource,
    providerId: WalletProviderId,
    { cacheTime = 0, staleTime = 0, ...restOptions }: QueryOptions<T, unknown, S, any[]> = {},
): QueryResult<S, unknown> {
    return useQuery(
        [Queries.REQUEST_WALLET_CONNECTION, side, providerId],
        () => {
            return (
                side === "origin"
                    ? ControllerFactory.bridgeWalletsController.requestOriginWalletConnection(providerId)
                    : ControllerFactory.bridgeWalletsController.requestDestinationWalletConnection(providerId)
            ) as T;
        },
        { cacheTime, staleTime, ...restOptions },
    );
}
