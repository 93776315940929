import { ActivityErrorCodes } from "domain/activity/errors/ActivityErrors";
import BridgeErrorCodes from "domain/bridge/BridgeErrorCodes";
import { WalletProviderErrorCodes } from "domain/wallet/providers/errors/WalletProviderErrors";

export enum GenericErrorCodes {
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

// Merge all module error codes here
const DomainErrorCodes = { ...GenericErrorCodes, ...BridgeErrorCodes, ...WalletProviderErrorCodes, ...ActivityErrorCodes };

export type DomainErrorCode = keyof typeof DomainErrorCodes;

export default DomainErrorCodes;
