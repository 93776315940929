import { BridgeWalletSelectorOptionsProps } from "./BridgeWalletSelectorOptions.types";
import { useTranslate } from "ui/locale";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { MoreAction } from "ui/common/components/input/More/More.types";
import More from "ui/common/components/input/More/More";

function BridgeWalletSelectorOptions({ side }: BridgeWalletSelectorOptionsProps): JSX.Element {
    const translate = useTranslate();

    const handleDisconnect = () => {
        ControllerFactory.bridgeWalletsController.disconnectWallet(side);
    };

    const actions: MoreAction[] = [
        {
            label: translate("disconnect"),
            onClick: handleDisconnect,
        },
    ];

    return <More actions={actions} />;
}

export default BridgeWalletSelectorOptions;
