import { Col, Loader, Row } from "@peersyst/react-components";
import { BridgeOriginAmountFieldProps } from "./BridgeOriginAmountField.types";
import clsx from "clsx";
import { BridgeOriginAmountTextField } from "./BridgeOriginAmountField.styles";
import useBridgeState from "ui/adapter/state/useBridgeState";
import { useControlled } from "@peersyst/react-hooks";
import BridgeOriginTokenSelector from "../BridgeOriginTokenSelector/BridgeOriginTokenSelector";
import { BridgeSource } from "xchain-sdk";
import { useGetSourceWalletBalance } from "ui/wallet/query/useGetBridgeWalletBalance";
import { useBridgeOriginSignatureRewardToPay } from "ui/bridge/query/useBridgeOriginSignatureRewardToPay";

function BridgeOriginAmountField({
    style,
    className,
    disabled = false,
    defaultValue = "",
    value: valueProp,
    onChange: onChangeProp,
    ...rest
}: BridgeOriginAmountFieldProps): JSX.Element {
    const [value, setValue] = useControlled(defaultValue, valueProp, onChangeProp);
    const { originXChainBridgeChain } = useBridgeState() || {};
    const { data: originWalletBalance } = useGetSourceWalletBalance(BridgeSource.ORIGIN);
    const { data: originSignatureRewardToPay, isLoading: signatureRewardToPayIsLoading } = useBridgeOriginSignatureRewardToPay();
    const loadingOriginWalletBalance = originWalletBalance === undefined || signatureRewardToPayIsLoading;

    return (
        <Row style={style} className={clsx("BridgeOriginAmountField", className)}>
            <Col flex={1}>
                <BridgeOriginAmountTextField
                    balance={loadingOriginWalletBalance ? undefined : originWalletBalance.minus(originSignatureRewardToPay!)}
                    value={value}
                    onChange={setValue}
                    disabled={disabled || !originXChainBridgeChain || loadingOriginWalletBalance}
                    validators={{ gt: 0 }}
                    error={loadingOriginWalletBalance} // Disables form while loading balance. Error is not shown since it is also being disabled.
                    suffix={originWalletBalance === undefined ? <Loader /> : <BridgeOriginTokenSelector />}
                    maxDecimals={originWalletBalance?.decimals}
                    {...rest}
                />
            </Col>
        </Row>
    );
}

export default BridgeOriginAmountField;
