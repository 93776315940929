import List from "ui/common/components/display/List/List";
import { renderTokenSelectorListItem } from "./TokenSelectorListItem/TokenSelectorListItem";
import { TokenSelectorListProps } from "./TokenSelectorList.types";
import { useCallback } from "react";
import { TokenSelectorListItemData } from "./TokenSelectorListItem/TokenSelectorListItem.types";
import { TokenSelectorListItemSkeleton } from "./TokenSelectorListItem/TokenSelectorListItemSkeleton";

export default function TokenSelectorList({
    onSelect,
    tokens,
    isLoading,
    gap,
    nothingToShow,
    renderItem: renderItemProp = renderTokenSelectorListItem,
    ...rest
}: TokenSelectorListProps): JSX.Element {
    const renderItem = useCallback(
        (item: TokenSelectorListItemData, index: number) => renderItemProp({ onSelect, item }, index),
        [onSelect],
    );

    return (
        <List
            data={tokens}
            gap={gap}
            renderItem={renderItem}
            isLoading={isLoading}
            nothingToShow={nothingToShow}
            Skeleton={TokenSelectorListItemSkeleton}
            numberOfSkeletons={5}
            {...rest}
        />
    );
}
