import { Loader, ModalProvider } from "@peersyst/react-components";
import { Suspense } from "react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import { config } from "common/config";
import BasePage from "ui/common/components/layout/BasePage/BasePage";
import { useLoad } from "ui/common/hooks/useLoad";
import InvalidNetworkOverlay from "ui/wallet/containers/invalidNetwork/InvalidNetworkOverlay/InvalidNetworkOverlay";
import { useHomeRoutes } from "ui/home/router/HomeRouter";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";

const Routes = () => {
    const homeRoutes = useHomeRoutes();

    return useRoutes([
        ...homeRoutes,
        {
            path: "*",
            element: <Navigate to={BridgeRoutes.BRIDGE} />,
        },
    ]);
};

const Router = (): JSX.Element => {
    const loading = useLoad();

    return (
        <BrowserRouter basename={config.publicUrl}>
            <ModalProvider>
                <Suspense fallback={<div>Loading</div>}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <BasePage>
                            <Routes />
                        </BasePage>
                    )}
                    <InvalidNetworkOverlay />
                </Suspense>
            </ModalProvider>
        </BrowserRouter>
    );
};

export default Router;
