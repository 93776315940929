import { Label, Paper, Typography } from "@peersyst/react-components";
import styled, { css } from "styled-components";

export const CustomTokenFormRoot = styled(Paper)(
    ({ theme }) => css`
        padding: ${theme.spacing[4]};
        width: 100%;
        background-color: ${theme.palette.grey[700]};
        border-radius: ${theme.borderRadiusSm};
    `,
);

export const CustomTokenFormLabel = styled(Label).attrs({
    alignment: "space-between",
    placement: "left",
    variant: "body1",
})(
    ({ theme }) => css`
        .Label {
            color: ${theme.palette.text};
        }
    `,
);

export const CustomTokenFormTypography = styled(Typography).attrs({
    variant: "heading",
})(() => css``);
