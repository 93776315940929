enum BridgeErrorCodes {
    ORIGIN_WALLET_PROVIDER_NOT_SET = "ORIGIN_WALLET_PROVIDER_NOT_SET",
    DESTINATION_WALLET_PROVIDER_NOT_SET = "DESTINATION_WALLET_PROVIDER_NOT_SET",
    ORIGIN_CHAIN_NOT_SET = "ORIGIN_CHAIN_NOT_SET",
    DESTINATION_CHAIN_NOT_SET = "DESTINATION_CHAIN_NOT_SET",
    MIN_CREATE_ACCOUNT_NOT_SET = "MIN_CREATE_ACCOUNT_NOT_SET",
    BRIDGE_DOES_NOT_CORRESPOND_TO_CHAINS = "BRIDGE_DOES_NOT_CORRESPOND_TO_CHAINS",
    BRIDGE_MANAGER_NOT_SET = "BRIDGE_MANAGER_NOT_SET",
    ORIGIN_PROVIDER_NOT_SET = "ORIGIN_PROVIDER_NOT_SET",
    DESTINATION_PROVIDER_NOT_SET = "DESTINATION_PROVIDER_NOT_SET",
    BRIDGE_DOORS_NOT_SET = "BRIDGE_DOORS_NOT_SET",
    BRIDGE_NOT_SET = "BRIDGE_NOT_SET",
    X_CHAIN_BRIDGE_DOES_NOT_CORRESPOND_TO_CHAIN = "X_CHAIN_BRIDGE_DOES_NOT_CORRESPOND_TO_CHAIN",
    NOT_CUSTOM_TOKEN_PROVIDER = "NOT_CUSTOM_TOKEN_PROVIDER",
    NO_WALLET_PROVIDERS = "NO_WALLET_PROVIDERS",
    WALLET_PROVIDER_DOES_NOT_SUPPORT_BRIDGE_CREATION = "WALLET_PROVIDER_DOES_NOT_SUPPORT_BRIDGE_CREATION",
    NOT_CREATE_BRIDGE_PROVIDER = "NOT_CREATE_BRIDGE_PROVIDER",
}

export default BridgeErrorCodes;
