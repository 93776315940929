import { Chip, Row, useTheme } from "@peersyst/react-components";
import { ChainAddressProps } from "./ChainAddress.types";
import BlockchainAddress from "ui/blockchain/components/display/BlockchainAddress/BlockchainAddress";
import { ChainType } from "xchain-sdk";

const ChipAddress = ({ address, chain }: ChainAddressProps): JSX.Element => {
    const { spacing } = useTheme();

    return (
        <Row flex={1} gap={spacing[2]} alignItems="center" css={{ width: "100%" }}>
            <Chip label={chain.name} variant="success" />
            <BlockchainAddress
                url={chain.explorerUrl}
                chainType={chain.type as ChainType}
                variant="body1"
                action="link"
                address={address}
                type="account"
                css={{ flex: 1 }}
            />
        </Row>
    );
};

export default ChipAddress;
