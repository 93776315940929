import { Row, Typography, useTheme } from "@peersyst/react-components";
import { AmountDisplayProps } from "./AmountDisplay.types";
import useFormatAmount from "ui/common/hooks/useFormatAmount";
import clsx from "clsx";

function AmountDisplay({ amount, hideCurrency = false, className, ...rest }: AmountDisplayProps): JSX.Element {
    const formatAmount = useFormatAmount();
    const { spacing } = useTheme();

    const formattedAmount = formatAmount(amount, { toParts: true });

    return (
        <Row gap={spacing[2]} className={clsx("AmountDisplay", className)} {...rest}>
            <Typography className="AmountDisplayAmount" variant="body1" fontWeight={400}>
                {formattedAmount.amount}
            </Typography>
            {!hideCurrency && (
                <Typography className="AmountDisplayCurrency" variant="body1" color="grey.400" fontWeight={600}>
                    {formattedAmount.currency}
                </Typography>
            )}
        </Row>
    );
}

export default AmountDisplay;
