import { BridgeTransferSummaryProps } from "./BridgeTransferSummary.types";
import { useTranslate } from "ui/locale";
import Amount from "common/utils/Amount";
import { Col, Label, useTheme } from "@peersyst/react-components";
import AmountDisplay from "ui/common/components/display/AmountDisplay/AmountDisplay";
import { useGetSourceToken } from "ui/bridge/query/useGetSourceToken";
import { BridgeSource } from "xchain-sdk";

function BridgeTransferSummary({ amount }: BridgeTransferSummaryProps): JSX.Element {
    const translate = useTranslate();
    const { spacing } = useTheme();

    const { data: originToken } = useGetSourceToken(BridgeSource.ORIGIN);
    const { data: destinationToken } = useGetSourceToken(BridgeSource.DESTINATION);

    const sendAmount = originToken ? Amount.fromDecToken(amount, originToken) : undefined;
    const receiveAmount = destinationToken ? Amount.fromDecToken(amount, destinationToken) : undefined;

    return (
        <Col gap={spacing[4]}>
            {sendAmount && (
                <Label label={translate("send")}>
                    <AmountDisplay amount={sendAmount} />
                </Label>
            )}
            {receiveAmount && (
                <Label label={translate("receive")}>
                    <AmountDisplay amount={receiveAmount} />
                </Label>
            )}
        </Col>
    );
}

export default BridgeTransferSummary;
