import { PaginatedTransfersDto } from "common/models";
import { ITransferApi } from "domain/adapter/api/ITransferApi";
import DomainError from "domain/error/DomainError";
import { IActivityController } from "ui/adapter/controllers/IActivityController";
import { ActivityErrorCodes } from "../errors/ActivityErrors";
import { IBridgeWalletsController } from "ui/adapter/controllers/IBridgeWalletsController";
import { IBridgeChainsController } from "ui/adapter/controllers/IBridgeChainsController";

export class ActivityController implements IActivityController {
    constructor(
        private readonly transferApi: ITransferApi,
        private readonly bridgeChainsController: IBridgeChainsController,
        private readonly bridgeWalletsController: IBridgeWalletsController,
    ) {}

    public async getPaginatedTranfers(pageNumber: number, pageSize: number): Promise<PaginatedTransfersDto> {
        const { originChain, destinationChain } = this.bridgeChainsController.getBridgeChains();

        const { originWallet, destinationWallet } = this.bridgeWalletsController.getBridgeWallets();

        if (originWallet.connection !== "connected" || destinationWallet.connection !== "connected")
            throw new DomainError(ActivityErrorCodes.NO_BRIDGE_WALLETS_CONNECTED);

        return await this.transferApi.getTransfers(
            originChain.name,
            destinationChain.name,
            originWallet.address,
            destinationWallet.address,
            pageNumber,
            pageSize,
        );
    }
}
