import { BridgeSource, XChainBridge } from "xchain-sdk";
import { QueryOptions, QueryResult } from "ui/query/react-query-overrides";
import { useConnectedBridgeSourceWalletState } from "../../bridge/hooks/useConnectedBridgeSourceWalletState";
import Amount from "common/utils/Amount";
import { useGetSourceBridgeTokenBalance } from "ui/bridge/query/useGetSourceBridgeTokenBalance";

/**
 * Returns the wallet bridge token balance for the given BridgeSource and XChainBridge.
 */
export function useGetBridgeWalletTokenBalance<TData = Amount>(
    source: BridgeSource,
    xChainBridge: XChainBridge | undefined,
    options?: QueryOptions<Amount, unknown, TData, any[]>,
): QueryResult<TData> {
    const sourceWallet = useConnectedBridgeSourceWalletState(source);

    return useGetSourceBridgeTokenBalance<TData>(sourceWallet?.address, source, xChainBridge, options);
}
