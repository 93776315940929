import Factory from "common/utils/Factory";
import Web3Service from "data_access/service/evm/ethers/web3/Web3Service";
import { IXrplService } from "./service/IXrplService";
import { IWeb3Service } from "./service/IWeb3Service";
import { IEthersService } from "./service/IEthersService";
import EthersService from "data_access/service/evm/ethers/EthersService";
import { IXrplFaucetService } from "./service/IXrplFaucetService";
import { XrplFaucetService } from "data_access/service/xrp/xrpl/xrplFaucet/XrplFaucetService";
import RepositoryFactory from "./RepositoryFactory";
import { XrplService } from "data_access/service/xrp/xrpl/XrplService";

export interface IServiceFactory {
    ethersService: IEthersService;
    web3Service: IWeb3Service;
    xrplService: IXrplService;
    xrplFaucetService: IXrplFaucetService;
}

export default Factory<IServiceFactory>({
    ethersService: () => new EthersService(),
    web3Service: () => new Web3Service(),
    xrplService: () => new XrplService(),
    xrplFaucetService: () => new XrplFaucetService(RepositoryFactory.xrplFaucetSeedsRepository),
});
