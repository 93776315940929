import { createModal, TabPanel, Tabs } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { useEffect, useState } from "react";
import Modal from "ui/common/components/feedback/Modal/Modal";
import { BridgeTransferModalProps } from "./BridgeTransferModal.types";
import BridgeTransferSteps from "../BridgeTransferSteps/BridgeTransferSteps";
import ControllerFactory from "ui/adapter/ControllerFactory";
import BridgeAttestations from "../BridgeAttestations/BridgeAttestations";
import { useNavigate } from "react-router-dom";
import { BridgeRoutes } from "ui/bridge/BridgeRouter";

const BridgeTransferModal = createModal<BridgeTransferModalProps>(function BridgeTransferModal({
    data,
    onClose,
    ...modalProps
}): JSX.Element {
    const translate = useTranslate();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        // @pre: transfer has started
        const removeOnFailed = ControllerFactory.bridgeTransferController.on("failed", () => {
            setIsError(true);
        });
        const removeOnAttestationsStarted = ControllerFactory.bridgeTransferController.on("attestationsStarted", () => {
            setTabIndex(1);
        });
        const removeOnCompleted = ControllerFactory.bridgeTransferController.on("completed", (result) => {
            navigate(BridgeRoutes.BRIDGE_SUCCESS, { state: { result: { ...result } } });
            onClose?.();
        });
        return () => {
            removeOnFailed();
            removeOnAttestationsStarted();
            removeOnCompleted();
        };
    }, []);

    return (
        <Modal
            title={tabIndex === 0 ? translate("approveTransaction") : undefined}
            closable={isError}
            css={{ width: "35rem" }}
            onClose={onClose}
            {...modalProps}
        >
            <Tabs index={tabIndex}>
                <TabPanel index={0}>
                    <BridgeTransferSteps data={data} />
                </TabPanel>
                <TabPanel index={1}>
                    <BridgeAttestations />
                </TabPanel>
            </Tabs>
        </Modal>
    );
});

export default BridgeTransferModal;
