import { Popover, Typography, useTheme } from "@peersyst/react-components";
import { TokenUnverifiedIcon, TokenVerifiedIcon } from "./TokenVerificationPopover.styles";
import { TokenVerificationPopoverProps } from "./TokenVerificationPopover.types";
import { useTranslate } from "ui/locale";

export default function TokenVerificationPopover({ label, verified = true }: TokenVerificationPopoverProps): JSX.Element {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Popover showOn="hover">
            <Popover.Content>{verified ? <TokenVerifiedIcon /> : <TokenUnverifiedIcon />}</Popover.Content>
            <Popover.Popper
                // TODO: Improve when Popover.Popper supports styled components
                style={{
                    backgroundColor: verified ? theme.palette.grey[700] : theme.palette.orange[100],
                    padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
                    borderRadius: theme.borderRadiusSm,
                    maxWidth: "15rem",
                }}
            >
                <Typography variant="caption2" css={{ color: verified ? theme.palette.text : theme.palette.orange[20] }}>
                    {label || translate(verified ? "verified" : "unverified")}
                </Typography>
            </Popover.Popper>
        </Popover>
    );
}
