import { Col, Divider, useTheme } from "@peersyst/react-components";
import { BridgeTransferStepsProps } from "./BridgeTransferSteps.types";
import BridgeTransferSummary from "../BridgeTransferSummary/BridgeTransferSummary";
import BridgeTransferDetails from "../BridgeTransferDetails/BridgeTransferDetails";
import BridgeTransactionStep from "../BridgeTransactionStep/BridgeTransactionStep";
import { BridgeTransferStage, BridgeTransferType } from "xchain-sdk";

function BridgeTransferSteps({ data }: BridgeTransferStepsProps): JSX.Element {
    const { spacing } = useTheme();

    return (
        <Col gap={spacing[4]}>
            <Col gap={spacing[4]}>
                {data.transferType === BridgeTransferType.CLAIM_COMMIT && (
                    <>
                        {data.isTrustClaimRequired && (
                            <>
                                <BridgeTransactionStep stage={BridgeTransferStage.TRUST_CLAIM} isFirst />
                                <Divider />
                            </>
                        )}
                        {data.isTrustCommitRequired && (
                            <>
                                <BridgeTransactionStep stage={BridgeTransferStage.TRUST_COMMIT} isFirst={!data.isTrustClaimRequired} />
                                <Divider />
                            </>
                        )}
                        <BridgeTransactionStep
                            stage={BridgeTransferStage.CREATE_CLAIM}
                            isFirst={!data.isTrustClaimRequired && !data.isTrustCommitRequired}
                        />
                        <Divider />
                        <BridgeTransactionStep stage={BridgeTransferStage.COMMIT} />
                    </>
                )}
                {data.transferType === BridgeTransferType.CREATE_ACCOUNT && (
                    <BridgeTransactionStep stage={BridgeTransferStage.CREATE_ACCOUNT_COMMIT} isFirst />
                )}
            </Col>
            <Divider />
            <BridgeTransferSummary amount={data.amount} />
            <Divider />
            <BridgeTransferDetails />
        </Col>
    );
}

export default BridgeTransferSteps;
