import { isValidElement } from "react";
import { TooltipProps } from "./Tooltip.types";
import { Popover, Typography } from "@peersyst/react-components";

export function Tooltip({ icon, content, ...popoverProps }: TooltipProps): JSX.Element {
    return (
        <Popover {...popoverProps}>
            <Popover.Content>{icon}</Popover.Content>
            <Popover.Popper>
                {content && (isValidElement(content) ? content : <Typography variant="caption1">{content}</Typography>)}
            </Popover.Popper>
        </Popover>
    );
}
