import { Typography } from "@peersyst/react-components";
import { BadgeRoot } from "./Badge.styles";
import { BadgeProps } from "./Badge.types";
import clsx from "clsx";

const Badge = ({ label, variant = "caption3", fontWeight = 700, className, ...props }: BadgeProps): JSX.Element => {
    return (
        <BadgeRoot justifyContent="center" alignItems="center" className={clsx("Badge", className)} {...props}>
            <Typography className="BadgeLabel" variant={variant} fontWeight={fontWeight}>
                {label}
            </Typography>
        </BadgeRoot>
    );
};

export default Badge;
