import { BridgeToken } from "common/models";
import { TokenSelectorListItemData } from "../TokenSelectorListItem.types";

export function buildTokenSelectorListItemFromBridgeToken(
    { xChainBridge, imageUrl, isVerified, isPending }: BridgeToken,
    chainName: string,
): TokenSelectorListItemData {
    return {
        label:
            xChainBridge.lockingChain.id === chainName
                ? xChainBridge.lockingChain.issue.currency
                : xChainBridge.issuingChain.issue.currency,
        xChainBridge,
        icon: imageUrl,
        isVerified,
        isPending,
    };
}
