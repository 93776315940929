import { Typography } from "@peersyst/react-components";
import { useTranslate } from "ui/locale";
import { HeaderRoot, HeaderContent } from "./Header.styles";
import Logo from "ui/common/components/display/Logo/Logo";

const Header = (): JSX.Element => {
    const translate = useTranslate();

    return (
        <HeaderRoot>
            <HeaderContent alignItems="center">
                <Logo />
                <Typography variant="h6">{translate("headerText")}</Typography>
            </HeaderContent>
        </HeaderRoot>
    );
};

export default Header;
