import { useQuery } from "react-query";
import ControllerFactory from "ui/adapter/ControllerFactory";
import { Queries } from "ui/query/queries";
import { QueryResult } from "ui/query/react-query-overrides";
import packageJson from "../../../../package.json";
import { ProjectVersion } from "common/models";

export default function useGetProjectVersion(): QueryResult<ProjectVersion> {
    return useQuery([Queries.APP_VERSION], async () => {
        const backendVersion = await ControllerFactory.healthController.check();

        return {
            backend: backendVersion.version,
            frontend: packageJson.version,
        };
    });
}
