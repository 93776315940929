import { BridgeAddressProps } from "./BridgeAddress.types";
import ChainAddress from "ui/bridge/components/display/ChainAddress/ChainAddress";
import { useBridgeSourceChainState } from "ui/bridge/hooks/useBridgeSourceChainState";

function BridgeAddress({ address, source: side }: BridgeAddressProps): JSX.Element {
    const chain = useBridgeSourceChainState(side, true);

    return <ChainAddress address={address} chain={chain} />;
}

export default BridgeAddress;
